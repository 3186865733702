import { isNil, sortBy, last } from 'lodash'
import { images, colors } from 'theme'
import VideoSnapshot from 'video-snapshot'
import { docExts, excelExts, pptExts, imageExts, audioExts, videoExts, extensionIcons } from './constant'
import { Node, Block, Maybe, NodeColor, NodeType } from 'types/graphqlSchema'

export const truncateString = (string, number, showEnd = false) => {
  if (string.length > number) {
    if (showEnd) {
      const firstPartLength = number - 13 - 3 // 3 is for '...'
      return string.substring(0, firstPartLength) + '...' + string.substring(string.length - 13)
    } else {
      return string.substring(0, number) + '...'
    }
  } else {
    return string
  }
}
// Get user readable date and time info drom Date objects
export const isMidnight = (date) => {
  return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0 && date.getMilliseconds() === 0
}
export const isTime1159PM = (date) => {
  return date.getHours() === 23 && date.getMinutes() === 59
}

export const formatForDate = (dateString: string, option?: object) => {
  if (!dateString) return ''
  if (Date.parse(dateString) === 0) return dateString

  if (!option) {
    const dateObj = new Date(dateString)
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = monthNames[dateObj.getMonth()]
    const day = dateObj.getDate()
    const year = dateObj.getFullYear()

    return `${month} ${day}, ${year}`
  }

  return new Intl.DateTimeFormat('default', option).format(new Date(dateString))
}

export const formatTimeString = (date: string) => {
  if (!date) return ''
  if (Date.parse(date) === 0) return date
  return new Intl.DateTimeFormat('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(new Date(date))
}

export const formatForTime = (date: string) => {
  if (!date) return ''
  if (Date.parse(date) === 0) return date
  return new Intl.DateTimeFormat('default', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date))
}

export const formateDeleteDate = (date: string, deleteInMonth: number) => {
  const newDate = new Date(date)
  const deleteDate = newDate.setMonth(newDate.getMonth() + +deleteInMonth)
  return new Intl.DateTimeFormat('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(deleteDate)
}

export const formatTimeSince = (date: string, useDate = '') => {
  const itemTime = new Date(date).getTime()
  const timeNow = new Date().getTime()
  const timeToLocalTimeString = new Date(date).toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
  })
  const diffTime = Math.abs(timeNow - itemTime)
  if (diffTime / (1000 * 60 * 60) < 1) {
    return new Intl.RelativeTimeFormat('en').format(-Math.floor(diffTime / (1000 * 60)), 'minute')
  }

  if (diffTime / (1000 * 60 * 60 * 24) < 1) {
    return new Intl.RelativeTimeFormat('en').format(-Math.floor(diffTime / (1000 * 60 * 60)), 'hour')
  }

  if (useDate === 'time') {
    return `${formatTimeString(date)} at ${formatForTime(date)}`
  }

  if (useDate === 'date') {
    return `${formatTimeString(date)} at ${timeToLocalTimeString}`
  }

  if (diffTime / (1000 * 60 * 60 * 24) < 7) {
    return new Intl.RelativeTimeFormat('en').format(-Math.floor(diffTime / (1000 * 60 * 60 * 24)), 'day')
  }
  if (diffTime / (1000 * 60 * 60 * 24 * 7) <= 4) {
    return new Intl.RelativeTimeFormat('en').format(Math.floor(-diffTime / (1000 * 60 * 60 * 24 * 7)), 'week')
  }
  return date
}

export const formatBytes = (b: number, decimals: number) => {
  const bytes = Number(b)
  if (bytes === 0) {
    return '0 Byte'
  }
  const k = 1024 // Or 1 kilo = 1000
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`
}

export const getFileType = (fileName: string) => {
  const extension = fileName?.split('.').pop()
  if (isNil(extension) || fileName === '') return null
  if (extension === 'pdf') return 'pdf'
  if (docExts.includes(extension)) return 'doc'
  if (excelExts.includes(extension)) return 'xls'
  if (pptExts.includes(extension)) return 'ppt'
  if (imageExts.includes(extension)) return 'image'
  if (audioExts.includes(extension)) return 'audio'
  if (videoExts.includes(extension)) return 'video'
  if (extension === 'tip') return 'tip'
  return null
}

export const extractUniqueUsers = (arr, userProperty) => {
  return arr
    .map((item) => ({
      name: `${item[userProperty].firstName} ${item[userProperty].lastName}`,
      id: item[userProperty].id,
      avatar: item[userProperty].avatar,
    }))
    .filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
}

export const getVideoCover = async (file: File, seekTo = 0.0): Promise<Blob> => {
  const snapshot = new VideoSnapshot(file)

  try {
    const coverImage = await snapshot.takeSnapshot(seekTo)
    return await fetch(coverImage).then((res) => res.blob())
  } catch (error) {
    throw new Error(`Failed to extract video cover: ${error.message}`)
  }
}

export const sortArray = (array: any[], sortBy: string) => {
  switch (sortBy.toLowerCase()) {
    case 'updatedat':
      return [...array].sort((a: any, b: any) => {
        if (new Date(a.updatedAt) < new Date(b.updatedAt)) {
          return 1
        }
        if (new Date(a.updatedAt) > new Date(b.updatedAt)) {
          return -1
        }
        return 0
      })
    case 'createdat':
      return [...array].sort((a: any, b: any) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return 1
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return -1
        }
        return 0
      })
    case 'size':
      return [...array].sort((a: any, b: any) => {
        if (new Date(a.totalSize) < new Date(b.totalSize)) {
          return 1
        }
        if (new Date(a.totalSize) > new Date(b.totalSize)) {
          return -1
        }
        return 0
      })
    case 'type':
      return [...array].sort((a: any, b: any) => {
        if (a.type > b.type) {
          return 1
        }
        if (a.type < b.type) {
          return -1
        }
        return 0
      })
    default:
      return [...array].sort((a: any, b: any) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }
        return 0
      })
  }
}

export const areArraysEqual = (arr1, arr2) => {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }
  // Create sets to remove order dependency
  const set1 = new Set(arr1)
  const set2 = new Set(arr2)
  // Check if sets have the same size and every element matches
  return set1.size === set2.size && [...set1].every((item) => set2.has(item))
}

export const dateSortFunction = (property: string, desc: boolean) => (a, b) => {
  const dateA = new Date(a[property])
  const dateB = new Date(b[property])
  return desc ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime()
}

export const pluralize = (count: number, noun: string, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`

/**
 * * Description
 * * A function that returns a promise, resolving into a recorder
 * * object that has 3 methods, start(), stop() and play().
 * * The function also returns an AudioBlob which is used to create the audio file
 * * and an audio url that can be used to instantly play the recorded audio.
 * * The default format of the recorded media is of type: audio/mpeg.
 * @returns a promise with 3 methods. start(), stop() and play()
 */
export const recordAudio = async () => {
  return new Promise((resolve) => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      // const mediaRecorder = new MediaRecorder(stream)
      const media = new MediaRecorder(stream)
      const audioChunks: any[] = []

      // Add listener to listen for data available
      media.addEventListener('dataavailable', (e: any) => {
        audioChunks.push(e.data)
      })

      // Start the recorder function
      const start = () => media.start()

      // Stop the recorder function
      const stop = () => {
        return new Promise((resolve) => {
          // Add listener for recording stop event
          media.addEventListener('stop', () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' })
            const audioUrl = URL.createObjectURL(audioBlob)
            const audio = new Audio(audioUrl)
            // Play the audio after the recording is done
            const play = () => audio.play()

            // Resolve
            resolve({ audioBlob, audioUrl, play })
          })
          // Stop Reocording
          media.stop()
        })
      }

      // Resolve Start and Stop
      resolve({ start, stop })
    })
  })
}

export const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'Android'
  }
  if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'iOS'
  }
  return 'Other'
}

export const resourceType = (BEType: string) => {
  if (!BEType) return null
  let type = 'PROJECT'
  if (BEType === NodeType.Box) {
    type = 'FOLDER'
  } else if (BEType === NodeType.Tip) {
    type = 'FILE'
  }
  return type
}

export const arrayBufferToBase64 = (buffer) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

/**
 * get file image from blocks
 * @param blocks
 * @returns
 */
export const getExtension = (blocks: Maybe<Maybe<Block>[]> | undefined): string => {
  const ext = (blocks?.[0]?.extension || '').toLowerCase()
  return ext.includes('/') ? ext.split('/').pop() || '' : ext
}

/**
 * get extension icon for tip
 * @param tip
 * @returns
 */
export function getFileIconFromNode(tip: Node) {
  const { blocks } = tip
  if (blocks?.length && blocks?.length > 1) return images.fileTipbox
  const ext = getExtension(blocks).toLocaleLowerCase()
  const extImage = extensionIcons.find(({ exts }) => exts.includes(ext))
  if (extImage) return extImage.src
  return ext ? images.blank : images.fileTipbox
}

/**
 * get extension icon for tip content
 * @param block
 * @returns
 */
export function getFileIconFromNodeContent(block: Block) {
  const ext = getExtension([block]).toLocaleLowerCase()
  const extImage = extensionIcons.find(({ exts }) => exts.includes(ext))
  return extImage ? extImage.src : images.fileUnknown
}

/**
 * check if video file or not
 * @param blocks
 * @returns
 */
export function isVideoFile(blocks: Maybe<Maybe<Block>[]> | undefined): boolean {
  const extension = getExtension(blocks)
  return videoExts.includes(extension)
}

/**
 * convert hex to NodeColor
 * @param hex
 * @returns
 */
export function convertHexToNodeColor(hex: string): NodeColor {
  switch (hex) {
    case colors.folder.green.body:
      return NodeColor.Green
    case colors.folder.yellow.body:
      return NodeColor.Yellow
    case colors.folder.red.body:
      return NodeColor.Red
    case colors.folder.purple.body:
      return NodeColor.Purple
    case colors.folder.blue.body:
      return NodeColor.Blue
    case colors.folder.orange.body:
      return NodeColor.Orange
    case colors.folder.lightGray.body:
      return NodeColor.LightGray
    case colors.folder.darkGray.body:
      return NodeColor.DarkGray
  }
  return NodeColor.Green
}

/**
 * convert NodeColor to hex
 * @param hex
 * @returns
 */
export function convertNodeColorToHex(color: NodeColor): { body: string; tab: string; text: string } {
  switch (color) {
    case NodeColor.Green:
      return colors.folder.green
    case NodeColor.Yellow:
      return colors.folder.yellow
    case NodeColor.Red:
      return colors.folder.red
    case NodeColor.Purple:
      return colors.folder.purple
    case NodeColor.Blue:
      return colors.folder.blue
    case NodeColor.Orange:
      return colors.folder.orange
    case NodeColor.LightGray:
      return colors.folder.lightGray
    case NodeColor.DarkGray:
      return colors.folder.darkGray
  }
  return colors.folder.darkGray
}

export const userLastFlowLog = (userId, flowLogs) => {
  return last(
    sortBy(
      flowLogs?.filter((log) => log?.user?.id === userId),
      ['createdAt'],
    ),
  )
}

export const groupBy = (keys) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map((key) => obj[key]).join('-')
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

// use Promise.all to run a parallel delay on mutations to make loading UI feel more robust
export async function minDelay<T>(promise: Promise<T>, ms: number) {
  const delay = new Promise((resolve) => setTimeout(resolve, ms))
  const [p] = await Promise.all([promise, delay])

  return p
}

export const validateUrl = (value) => {
  const regex =
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
  return regex.test(value)
}

export const extractFilename = (url: Maybe<string> | undefined): string => {
  if (!url) return ''
  const match = url.match(/\/(\d+_)?([^/?]+\.\w+)$/)
  if (match && match[2]) {
    return match[2]
  }
  return ''
}
