import { Box, Flex, Text, Icon, Grid, useBreakpointValue } from '@chakra-ui/react'
import { ComfirmationModal } from 'components/fragments'
import { format } from 'date-fns'
import { useFlowActions } from 'features/workflow'
import { truncateString } from 'helpers/utils'
import FlowMenuPopup from 'pages/Flows/components/FlowMenuPopup'
import { icons } from 'theme'
import { Flow } from 'types/graphqlSchema'

interface TaskRowProps {
  flow: Flow
  onClick?: () => void
}

const TaskListCard = ({ flow, onClick }: TaskRowProps) => {
  const { startConfirm, confirmTitle, isConfirming, actionType, endConfirm, executeAction } = useFlowActions()

  const urgent = flow.urgent

  const flowNameTruncationValue = useBreakpointValue({ base: 26, sm: 36, md: 50, lg: 30, xl: 36, '2xl': 40 })
  const truncatedFlowName =
    flow &&
    flow.name &&
    truncateString(
      flow.name.startsWith(' - ') ? `${flow?.project?.name}${flow.name}` : flow.name,
      flowNameTruncationValue,
    )

  return (
    <>
      <Flex w="full">
        <Flex
          w="full"
          cursor="pointer"
          bg="background"
          border="1px solid"
          borderColor="transparent"
          key={flow.id}
          h="40px"
          rounded={6}
          _hover={{ borderColor: 'textHighlight' }}
        >
          <Flex w="full" h="full" alignItems="center" onClick={onClick} pl={6}>
            {urgent && (
              <Icon
                as={icons.BsExclamationSquareFill}
                w={{ base: 3, '2xl': 4 }}
                h={{ base: 3, '2xl': 4 }}
                color="inherit"
                display={{ base: 'none', sm: 'block', lg: 'none', xl: 'block' }}
                mr={2}
              />
            )}
            <Text>{truncatedFlowName}</Text>
            <Text display={{ base: 'none', md: 'block', lg: 'none', '2xl': 'block' }} ml="auto" mr={8} minW="max">
              {flow.dueDate ? format(new Date(flow.dueDate), 'MMM d yyyy') : 'None'}
            </Text>
          </Flex>
          <Grid placeContent="center">
            <FlowMenuPopup
              flow={flow}
              isHovering={true}
              onClickMenu={(action) => {
                console.log(action, flow)
                startConfirm({ action, flow })
              }}
              position="static"
            />
          </Grid>
        </Flex>
        <Box id="scrollbar-gutter" w={4}></Box>
      </Flex>
      <ComfirmationModal
        name=""
        header={confirmTitle}
        isOpen={isConfirming}
        confirmType={actionType}
        confirmAction={() => executeAction()}
        close={endConfirm}
      />
    </>
  )
}

export default TaskListCard
