import { gql } from '@apollo/client'

// ---------------------------
// COMMON QUERY STRING
// ---------------------------
const getUserGuestShareMemberInformation = `
  shareEmailSuggestions {
    firstName
    lastName
    avatar
    avatarSecureUrl
    organizationId
    email
    organization {
      name
    }
    id
  }
`
const userInfo = `
  id
  primaryUserId
  email
  username
  avatar
  avatarSecureUrl
  firstName
  lastName
  phone
  phoneVerified
  notificationEmail
  notificationEmailVerified
  slackId
  slackIdVerified
  teamsId
  organizationId
  organization {
    name
    privacySetting
    logoSecureUrl
  }
  role
  position
  userState
  lastLogin
  lastPasswordChange
  twoStepVerification
  myProject {
    id
  }
  myProjectId
  createdAt
  updatedAt
  followedNodes {
    nodeId
  }
  notificationsPreferences {
    eventType
    channels
  }
`
const organizationInfo = `
  name
  id
  privacySetting
      logoSecureUrl
      users {
        id
        avatarSecureUrl
        firstName
        lastName
        email
        role
        createdAt
        organizationId
        userState
      }
      userGroups {
        id
        name
        users {
          firstName
          lastName
          id
          avatarSecureUrl
          organizationId
          email
        }
        groupType
      }
`

// ---------------------------
// QUERIES
// ---------------------------
export const GET_ME = gql(`
  query getMe {
    me {
      ${userInfo}
    }
  }
`)

export const GET_ORG = gql(`
  query organization($organizationId: String!) {
    organization(id: $organizationId) {
      ${organizationInfo}
    }
  }
`)

export const USERS = gql`
  query Users {
    users {
      firstName
      lastName
      avatar
      avatarSecureUrl
      organizationId
      email
      organization {
        name
      }
      id
    }
  }
`

export const GET_USER_GUEST_SHARE_MEMBERS = gql(`
  query getMe {
    me {
      ${getUserGuestShareMemberInformation}
    }
  }
`)

export const GET_USERS = gql(`
  query users($filter: FilterObj, $skip: Int, $limit: Int, $sortBy: UserSortBy) {
    users(filter: $filter, skip: $skip, limit: $limit, sortBy: $sortBy) {
      id
      primaryUserId
      firstName
      lastName
      username
      email
      username
      phone
      phoneVerified
      notificationEmail
      notificationEmailVerified
      slackId
      slackIdVerified
      role
      userState
      avatar
      avatarSecureUrl
      lastLogin
    }
  }
`)

export const GET_MY_SHARED_NODE_OWNERS = gql(`
  query mySharedNodeOwners {
    users {
      id
      firstName
      lastName
      email
      username
      avatar
    }
  }
`)

export const GET_FOLLOWED_NODES = gql(`
  query FollowedNodes {
    followedNodes {
      id
      nodeId
      node {
        id
        name
        type
        category
        state
        color
        coverImage
        coverImageSecureUrl
        totalSize
        createdAt
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          email
          avatar
        }
        ownerId
        owner {
          id
          firstName
          lastName
          email
          avatar
        }
        projectId
        project {
          id
          name
        }
        breadcrumbs {
          name
          id
          type
        }
        parent {
          id
          name
          type
          private
        }
        children {
          id
        }
        blocks {
          extension
          preview
          previewSecureUrl
        }
        organizationId
        organization {
          id
          name
        }
        sharedMembers {
          id
          sharedBy {
            id
            firstName
            lastName
            email
            avatar
          }
          sharedWith {
            id
            firstName
            lastName
            email
            avatar
          }
          permission {
            id
            name
          }
          isPublic
          entityType
          entityId
        }
      }
      userId
      user {
        id
        firstName
        lastName
        email
        username
        avatar
      }
      createdAt
    }
  }
`)

// ---------------------------
// MUTATIONS
// ---------------------------

export const CREATE_USER = gql(`
  mutation createUser($input: UserCreateInput!) {
    createUser(input: $input) {
      ${userInfo}
    }
  }
`)

export const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($name: String!, $userIds: [String!]!, $groupType: String!) {
    createUserGroup(name: $name, userIds: $userIds, groupType: $groupType) {
      id
      name
      users {
        id
        firstName
        lastName
        email
      }
      groupType
    }
  }
`

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($groupId: String!, $name: String!, $userIds: [String!]!, $groupType: String!) {
    updateUserGroup(groupId: $groupId, name: $name, userIds: $userIds, groupType: $groupType) {
      id
      name
      users {
        id
        firstName
        lastName
        avatarSecureUrl
        organizationId
        email
      }
      groupType
    }
  }
`

export const REMOVE_USER_GROUP = gql`
  mutation RemoveUserGroup($groupId: String!) {
    removeUserGroup(groupId: $groupId)
  }
`

export const UPDATE_USER = gql(`
  mutation updateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      ${userInfo}
    }
  }
`)
export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($userId: String!, $userOrgId: String!, $role: String!) {
    updateUserRole(userId: $userId, userOrgId: $userOrgId, role: $role) {
      id
      role
      firstName
    }
  }
`

export const DELETE_USER = gql(`
  mutation deleteUser($deleteUserId: String!) {
    deleteUser(id: $deleteUserId)
  }
`)

export const UPDATE_ME = gql(`
  mutation updateMe($input: UserUpdateInput!) {
    updateMe(input: $input) {
      ${userInfo}
    }
  }
`)

export const UPDATE_ORGANIZATION = gql(`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
      privacySetting
      settings
      subdomain
      theme
    }
  }
`)

export const DELETE_ME = gql(`mutation deleteMe { deleteMe }`)

export const FOLLOW_NODE = gql`
  mutation followNode($nodeId: ID!) {
    followNode(nodeId: $nodeId) {
      userId
      nodeId
      createdAt
      user {
        email
        firstName
        lastName
      }
      node {
        id
        name
      }
    }
  }
`

export const UNFOLLOW_NODE = gql`
  mutation unfollowNode($nodeId: ID!) {
    unfollowNode(nodeId: $nodeId) {
      userId
      nodeId
      createdAt
      user {
        email
        firstName
        lastName
      }
      node {
        id
        name
      }
    }
  }
`

export const UPDATE_NOTIFICATION_EMAIL = gql`
  mutation updateNotificationEmail($notificationEmail: String!) {
    updateNotificationEmail(notificationEmail: $notificationEmail)
  }
`
export const VERIFY_NOTIFICATION_EMAIL = gql`
  mutation verifyNotificationEmail($verificationCode: String!) {
    verifyNotificationEmail(verificationCode: $verificationCode)
  }
`

export const UPDATE_PHONE_NUMBER = gql`
  mutation updatePhoneNumber($phoneNumber: String!) {
    updatePhoneNumber(phoneNumber: $phoneNumber)
  }
`
export const VERIFY_PHONE_NUMBER = gql`
  mutation verifyPhoneNumber($verificationCode: String!) {
    verifyPhoneNumber(verificationCode: $verificationCode)
  }
`
export const UPDATE_SLACK_ID = gql`
  mutation updateSlackId($slackId: String!) {
    updateSlackId(slackId: $slackId)
  }
`
export const VERIFY_SLACK_ID = gql`
  mutation verifySlackId($verificationCode: String!) {
    verifySlackId(verificationCode: $verificationCode)
  }
`
