import { EVENT_TYPE } from 'helpers/constant'
import { MentionsItem, FlowsItem, FollowingItem, OtherItem } from './items'
import { TrackingEvent } from 'types/graphqlSchema'

interface AlertItemProps {
  event: TrackingEvent
  handleItemClick: (event: TrackingEvent) => void
}

const AlertItem = ({ event, handleItemClick }: AlertItemProps) => {
  // return appropriate item component based on event type
  switch (event.eventType) {
    case EVENT_TYPE.USER_MENTIONED:
    case EVENT_TYPE.FLOW_USER_MENTIONED:
      return <MentionsItem event={event} handleItemClick={handleItemClick} />

    case EVENT_TYPE.FLOW_SENT:
    case EVENT_TYPE.FLOW_APPROVED:
    case EVENT_TYPE.FLOW_CANCELLED:
    case EVENT_TYPE.FLOW_REJECTED:
    case EVENT_TYPE.FEEDBACK_FLOW_SUBMITTED:
    case EVENT_TYPE.FLOW_UNRESOLVED:
    case EVENT_TYPE.FLOW_USER_REMOVED:
    case EVENT_TYPE.IN_THE_LOOP:
    case EVENT_TYPE.FLOW_INTERVAL_REMINDER_CREATOR:
    case EVENT_TYPE.FLOW_INTERVAL_REMINDER_ASSIGNEE:
      return <FlowsItem event={event} handleItemClick={handleItemClick} />

    case EVENT_TYPE.RESOURCE_FOLLOWED:
      return <FollowingItem event={event} handleItemClick={handleItemClick} />

    case EVENT_TYPE.RESOURCE_SHARED:
      return <OtherItem event={event} handleItemClick={handleItemClick} />

    default:
      return null
  }
}

export default AlertItem
