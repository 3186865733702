import { useState, useEffect, useMemo, useRef } from 'react'
import { Flex } from '@chakra-ui/react'
import { useMutation, ApolloError, useQuery } from '@apollo/client'
import { ComfirmationModal } from 'components'
import { REMOVE_COMMENT } from 'features/message/message.schema'
import { Node, User, Flow, Message } from 'types/graphqlSchema'

import CommentForm from './CommentForm'
import CommentList from './CommentList'
import { GET_NODE_SHARED_MEMBERS } from 'features/graphql'
import { uniqBy } from 'lodash'

type CommentsProps = {
  user: User
  node?: Node
  mobile?: boolean
  comments: Message[]
  loading: boolean
  error: ApolloError | undefined
  tabsVisible?: boolean
  flows?: Flow[]
}

const CommentsAndTasks = ({
  user,
  node = undefined,
  mobile = false,
  comments,
  loading,
  error,
  flows,
}: CommentsProps) => {
  if (!node) return null

  const { data: nsmQueryData, refetch } = useQuery(GET_NODE_SHARED_MEMBERS, {
    variables: {
      id: node?.id,
      private: node?.private,
    },
  })

  useEffect(() => {
    refetch({
      id: node?.id,
      private: node?.private,
    })
  }, [node?.sharedMembers?.length, node?.id, node?.private, refetch])

  const sharedUsers = useMemo(() => {
    if (!node || !nsmQueryData?.nodeSharedMembers) {
      return []
    }
    const allSharedMembers = uniqBy([...nsmQueryData.nodeSharedMembers, node.owner].filter(Boolean), 'id')
    return allSharedMembers.map((member) => ({
      id: member.id,
      display: user.id !== member.id ? `${member.firstName} ${member.lastName}` : `Me`,
    }))
  }, [node, nsmQueryData])

  // GraphQL

  const [mutateRemoveComment] = useMutation(REMOVE_COMMENT, {
    onCompleted: () => {
      setShowModal(false)
    },
  })

  const [showModal, setShowModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState<string>('')

  const commentListRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (idToDelete) {
      setShowModal(true)
    }
    return () => setShowModal(false)
  }, [idToDelete])

  useEffect(() => {
    if (!comments) return
    if (!commentListRef.current) return
    commentListRef.current.scroll({ top: 0 })
  }, [comments?.length])

  const handleDeleteConfirmation = (id: string) => {
    setIdToDelete(id)
  }

  const handleDeleteComment = async () => {
    await mutateRemoveComment({ variables: { messageId: idToDelete } })
  }

  if (loading || !comments) return null

  if (error) throw new Error(error.message)

  return (
    <>
      <Flex
        flexDir="column"
        h={{ base: '80svh', '2xl': '84svh' }}
        justifyContent="flex-end"
        mt={0}
        className="no-scroll-bar"
      >
        {/* Comments List */}
        <CommentList
          commentListRef={commentListRef}
          comments={comments}
          handleDeleteComment={handleDeleteConfirmation}
          user={user}
          sharedUsers={sharedUsers}
          mobile={mobile}
          flows={flows}
        />
        <CommentForm
          user={user}
          comments={comments}
          error={error}
          loading={loading}
          node={node ?? undefined}
          sharedUsers={sharedUsers}
        />
      </Flex>
      {/* DELETE COMMENT MODAL */}
      <ComfirmationModal
        name="This comment"
        header="Delete comment"
        confirmAction={() => handleDeleteComment()}
        confirmType="delete"
        isOpen={showModal}
        close={() => {
          setShowModal(false)
        }}
      />
    </>
  )
}

export default CommentsAndTasks
