import React, { useState } from 'react'
import {
  FormControl,
  Input,
  Flex,
  Text,
  Box,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  FormLabel,
  Grid,
  useRadioGroup,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useAppSlice, useProjectSlice } from 'features/redux'
import { Button, FileInput, ColorRadio, Toast, PrivacySwitch } from 'components'
import { uploadFile } from 'helpers/storage'
import { icons, colors } from 'theme'
import { NodeCreateInput, NodeState } from 'types/graphqlSchema'
import { convertHexToNodeColor } from 'helpers/utils'
import { privacySetting } from 'helpers/constant'
import { useNavigate } from 'react-router-dom'

interface CreateNewProjectProps {
  setOpenModal: (openModal: string) => void
}

const CreateNewProject = ({ setOpenModal }: CreateNewProjectProps) => {
  const navigate = useNavigate()
  const { dispatch, user } = useAppSlice()
  const { createProject, deleteProject, updateProjectInfo } = useProjectSlice()
  const initialColor = useColorModeValue(colors.folder.lightGray.body, colors.folder.darkGray.body)
  const [projectName, setProjectName] = useState('')
  const [folderColor, setFolderColor] = useState<string>(initialColor)
  const [coverImage, setCoverImage] = useState<File>()
  const [isPrivate, setIsPrivate] = useState(user.organization.privacySetting === privacySetting.inviteOnly)
  const [imgData, setImgData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [, setErrorMessage] = useState('')
  const isMobile = useBreakpointValue({ xs: true, lg: false })

  // Control a group of custom radios
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'colors',
    onChange: setFolderColor,
  })
  const group = getRootProps()

  const handleInputChange = ({ target: { value } }: IInputParams) => {
    setProjectName(value)
  }

  const uploadPhoto = async () => {
    if (!coverImage) return {}
    try {
      const uploadResponse = await uploadFile(coverImage)
      return uploadResponse.fileUrl
    } catch (error: any) {
      setErrorMessage(error.message)
      return error
    }
  }

  const onChangePicture = (pictureFile: File) => {
    if (pictureFile) {
      setCoverImage(pictureFile)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgData(reader.result)
      })
      reader.readAsDataURL(pictureFile)
    }
  }

  const onRemovePicture = () => {
    setCoverImage(undefined)
    setImgData('')
    setFolderColor(initialColor)
  }

  const handleUndo = (id: string) => {
    try {
      dispatch(deleteProject(id))
    } catch (error: any) {
      setErrorMessage(error)
    }
  }

  const handlePrivacyChange = (isPrivate: boolean) => {
    setIsPrivate(isPrivate)
  }

  const handleCreateProject = async () => {
    setIsLoading(true)
    try {
      const input: NodeCreateInput = {
        name: projectName,
        state: NodeState.Active,
        category: 'ORGANIZATIONAL',
        type: 'PROJECT',
        private: isPrivate,
      }

      if (coverImage) {
        input.coverImage = await uploadPhoto()
      }

      if (folderColor) {
        input.color = convertHexToNodeColor(folderColor)
      }

      setOpenModal('')
      const res = await dispatch(createProject(input))
      if (res.payload && res.payload.coverImage) {
        setCoverImage(res.payload.coverImage)
      }

      await dispatch(updateProjectInfo({ ...res.payload }))

      Toast.show({
        icon: 'check',
        message: 'A new project was created.',
        onUndo: () => {
          handleUndo(res.payload.id)
        },
      })
      if (res.payload.id) {
        navigate('/')
      }
    } catch (error: any) {
      setErrorMessage(error.message)
      Toast.show({
        icon: 'error',
        message: 'Something went wrong with creating your project',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ModalBody overflowY="auto" className="no-scroll-bar">
        <Flex flexDir="column" color="textBlack">
          <FormControl mb={6}>
            <FormLabel mb={2} fontSize={[12, null, null, 15]} textAlign="inherit" color="inherit">
              Project Name
            </FormLabel>
            <Input
              id="projectName"
              value={projectName}
              autoFocus
              placeholder="Enter project name"
              variant="outline"
              borderRadius={8}
              borderColor="borderLight"
              onChange={handleInputChange}
              _placeholder={{
                color: 'textRegular',
                fontSize: isMobile ? '12px' : '16px',
              }}
            />
          </FormControl>
          <Flex flexDir="column" mb={6} color="inherit">
            <FormLabel mb={2} fontSize={[12, null, null, 15]} textAlign="inherit" color="inherit">
              Add a Cover Photo or Colour
            </FormLabel>

            <FormControl as="fieldset" textAlign="center">
              <Flex flexDir="column" w="full">
                <Flex justify="space-between" align="center" columnGap={4}>
                  {/* Folder Image */}
                  <Box maxWidth="8.875rem">
                    <FileInput
                      placeHolder={<icons.plusThin fontSize="1.5rem" style={{ margin: '0 auto' }} />}
                      name="boxCover"
                      file={coverImage}
                      onSelectFile={onChangePicture}
                      onCancelFile={onRemovePicture}
                      preview={imgData}
                      noPreviewFileName
                      styleProps={{
                        height: ['3rem', null, null, '3.5rem'],
                        width: ['4rem', null, null, '5rem'],
                        marginTop: 0,
                        borderWidth: '.5px',
                        borderStyle: 'solid',
                      }}
                    />
                  </Box>

                  <Text as="span" alignSelf="center" color="textBlack" fontSize={[12, null, null, 16]}>
                    or
                  </Text>

                  {/* Folder Color */}
                  <FormControl as="fieldset" width="auto">
                    <Box
                      position="relative"
                      _after={{
                        display: coverImage && imgData ? 'block' : 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: useColorModeValue('rgba(255, 255, 255, .6)', 'rgba(0, 0, 0, .6)'),
                        content: '""',
                      }}
                    >
                      <Grid {...group} gridTemplateColumns="repeat(8, auto)" gap=".375rem">
                        {Object.entries(colors.folder).map(([, { body }]) => {
                          // Override Chakra UI's isChecked prop to be able to clear the radio value
                          const radio = { ...getRadioProps({ value: body }), isChecked: folderColor === body }

                          return <ColorRadio key={body} color={body} {...radio} />
                        })}
                      </Grid>
                    </Box>
                  </FormControl>
                </Flex>
                {coverImage && imgData && (
                  <Box mt={2} textAlign="left">
                    <Box as="button" type="button" fontSize={[12, null, null, 15]} onClick={onRemovePicture}>
                      Remove
                    </Box>
                  </Box>
                )}
              </Flex>
            </FormControl>
          </Flex>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <PrivacySwitch user={user} onPrivacyChange={handlePrivacyChange} useConfirmation={false} />
        <Button
          disabled={!(projectName.trim().length > 0)}
          isLoading={isLoading}
          label="Create Project"
          onClick={handleCreateProject}
          variant="modalBtn"
        />
      </ModalFooter>
    </>
  )
}

export default CreateNewProject
