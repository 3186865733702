import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'helpers/store'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { client as apollo } from 'helpers/apollo'
import { loggedIn, authLogout, getAccessToken, manageOrgUsers } from 'helpers/auth'
import {
  GET_ME,
  UPDATE_ME,
  DELETE_ME,
  GET_MY_SHARED_NODE_OWNERS,
  GET_USER_GUEST_SHARE_MEMBERS,
  FOLLOW_NODE,
  UNFOLLOW_NODE,
  GET_FOLLOWED_NODES,
  UPDATE_ORGANIZATION,
} from './app.schema'
import {
  Node,
  FollowedNode,
  SharedMember,
  UserState,
  CreateOrganizationInput,
  UpdateOrganizationInput,
} from 'types/graphqlSchema'
import { FlowDisplayType } from 'features/workflow/Flows.types'

// ---------------------------------------
// Types
// ---------------------------------------
export type NodeSortDefault = 'Name' | 'Last Modified' | 'Size'
export type NodeSortTrash = 'Name' | 'Type' | 'Deleted'
export type NodeSortSearch = 'Name' | 'Type' | 'Last Modified'
export type NodeSortRecent = 'Last Modified'
export type FlowSortDefault = 'Sent Date' | 'Due Date' | 'Sender'
export type ProjectSortDefault = 'Name' | 'Last Modified' | 'Date Created' | 'Owner' | 'Organization'

interface IAppState {
  saveCredentials: boolean
  checking: boolean
  checked: boolean
  loggedIn: boolean
  notAuthorized: boolean
  addAccount: boolean
  user: any
  error: any
  isLoading: boolean
  viewType: string
  sortType: {
    default: NodeSortDefault
    trash: NodeSortTrash
    search: NodeSortSearch
    recent: NodeSortRecent
    flow: FlowSortDefault
    project: ProjectSortDefault
    taskList: FlowDisplayType
  }
  flowRequesterId: string
  projectOwnerId: string
  orgFilterId: string
  selectedNode?: Node | null
  popupMenuProjects?: { itemId: string; isOpen: boolean }[]
  popupMenuBoxes?: { itemId: string; isOpen: boolean }[]
  popupMenuTips?: { itemId: string; isOpen: boolean }[]
  popupMenuFlows?: { itemId: string; isOpen: boolean }[]
  shareEmailSuggestions: INodeUser[] | []
  guestUsers: GuestMember[] | []
  showWalkthrough: boolean
  isUploading: boolean
  linkShareAccess: boolean
  leftMenuCollapsed: boolean
  prevAutoCollapsed: boolean
  prevManualCollapsed: boolean
  rightMenuCollapsed: boolean
}

const initialState: IAppState = {
  saveCredentials: false,
  checking: false,
  checked: false,
  loggedIn: false,
  notAuthorized: false,
  addAccount: false,
  user: {},
  error: null,
  isLoading: false,
  isUploading: false,
  viewType: 'grid',
  sortType: {
    default: 'Name',
    trash: 'Name',
    search: 'Name',
    recent: 'Last Modified',
    flow: 'Sent Date',
    project: 'Last Modified',
    taskList: FlowDisplayType.list,
  },
  flowRequesterId: '',
  projectOwnerId: '',
  orgFilterId: '',
  selectedNode: undefined,
  popupMenuProjects: [],
  popupMenuBoxes: [],
  popupMenuTips: [],
  popupMenuFlows: [],
  showWalkthrough: false,
  shareEmailSuggestions: [],
  guestUsers: [],
  linkShareAccess: false,
  leftMenuCollapsed: false,
  prevAutoCollapsed: false,
  prevManualCollapsed: false,
  rightMenuCollapsed: false,
}

// ---------------------------------------
// Helpers
// ---------------------------------------

const logoutHelper = (currentState: IAppState) => {
  authLogout()
  apollo.resetStore()
  manageOrgUsers('remove', currentState.user)
  currentState.loggedIn = false
  currentState.user = {}
  currentState.shareEmailSuggestions = []
  currentState.guestUsers = []
}

// ---------------------------------------
// Thunks
// ---------------------------------------

const authenticate = createAsyncThunk('app/authenticate', async () => {
  let isLoggedIn
  try {
    isLoggedIn = await loggedIn()
    if (isLoggedIn) {
      const {
        data: { me },
      } = await apollo.query({ query: GET_ME })
      if (me) manageOrgUsers('add', me)
      return me || {}
    }
    return null
  } catch (error) {
    isLoggedIn = false
    throw new Error(error)
  }
})

const getUserGuestShareMemberInformation = createAsyncThunk('app/getUserGuestShareMemberInformation', async () => {
  try {
    const {
      data: { me },
    } = await apollo.query({ query: GET_USER_GUEST_SHARE_MEMBERS })
    return me
  } catch (err) {
    return err
  }
})

const updateMe = createAsyncThunk('app/updateMe', async (input: IUserUpdateInput) => {
  try {
    const { data } = await apollo.mutate({
      mutation: UPDATE_ME,
      variables: { input },
    })
    manageOrgUsers('update', data?.updateMe)
    return data?.updateMe
  } catch (err) {
    return err
  }
})

const updateOrganization = createAsyncThunk('app/updateOrganization', async (input: UpdateOrganizationInput) => {
  try {
    const { data } = await apollo.mutate({
      mutation: UPDATE_ORGANIZATION,
      variables: { input },
    })
    return data?.updateOrganization
  } catch (err) {
    console.log({ err })
    return err
  }
})

const deleteMyAccount = createAsyncThunk('app/deleteMyAccount', async () => {
  try {
    // const accessToken = await getAccessToken()
    const {
      data: { deleteMe },
    } = await apollo.mutate({
      mutation: DELETE_ME,
    })
    return deleteMe
  } catch (err) {
    return err
  }
})

const updateFollowing = createAsyncThunk('app/updateFollowing', async (nodeId: string, { dispatch, getState }: any) => {
  const { user } = getState().app
  if (user.followedNodes.some((f: FollowedNode) => f.nodeId === nodeId)) {
    await apollo.mutate({
      mutation: UNFOLLOW_NODE,
      variables: { nodeId },
    })
  } else {
    await apollo.mutate({
      mutation: FOLLOW_NODE,
      variables: { nodeId },
    })
  }
  const {
    data: { followedNodes },
  } = await apollo.query({ query: GET_FOLLOWED_NODES })
  return followedNodes
})

// TODO: Things like this no need to be in redux thunk
const getMySharedNodeOwners = createAsyncThunk('app/getMySharedNodeOwners', async () => {
  try {
    const {
      data: { users },
    } = await apollo.query({
      query: GET_MY_SHARED_NODE_OWNERS,
    })
    return users
  } catch (error: any) {
    throw new Error(error)
  }
})

// slice
const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSaveCredentials(state, { payload }: PayloadAction<boolean>) {
      state.saveCredentials = payload
    },
    login(state, { payload }) {
      // ok to "mutate" the state by overwriting a field
      state.loggedIn = true
      state.user = payload
    },
    logout(state) {
      logoutHelper(state)
    },
    saveMe(state, { payload }) {
      state.user = payload.user
    },
    toggleNotAuthorization(state, { payload }) {
      state.notAuthorized = payload
    },
    setViewType(state, { payload }) {
      state.viewType = payload
    },
    setSortType(
      { sortType },
      {
        payload,
      }: PayloadAction<{
        default?: NodeSortDefault
        trash?: NodeSortTrash
        search?: NodeSortSearch
        recent?: NodeSortRecent
        flow?: FlowSortDefault
        project?: ProjectSortDefault
        taskList?: FlowDisplayType
      }>,
    ) {
      if (payload.default) sortType.default = payload.default
      if (payload.trash) sortType.trash = payload.trash
      if (payload.search) sortType.search = payload.search
      if (payload.recent) sortType.recent = payload.recent
      if (payload.flow) sortType.flow = payload.flow
      if (payload.project) sortType.project = payload.project
      if (payload.taskList) sortType.taskList = payload.taskList
    },
    setFlowRequesterId(state, { payload }) {
      state.flowRequesterId = payload
    },
    setProjectOwnerId(state, { payload }) {
      state.projectOwnerId = payload
    },
    setOrgFilterId(state, { payload }) {
      state.orgFilterId = payload
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload
    },
    setAddAccount(state, { payload }) {
      state.addAccount = payload
    },
    setIsUploading(state, { payload }) {
      state.isUploading = payload
    },
    setSelectedNode(state, { payload }) {
      state.selectedNode = payload
    },
    setPopupMenuProjects(state, { payload }) {
      state.popupMenuProjects = payload
    },
    setPopupMenuBoxes(state, { payload }) {
      state.popupMenuBoxes = payload
    },
    setPopupMenuTips(state, { payload }) {
      state.popupMenuTips = payload
    },
    setPopupMenuFlows(state, { payload }) {
      state.popupMenuFlows = payload
    },
    clearSelectedNode(state) {
      state.selectedNode = undefined
    },
    updateNotifications(state, { payload }) {
      if (!payload) return
      state.user.notifications =
        state.user?.notifications?.map((notification: INotification) => {
          if (payload.id === notification.id) {
            return payload
          }
          return notification
        }) || []
    },
    updateAllNotifications(state, { payload }) {
      if (!payload) return
      state.user.notifications = payload.notifications
    },
    updatedShareEmailSuggestions(state, { payload }) {
      state.shareEmailSuggestions = payload
    },
    updateShowWalkthrough(state, { payload }: { payload: boolean }) {
      state.showWalkthrough = payload
    },
    toggleLinkShareAccess(state, { payload }) {
      state.linkShareAccess = payload
    },
    updateGuestList(state, { payload }) {
      if (!payload) return
      state.guestUsers = payload
    },
    removeGuestUser(state, { payload }) {
      if (!payload) return
      state.guestUsers = state.guestUsers.filter((user: SharedMember) => user.sharedWith?.id !== payload)
    },
    setLeftMenuCollapsed(state, { payload }: PayloadAction<boolean>) {
      state.leftMenuCollapsed = payload
    },
    setPrevManualCollapsed(state, { payload }: PayloadAction<boolean>) {
      state.prevManualCollapsed = payload
    },
    setPrevAutoCollapsed(state, { payload }: PayloadAction<boolean>) {
      state.prevAutoCollapsed = payload
    },
    setRightMenuCollapsed(state, { payload }: PayloadAction<boolean>) {
      state.rightMenuCollapsed = payload
    },
    setFollowedNodes(state, { payload }) {
      state.user.followedNodes = payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(authenticate.pending, (state) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.checking = true
      state.checked = false
    })
    builder.addCase(authenticate.fulfilled, (state, { payload }) => {
      if (payload) {
        if (payload.userState === UserState.Inactive) {
          state.loggedIn = false
        } else {
          state.loggedIn = true

          if (!payload.followedNodes) {
            payload.followedNodes = []
          }
          state.user = payload
          // state.shareEmailSuggestions =
          //   payload.shareEmailSuggestions === null
          //     ? []
          //     : payload.shareEmailSuggestions
          // state.guestUsers = payload.guestUsers.sharedMembers
        }
      } else {
        logoutHelper(state)
      }
      state.checking = false
      state.checked = true
      state.showWalkthrough = !payload?.lastLogin
    })
    builder.addCase(authenticate.rejected, (state, action) => {
      state.error = action?.error
      logoutHelper(state)
    })
    builder.addCase(getUserGuestShareMemberInformation.fulfilled, (state, { payload }) => {
      if (!payload) return
      state.shareEmailSuggestions = payload.shareEmailSuggestions === null ? [] : payload.shareEmailSuggestions
      state.guestUsers = payload.guestUsers ? payload.guestUsers.sharedMembers : []
    })
    builder.addCase(updateMe.fulfilled, (state, { payload }) => {
      if (payload) {
        state.user = payload
      }
    })
    builder.addCase(updateMe.rejected, (state, action) => {
      state.error = action?.error
    })
    builder.addCase(updateFollowing.fulfilled, (state, { payload }) => {
      if (payload) {
        state.user.followedNodes = payload
      }
    })
    builder.addCase(updateFollowing.rejected, (state, action) => {
      state.error = action?.error
    })
    builder.addCase(deleteMyAccount.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteMyAccount.fulfilled, (state) => {
      state.isLoading = false
      logoutHelper(state)
    })
    builder.addCase(deleteMyAccount.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export function useAppSlice() {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((s: RootState) => s.app)
  return {
    dispatch,
    ...state,
    ...slice.actions,
    authenticate,
    getMySharedNodeOwners,
    getUserGuestShareMemberInformation,
    updateFollowing,
    updateMe,
    deleteMyAccount,
    updateOrganization,
  }
}

export default slice.reducer
