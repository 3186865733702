import { Flex, Button, Fade } from '@chakra-ui/react'
import { AvatarsTooltip, Delay } from 'components'

const ShareTaskFormFooter = ({
  submitDisabled,
  submit,
  owner,
  isSubmitting,
  usersSelectOpen,
  onUsersSelectClose,
  label = 'Share',
}) => {
  return (
    <Flex mt="auto" as="footer" w="full" justify="space-between" minH="3rem" px={6} mb={1}>
      {owner && (
        <Flex align="center" gap={6}>
          <AvatarsTooltip users={[owner]} />
        </Flex>
      )}
      {!usersSelectOpen ? (
        <Delay>
          <Fade in={!usersSelectOpen} delay={0.3}>
            <Button
              isDisabled={submitDisabled}
              onClick={submit}
              isLoading={isSubmitting}
              variant={'defaultButton'}
              _disabled={{
                bg: 'var(--chakra-colors-textSoft)',
                color: 'var(--chakra-colors-textRegular)',
                border: '1px solid transparent',
                _hover: {
                  backgroundColor: 'var(--chakra-colors-textSoft)',
                  color: 'var(--chakra-colors-textRegular)',
                  border: '1px solid textBlack',
                  cursor: 'not-allowed',
                },
              }}
              _focusVisible={{ boxShadow: '0 0 0 2px var(--chakra-colors-borderFocus)' }}
              fontWeight={500}
            >
              {label}
            </Button>
          </Fade>
        </Delay>
      ) : (
        <Fade in={usersSelectOpen} delay={0.3}>
          <Button
            isDisabled={submitDisabled}
            onClick={onUsersSelectClose}
            isLoading={isSubmitting}
            variant="btnOutline"
            _disabled={{
              bg: 'var(--chakra-colors-textSoft)',
              color: 'var(--chakra-colors-textRegular)',
              border: '1px solid transparent',
              _hover: {
                backgroundColor: 'var(--chakra-colors-textSoft)',
                color: 'var(--chakra-colors-textRegular)',
                border: '1px solid textBlack',
                cursor: 'not-allowed',
              },
            }}
            _focusVisible={{ boxShadow: '0 0 0 2px var(--chakra-colors-borderFocus)' }}
            fontWeight={500}
          >
            Done
          </Button>
        </Fade>
      )}
    </Flex>
  )
}

export default ShareTaskFormFooter
