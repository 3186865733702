import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { images } from 'theme'

const NotFound = () => (
  <Box
    pos="absolute"
    w="100vw"
    h="100vh"
    bg="menuBackground"
    color="textHighlight"
    zIndex={100}
    style={{ left: 0, top: 0 }}
    display="flex"
  >
    <Flex
      gap={4}
      style={{
        margin: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box bg="textAsh" rounded={6}>
        <img src={images.errorPic} alt="logo" />
      </Box>
      <Text
        color="accent"
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          textAlign: 'center',
          letterSpacing: 'normal',
        }}
      >
        Error 404
      </Text>

      <Text>We can’t find what you’re looking for.</Text>

      <Text
        as="button"
        onClick={() => {
          window.location.href = '/'
        }}
        textAlign="center"
        maxW="23rem"
        mx="auto"
        fontSize="1.5rem"
      >
        Back to{' '}
        <span style={{ color: 'var(--chakra-colors-accentIcon)', fontWeight: 'bold', marginLeft: '4px' }}>Home</span>
      </Text>
    </Flex>
  </Box>
)

export default NotFound
