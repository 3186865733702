import { TrackingEvent } from 'types/graphqlSchema'

export type ItemProps = {
  event: TrackingEvent
  handleItemClick: (event: TrackingEvent) => void
}

export enum UpdatesLabel {
  mentions = '@Mentions',
  flows = 'Tasks',
  following = 'Following',
  other = 'Other',
}

export enum UpdatesType {
  mentions = 'mentions',
  flows = 'flows',
  following = 'following',
  other = 'other',
}

export enum MentionsTab {
  all = 'All',
  unread = 'Unread',
}

export enum FlowsTab {
  all = 'All',
  todo = 'To Do',
  done = 'Done',
  reminders = 'Reminders',
  other = 'Other',
}

export enum FollowingTab {
  all = 'All',
  projects = 'Projects',
  folders = 'Folders',
  files = 'Files',
}

export enum OtherTab {
  all = 'All',
  shares = 'Shares',
  other = 'Other',
}
