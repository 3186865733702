// ICONS
import {
  IoMdNotificationsOutline as notification,
  IoIosArrowUp as upArrow,
  IoIosArrowDown as downArrow,
  IoIosList as listView,
  IoIosInformationCircleOutline,
} from 'react-icons/io'
import { CiFilter } from 'react-icons/ci'
import {
  IoSearch as search,
  IoMicOutline as mic,
  IoShareSocial as share,
  IoGridOutline as gridView,
  IoClose as close,
  IoChevronDown,
  IoChevronUp,
  IoEyeOutline,
  IoEyeOffOutline,
} from 'react-icons/io5'
import { RiUser3Line as user, RiUserAddLine as userAdd } from 'react-icons/ri'
import { GoPlus as plus, GoPaperclip as paperclip, GoMention } from 'react-icons/go'
import { VscGear as account } from 'react-icons/vsc'
import { FcPrevious as previous, FcNext as next, FcCheckmark, FcCancel } from 'react-icons/fc'
import { SlCamera } from 'react-icons/sl'

import {
  FaCalendarDay,
  FaDollarSign as invoices,
  FaEye as preview,
  FaChevronRight,
  FaChevronLeft,
  FaChevronDown,
  FaChevronUp,
  FaChevronCircleUp,
  FaGoogle,
  FaFacebookF,
  FaEye,
  FaEyeSlash,
} from 'react-icons/fa'

import {
  BiArrowToRight as logOut,
  BiEditAlt as rename,
  BiFont as text,
  BiInfoCircle as infoIcon,
  BiExpandVertical as expandIcon,
  BiBell,
} from 'react-icons/bi'
import {
  BsThreeDots as menuIcon,
  BsThreeDotsVertical as tipMenuIcon,
  BsFileEarmarkPlus as createTip,
  BsArrowRight as moveTo,
  BsChatSquareFill as chat,
  BsArrowRightSquare,
  BsExclamationSquareFill,
  BsExclamationCircle,
  BsGrid,
  BsListUl,
  BsTag,
  BsChatDots,
  BsFolder2,
  BsClipboardCheck,
  BsHourglassSplit,
  BsCircle,
} from 'react-icons/bs'
import {
  AiOutlineLink as link,
  AiOutlineCamera as coverPhoto,
  AiFillStar as favorited,
  AiOutlineStar as favStar,
  AiOutlineDelete as deleteIcon,
  AiFillPlusCircle as createIcon,
  AiOutlinePicture as picture,
  AiFillInfoCircle as info,
  AiFillQuestionCircle as questionMark,
  AiOutlineQuestionCircle as outlineQuestionMark,
  AiFillClockCircle as clockIcon,
  AiOutlineClockCircle as outlineClockIcon,
  AiOutlineDownload as downloadIcon,
  AiFillPrinter as printerIcon,
  AiOutlineThunderbolt as thunderIcon,
  AiOutlinePlus as plusThin,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from 'react-icons/ai'
import {
  MdContentCopy as duplicate,
  MdHourglassEmpty as manage,
  MdSubdirectoryArrowRight as breadcrumbMenuArrow,
  MdOutlineDragIndicator as dragIndicator,
  MdAddToDrive as addDriveIcon,
  MdEditNote,
  MdEmail,
  MdPhone,
  MdDragHandle,
  MdOutlineUpdate,
} from 'react-icons/md'
import { FiDownload as downLoad, FiUpload as upLoad, FiLock as lock, FiRss as follow } from 'react-icons/fi'
import {
  HiOutlineChevronLeft as backArrow,
  HiCheck as check,
  HiOutlineExternalLink as externalLink,
  HiShare,
  HiPlus as plusBold,
} from 'react-icons/hi'
import {
  TbArrowsSort as sortIcon,
  TbLetterA as AIcon,
  TbSquareArrowRight as squareArrowRight,
  TbArrowsExchange,
} from 'react-icons/tb'
import { PiArrowsDownUpBold, PiSlidersHorizontalBold, PiShootingStar as shootingStar } from 'react-icons/pi'
import { LuClapperboard, LuBinary } from 'react-icons/lu'

const icons = {
  FaEye,
  FaEyeSlash,
  IoIosInformationCircleOutline,
  IoEyeOutline,
  IoEyeOffOutline,
  MdOutlineUpdate,
  FaCalendarDay,
  LuBinary,
  MdDragHandle,
  MdEmail,
  MdPhone,
  MdEditNote,
  BiBell,
  LuClapperboard,
  BsCircle,
  BsClipboardCheck,
  BsHourglassSplit,
  GoMention,
  TbArrowsExchange,
  BsFolder2,
  BsChatDots,
  HiShare,
  CiFilter,
  notification,
  search,
  mic,
  user,
  userAdd,
  plus,
  plusThin,
  plusBold,
  account,
  invoices,
  IoChevronUp,
  IoChevronDown,
  FaChevronRight,
  FaChevronLeft,
  FaChevronDown,
  FaChevronUp,
  FaChevronCircleUp,
  FaGoogle,
  FaFacebookF,
  paperclip,
  logOut,
  menuIcon,
  tipMenuIcon,
  favorited,
  favStar,
  share,
  link,
  follow,
  coverPhoto,
  rename,
  duplicate,
  downLoad,
  upLoad,
  deleteIcon,
  createIcon,
  createTip,
  moveTo,
  squareArrowRight,
  upArrow,
  downArrow,
  gridView,
  listView,
  backArrow,
  picture,
  info,
  chat,
  preview,
  manage,
  close,
  check,
  lock,
  breadcrumbMenuArrow,
  questionMark,
  outlineQuestionMark,
  text,
  dragIndicator,
  previous,
  next,
  FcCheckmark,
  FcCancel,
  clockIcon,
  outlineClockIcon,
  downloadIcon,
  printerIcon,
  infoIcon,
  sortIcon,
  expandIcon,
  addDriveIcon,
  AIcon,
  externalLink,
  thunderIcon,
  BsArrowRightSquare,
  PiArrowsDownUpBold,
  BsExclamationSquareFill,
  BsExclamationCircle,
  BsGrid,
  BsListUl,
  BsTag,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  PiSlidersHorizontalBold,
  shootingStar,
  SlCamera,
}

export default icons
