import { useState } from 'react'
import { dateSortFunction, sortArray } from 'helpers/utils'
import { useAppSlice, useProjectSlice, useBoxSlice, useTipSlice } from 'features/redux'
import { Node, NodeType } from 'types/graphqlSchema'

interface IHandleSortParam {
  listType: string
  sortBy: string
  children?: Node[] | null
}

export default () => {
  const { dispatch, sortType } = useAppSlice()
  const { projects } = useProjectSlice()
  const { setBoxes } = useBoxSlice()
  const { setTips } = useTipSlice()
  const [projectsDisplayed, setProjectsDisplayed] = useState(projects)

  /**
   *
   * @param listType string
   * @param sortBy string
   * @param children Node[]
   * @returns [] e.g- tips array and boxes array
   */
  const handleSort = ({ listType, sortBy, children }: IHandleSortParam) => {
    if (listType === 'boxes' && children?.length) {
      const boxesArray = children?.filter((b: Node) => b !== null && b.type === NodeType.Box)
      if (boxesArray?.length > 0 && sortBy !== 'Custom') {
        const sortedBoxesArray = sortArray(boxesArray, sortBy === 'Last Modified' ? 'updatedAt' : sortBy.toLowerCase())
        return sortedBoxesArray
      }
      return boxesArray
    }
    if (listType === 'tips' && children?.length) {
      const tipsArray = children?.filter((tip: Node) => tip !== null && tip.type === NodeType.Tip)
      if (tipsArray?.length > 0 && sortBy !== 'Custom') {
        const sortedTipsArr = sortArray(tipsArray, sortBy === 'Last Modified' ? 'updatedAt' : sortBy.toLowerCase())
        return sortedTipsArr
      }
      return tipsArray
    }
    return []
  }

  const handleSortFoldersFiles = (nodes) => {
    if (!Array.isArray(nodes)) return
    // sort boxes
    const sortedBoxes = handleSort({
      listType: 'boxes',
      sortBy: sortType.default,
      children: nodes?.filter((c: Node) => c.type === NodeType.Box),
    })
    // sort tips
    const sortedTips = handleSort({
      listType: 'tips',
      sortBy: sortType.default,
      children: nodes?.filter((c: Node) => c.type === NodeType.Tip),
    })
    dispatch(setBoxes(sortedBoxes))
    dispatch(setTips(sortedTips))
  }

  const handleSortProjects = (projectsToSort, descending, projectOwnerId, orgFilterId) => {
    if (sortType.project === 'Name') {
      descending
        ? setProjectsDisplayed(projectsToSort.sort((a, b) => a.name.localeCompare(b.name)))
        : setProjectsDisplayed(projectsToSort.sort((a, b) => b.name.localeCompare(a.name)))
    }
    if (sortType.project === 'Owner') {
      if (projectOwnerId) {
        setProjectsDisplayed(projectsToSort.filter((proj) => proj.owner?.id === projectOwnerId))
      }
    }
    if (sortType.project === 'Organization') {
      if (orgFilterId) {
        setProjectsDisplayed(projectsToSort.filter((proj) => proj.organization.id === orgFilterId))
      }
    }
    if (sortType.project !== 'Name' && sortType.project !== 'Owner' && sortType.project !== 'Organization') {
      const sortTypeMap = {
        'Last Modified': 'updatedAt',
        'Date Created': 'createdAt',
      }
      setProjectsDisplayed(projectsToSort.sort(dateSortFunction(sortTypeMap[sortType.project], descending)))
    }
  }

  return {
    handleSortFoldersFiles,
    handleSortProjects,
    projectsDisplayed,
  }
}
