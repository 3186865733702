import { useRef } from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  Box,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Link,
} from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { useSearchParams } from 'react-router-dom'
import { useAppSlice, useTipSlice } from 'features/redux'
import { icons } from 'theme'
import BreadcrumbDesktopMenu from './BreadcrumbDesktopMenu'
import { Node } from 'types/graphqlSchema'

interface CurrentNode {
  name: string
  type: string
  id: string
  breadcrumbs: IBreadcrumb[]
}

interface BreadcrumbDesktopProps {
  currentNode: CurrentNode | undefined
  project: Node | undefined
  mainLabel: { label: string; path: string; bold: boolean; tooltipIcon?: IconType; tooltip?: string }
  isLinkShare?: boolean
  breadcrumbBar: IBreadcrumb[]
  breadcrumbMenu: IBreadcrumb[]
  menuOpen: boolean
  pathname: string
  setMenuOpen: (menuOpen: boolean) => void
  handleClick: (node: IBreadcrumb) => void
  handleNavagationParentPage: (path: string) => void
}

const BreadcrumbDeskTop = ({
  currentNode,
  project,
  mainLabel,
  breadcrumbBar,
  breadcrumbMenu,
  menuOpen,
  pathname,
  setMenuOpen,
  handleClick,
  handleNavagationParentPage,
}: BreadcrumbDesktopProps) => {
  const breadcrumbRef = useRef<HTMLInputElement>(null)
  const [searchParams] = useSearchParams()
  const projectParam = searchParams.get('project')
  const { currentTip } = useTipSlice()
  const {
    user: { myProjectId },
  } = useAppSlice()
  const isPersonal = project?.id === myProjectId

  if (pathname === '/' || pathname === '/trash') {
    return (
      <Link href={mainLabel.path} fontWeight="700" fontSize={20} color="textSoft">
        {mainLabel.label}
      </Link>
    )
  }

  return (
    <Flex id="breadcrumbParent" overflowX="hidden" noOfLines={1} maxWidth="calc(100vw - 636px)">
      <Flex id="breadcrumbsDiv" align="center">
        <ChakraBreadcrumb
          spacing="8px"
          separator={<Icon as={icons.FaChevronRight} h={4} w={4} color="textRegular" />}
          fontFamily="Inter"
          fontWeight="400"
          fontSize={16}
          alignContent="center"
          pr={1}
        >
          {isPersonal && (
            <BreadcrumbItem>
              <Link href={mainLabel.path} fontWeight={700} color="textSoft" fontSize={20}>
                Notes
              </Link>
              <Popover placement="right">
                <PopoverTrigger>
                  <Box mt={1} p={2} display="inline" cursor="pointer">
                    <Icon as={mainLabel.tooltipIcon} />
                  </Box>
                </PopoverTrigger>
                <PopoverContent
                  border="0"
                  width="full"
                  rounded="lg"
                  _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
                >
                  <PopoverArrow bg="menuBackground" border="0" />
                  <PopoverBody bg="menuBackground" _focusVisible={{}} rounded="lg">
                    <Text variant="tiny" color="textHighlight">
                      {mainLabel.tooltip}
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </BreadcrumbItem>
          )}
          {!currentNode && project && !projectParam && mainLabel.label !== 'Personal' && (
            <BreadcrumbItem>
              <Text color="textHighlight" fontWeight={500} fontSize="18px" maxWidth="calc(100vw - 725px)" noOfLines={1}>
                {project.name}
              </Text>
            </BreadcrumbItem>
          )}
          {breadcrumbMenu.length > 0 && (
            <BreadcrumbItem h="40px">
              <BreadcrumbDesktopMenu
                breadcrumbs={breadcrumbMenu}
                handleClick={handleClick}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                handleNavagationParentPage={handleNavagationParentPage}
              />
            </BreadcrumbItem>
          )}
          {breadcrumbBar.map((node) => (
            <BreadcrumbItem key={node.id}>
              <BreadcrumbLink
                color="textRegular"
                onClick={() => {
                  handleClick(node)
                }}
              >
                {node.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
          {currentNode && currentNode?.id !== currentTip?.id && (
            <BreadcrumbItem alignContent="center">
              <Text
                color="textHighlight"
                fontWeight={500}
                fontStyle="italic"
                fontSize="18px"
                maxWidth="calc(100vw - 725px)"
                noOfLines={1}
                overflow="visible"
              >
                {currentNode?.name}
              </Text>
            </BreadcrumbItem>
          )}
        </ChakraBreadcrumb>
        <Box ref={breadcrumbRef} h="full" w="1px" />
      </Flex>
    </Flex>
  )
}

export default BreadcrumbDeskTop
