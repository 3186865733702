import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  button: {
    // this will style the MenuButton component
    fontWeight: 'medium',
    bg: 'teal.500',
    color: 'textHighlight',
    _hover: {
      bg: 'textBlack',
      color: 'white',
    },
  },
  list: {
    // this will style the MenuList component
    // py: '4',
    borderRadius: 'xl',
    border: 'none',
    bg: 'textHighlight',
    zIndex: 10,
    boxShadow: 'xl',
    rounded: 'md',
  },
  item: {
    // this will style the MenuItem and MenuItemOption components
    color: 'textBlack',
    bg: 'textHighlight',
    _hover: {
      color: 'textHighlight',
      bg: 'menuBackground',
    },
    _focus: {
      color: 'textHighlight',
      bg: 'cardBackground',
    },
  },
  groupTitle: {
    // this will style the text defined by the title prop
    // in the MenuGroup and MenuOptionGroup components
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'center',
    letterSpacing: 'wider',
    opacity: '0.7',
  },
  command: {
    // this will style the text defined by the command
    // prop in the MenuItem and MenuItemOption components
    opacity: '0.8',
    fontFamily: 'mono',
    fontSize: 'sm',
    letterSpacing: 'tighter',
    pl: '4',
  },
  divider: {
    // this will style the MenuDivider component
    my: '4',
    borderColor: 'white',
    borderBottom: '2px dotted',
  },
  variants: {
    lightBg: {
      menuItem: {
        bg: 'inherit',
        color: 'textBlack',
        fontFamily: 'Inter',
        fontWeight: 500,

        _hover: { bg: 'menuBackground', color: 'textHighlight', fill: 'textHighlight' },
        _focusVisible: { bg: 'menuBackground', color: 'textHighlight' },
      },
    },
  },
})
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle })
