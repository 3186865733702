import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  FormControl,
  FormLabel,
  Input,
  HStack,
  VStack,
  Button,
  Flex,
  MenuItem,
  Box,
  ModalFooter,
  FormErrorMessage,
} from '@chakra-ui/react'
import { PhoneNumberInput } from 'components/elements'
import { AdminContext } from 'components/layouts/AdminLayout'
import { regs } from 'helpers/vali'
import { useAdmin } from 'pages/Admin/hooks'
import { menuItemStyles, RoleDropdown } from 'pages/Admin/UserManagement/components/UserCard'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Role, UserState } from 'types/graphqlSchema'

interface AUMProps {
  isOpen: boolean
  onClose: () => void
}

const inputStyle = {
  rounded: 8,
  border: '1px solid var(--chakra-colors-borderLight)',
  fontWeight: 400,
  fontSize: '14px',
  color: 'textBlack',
  _placeholder: { color: 'tipbox.dark.text.charcoalGrey' },
  _selected: { color: 'textHighlight' },
}

const AddUsersModal = ({ isOpen, onClose }: AUMProps) => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [firstNameInvalid, setFirstNameInvalid] = useState(false)
  const [lastNameInvalid, setLastNameInvalid] = useState(false)
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedRole, setSelectedRole] = useState(Role.User)
  const { orgData } = useAdmin()
  const orgId = orgData?.organization?.id
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitDisabled = isSubmitting || !email || !regs.email.test.test(email) || !firstName || !lastName || !orgId
  const { handleInviteUser } = useOutletContext<AdminContext>()

  const handleClose = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setPhoneNumber('')
    onClose()
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('')
      }, 4000)
    }
  }, [error])

  const getSelectedRole = () => {
    if (selectedRole === Role.Admin) return 'Admin'
    if (selectedRole === Role.User) return 'Member'
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDir="column"
        bg="white"
        color="textBlack"
        fontFamily="Inter"
        w="600px"
        maxW="80%"
        pb={4}
        px={4}
      >
        <ModalHeader>
          <Text color="textBlack" px="1px">
            Add users to your organization
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <VStack spacing={4} align="stretch" px="1px">
            <HStack spacing={4}>
              <FormControl
                isRequired
                isInvalid={firstNameInvalid}
                onBlur={() => {
                  if (firstName.length === 0) {
                    setFirstNameInvalid(true)
                  } else {
                    setFirstNameInvalid(false)
                  }
                }}
              >
                <FormLabel color="textBlack" fontWeight={500} fontSize={14} requiredIndicator={<></>}>
                  *First Name
                </FormLabel>
                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First name"
                  {...inputStyle}
                />
                <FormErrorMessage>First name is required.</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={lastNameInvalid}
                onBlur={() => {
                  if (lastName.length === 0) {
                    setLastNameInvalid(true)
                  } else {
                    setLastNameInvalid(false)
                  }
                }}
              >
                <FormLabel color="textBlack" fontWeight={500} fontSize={14} requiredIndicator={<></>}>
                  *Last Name
                </FormLabel>
                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last name"
                  {...inputStyle}
                />
                <FormErrorMessage>Last name is required.</FormErrorMessage>
              </FormControl>
            </HStack>

            <FormControl isRequired isInvalid={email.length > 0 && !regs.email.test.test(email)}>
              <FormLabel color="textBlack" fontWeight={500} fontSize={14} requiredIndicator={<></>}>
                *Email
              </FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email address"
                {...inputStyle}
              />
              <FormErrorMessage>Email is required.</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel color="textBlack" fontWeight={500} fontSize={14}>
                Phone Number
              </FormLabel>
              <PhoneNumberInput
                fontSize={14}
                value={phoneNumber}
                onChange={(phoneNumber) => {
                  setPhoneNumber(phoneNumber)
                }}
                disabled={false}
              />
            </FormControl>
            <FormControl>
              <FormLabel color="textBlack" fontWeight={500} fontSize={14}>
                *Role
              </FormLabel>
              <Box mt={4}>
                <RoleDropdown disable={false} title={getSelectedRole()} inModal>
                  <MenuItem {...menuItemStyles} px={8} value="Urgent" onClick={() => setSelectedRole(Role.Admin)}>
                    <Flex flexDir="column">
                      <Text color="inherit" fontSize="0.875rem" fontWeight={500}>
                        Admin
                      </Text>
                      <Text color="textRegular" fontSize="11px" fontWeight={500} w="35ch">
                        Can manage organization, privacy & team members, create & edit all content
                      </Text>
                    </Flex>
                  </MenuItem>
                  <MenuItem {...menuItemStyles} px={8} value="Not Urgent" onClick={() => setSelectedRole(Role.User)}>
                    <Flex flexDir="column">
                      <Text color="inherit" fontSize="0.875rem" fontWeight={500}>
                        Member
                      </Text>
                      <Text color="textRegular" fontSize="11px" fontWeight={500} w="35ch">
                        Can create & edit content, manage privacy & team members, manage & respond to Flows
                      </Text>
                    </Flex>
                  </MenuItem>
                </RoleDropdown>
              </Box>
            </FormControl>
          </VStack>
          {/* </Flex> */}
        </ModalBody>
        <ModalFooter display="flex" justifyContent="space-between" alignItems="center">
          <Text color="textBlack" fontStyle="italic" fontWeight={400} fontSize={14}>
            * required
          </Text>
          {error && error.length > 0 && (
            <Text fontStyle="italic" fontWeight={400} fontSize={14} color="textBlack">
              {error}
            </Text>
          )}
          <Button
            type="submit"
            alignSelf="flex-end"
            isDisabled={submitDisabled}
            mr="1px"
            variant={'defaultButton'}
            _disabled={{
              bg: 'var(--chakra-colors-textSoft)',
              color: 'var(--chakra-colors-textRegular)',
              border: '1px solid transparent',
              _hover: {
                backgroundColor: 'var(--chakra-colors-textSoft)',
                color: 'var(--chakra-colors-textRegular)',
                border: '1px solid textBlack',
                cursor: 'not-allowed',
              },
            }}
            fontWeight={500}
            onClick={async () => {
              setIsSubmitting(true)
              const res = await handleInviteUser({
                variables: {
                  input: {
                    email,
                    firstName,
                    lastName,
                    role: selectedRole,
                    userState: UserState.Pending,
                    isInvite: true,
                    organizationSubdomain: 'fix-me',
                    username: firstName + ' ' + lastName,
                    phone: phoneNumber.length > 0 ? phoneNumber.replace(/[^+\d]+/g, '') : undefined,
                  },
                },
              })
              if (res.data && res.data.createUser) {
                setIsSubmitting(false)
                handleClose()
              } else {
                setIsSubmitting(false)
                setError('Something went wrong. Please try again.')
              }
            }}
          >
            Invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AddUsersModal
