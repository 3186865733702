import { Flex } from '@chakra-ui/react'
import FlowFormMessage from './FlowFormMessage'

const ShareTaskFormMessage = ({ flowState, handleMessageChange }) => {
  return (
    <Flex mb="auto" pr={6}>
      <FlowFormMessage value={flowState?.message} onChange={handleMessageChange} />
    </Flex>
  )
}

export default ShareTaskFormMessage
