import React, { useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Text, Flex, Box, Icon, ModalBody, ModalFooter } from '@chakra-ui/react'
import { GET_NODES, CREATE_NODE } from 'features/node/node.schema'
import { useAppSlice, useProjectSlice, useBoxSlice, useTipSlice } from 'features/redux'
import { Button, TextInput, ModalExternalButton, Toast } from 'components'
import { CreateNewFolder } from 'pages/components'
import { uploadFile } from 'helpers/storage'
import { nodeState, nodeType } from 'helpers/constant'
import { icons, customIcons } from 'theme'
import { Node } from 'types/graphqlSchema'
import MoveToBreadcrumbs from './MoveToBreadcrumbs'
import MoveToItem from './MoveToItem'
import { ITip } from 'types/tip'
import { useNavigate } from 'react-router-dom'

interface BreadcrumbProps {
  label: string
  type: string
  item: any
}

interface MoveToMenuProps {
  closeAction: () => void
  movingResource?: Node | undefined
  redirect?: boolean
}

const MoveToMenu = ({ closeAction, movingResource, redirect = false }: MoveToMenuProps) => {
  const apolloClient = useApolloClient()
  const navigate = useNavigate()
  const {
    user: { myProject, organizationId },
  } = useAppSlice()
  const { projects } = useProjectSlice()
  const { dispatch, boxes, updateBox, moveBox, setBoxes } = useBoxSlice()
  const { tips, moveTip, setTips } = useTipSlice()

  const [openCreate, setOpenCreate] = useState('')
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([{ label: 'Tipbox', type: 'default', item: {} }])
  const [showing, setShows] = useState('default')
  const [listOfBoxes, setListOfBoxes] = useState<any[]>([])
  const [disableButton, setDisableButton] = useState(false)
  const [keyword, setKeyword] = useState('')

  const projectsInOrganisation = useMemo(() => {
    return projects.filter((proj) => proj.organizationId === organizationId && proj.id !== myProject.id)
  }, [projects])

  const defaultOptions = [
    {
      icon: customIcons.projectIcon,
      name: 'Projects',
      clickValue: 'projects',
    },
    {
      icon: customIcons.personalIcon,
      name: 'Personal',
      clickValue: `${myProject.id}`,
    },
  ]
  // Function handlers
  const getChildrenBox = async (item: any, updateBreadcrumb = true) => {
    if (!item) return
    const params = {
      filter: {
        type: 'multi_conditions',
        conditions: {
          parentId: item.id,
          type: nodeType.box,
          state: nodeState.active,
        },
      },
    }
    try {
      const {
        data: { nodes },
      } = await apolloClient.query({
        query: GET_NODES,
        variables: { ...params },
      })
      setListOfBoxes(nodes)
      setShows('boxes')
      if (updateBreadcrumb) {
        setBreadcrumbs((prev) => [...prev, { label: item.name, type: item.type, item }])
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const handleUndoMove = async () => {
    if (movingResource?.type === nodeType.box) {
      const movingBox = boxes.find((b: any) => b.id === movingResource.id)
      await dispatch(moveBox({ resourceId: movingResource.id, destinationId: movingBox?.parent.id }))
      dispatch(setBoxes(boxes))
    } else if (movingResource?.type === nodeType.tip) {
      const movingTip: ITip | undefined = tips.find((tip: ITip) => tip.id === movingResource.id)
      if (movingTip) {
        await dispatch(moveTip({ resourceId: movingResource.id, destinationId: movingTip.parent.id }))
        dispatch(setTips(tips))
      }
    }
  }

  const handleMove = async () => {
    try {
      const { item, label } = breadcrumbs[breadcrumbs.length - 1]
      if (movingResource?.type === nodeType.box) {
        await dispatch(moveBox({ resourceId: movingResource.id, destinationId: item.id }))
      } else if (movingResource?.type === nodeType.tip) {
        await dispatch(moveTip({ resourceId: movingResource.id, destinationId: item.id }))
      }
      closeAction()
      if (redirect && movingResource?.id) {
        await navigate(`/link/${movingResource.id}`)
      } else if (redirect) {
        await navigate(`/link/${item.id}`)
      }
      Toast.show({
        icon: 'check',
        message: `Your ${movingResource?.type === nodeType.tip ? 'file' : 'folder'} was successfully moved to "${
          item.name ?? label
        }".`,
        onUndo: () => {
          handleUndoMove()
        },
      })
    } catch (e) {
      Toast.show({
        icon: 'error',
        message: `Something went wrong. Failed to move ${movingResource?.type === nodeType.box ? 'folder' : 'file'}`,
      })
    }
  }

  // Upload new Box to s3 and updateDB
  const uploadImage = async (image: File, box: IBox) => {
    if (!image) return

    const uploadResponse = await uploadFile(image)
    const params = {
      coverImage: uploadResponse.fileUrl,
      id: box.id,
    }
    const updatedBox = await dispatch(updateBox(params))
    setListOfBoxes([updatedBox.payload, ...listOfBoxes])
  }

  // CREATE NEW BOX
  const handleCreateBox = async (boxName: string, coverImage: File | undefined) => {
    const { item, type } = breadcrumbs[breadcrumbs.length - 1]
    const projectId = type === 'box' ? item.project.id : item.id

    const input: ICreateBoxInput = {
      name: boxName,
      projectId,
      state: nodeState.active,
      type: nodeType.box,
      parentId: type === 'box' ? item.id : projectId,
    }
    try {
      const {
        data: { createNode },
      } = await apolloClient.mutate({
        mutation: CREATE_NODE,
        variables: { input },
      })
      if (coverImage) {
        uploadImage(coverImage, createNode)
      } else {
        setListOfBoxes([createNode, ...listOfBoxes])
      }
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  const navigateBack = (type: string, item: any, index: number) => {
    const remainBreadcrumb = [...breadcrumbs].slice(0, index + 1)
    setBreadcrumbs(remainBreadcrumb)
    setShows(type)
    if (type === 'box' || type === 'project' || type === 'personal' || type === 'BOX' || type === 'PROJECT') {
      getChildrenBox(item, false)
    }
  }

  return (
    <>
      <ModalBody overflow="hidden" bg="textHighlight" color="textBlack">
        <Text pb={6} color="inherit">
          Please select a destination project or folder.
        </Text>
        <TextInput
          placeholder="Search"
          value={keyword}
          handleChange={({ target: { value } }) => {
            setShows('default')
            setBreadcrumbs([{ label: 'Tipbox', type: 'default', item: {} }])
            setKeyword(value)
          }}
          leftIcon={icons.search}
          rightIcon={icons.close}
          rightIconVariant="passwordBtn"
        />
        <Flex pt={8} pb={4}>
          <MoveToBreadcrumbs clickAction={navigateBack} breadcrumbs={breadcrumbs} />
        </Flex>

        <Box h="71%" overflow="auto" className="no-scroll-bar">
          {/* PROJECTS OR BOXES KEYWORD SEARCH */}
          {keyword.trim() && showing === 'default' && (
            <>
              {projectsInOrganisation
                .filter((project) => project.name.trim().toLowerCase().includes(keyword.trim().toLocaleLowerCase()))
                .map((project: any) => (
                  <MoveToItem
                    key={project.id}
                    image={project.coverImage}
                    label={project.name}
                    clickAction={() => {
                      setShows('projects')
                      setBreadcrumbs((prev) => [
                        ...prev,
                        {
                          label: 'Projects',
                          type: 'projects',
                          item: {},
                        },
                      ])
                      getChildrenBox(project)
                    }}
                  />
                ))}
              {boxes
                .filter((box) => box.name.trim().toLowerCase().includes(keyword.trim().toLocaleLowerCase()))
                .map((box: any) => (
                  <React.Fragment key={box.id}>
                    {box.id !== movingResource?.id && (
                      <MoveToItem
                        image={box.coverImage}
                        label={box.name}
                        clickAction={() => {
                          setShows('personal')
                          setBreadcrumbs((prev) => [
                            ...prev,
                            {
                              label: 'Personal',
                              type: 'personal',
                              item: myProject,
                            },
                          ])
                          getChildrenBox(box)
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
            </>
          )}
          {/* HOME OF DEFAULT SEARCH */}
          {!keyword.trim() && showing === 'default' && (
            <>
              {defaultOptions.map((option) => (
                <MoveToItem
                  key={option.name}
                  icon={option.icon}
                  label={option.name}
                  clickAction={() => {
                    if (option.clickValue === 'projects') {
                      setShows('projects')
                      setBreadcrumbs((prev) => [
                        ...prev,
                        {
                          label: 'Projects',
                          type: 'projects',
                          item: {},
                        },
                      ])
                    } else {
                      setShows('personal')
                      setBreadcrumbs((prev) => [
                        ...prev,
                        {
                          label: 'Personal',
                          type: 'personal',
                          item: myProject,
                        },
                      ])
                      getChildrenBox(myProject, false)
                    }
                  }}
                />
              ))}
            </>
          )}
          {/* PROJECT SEARCH */}
          {showing === 'projects' && (
            <>
              {projectsInOrganisation.map((projectOption: any) => (
                <MoveToItem
                  key={projectOption.id}
                  image={projectOption.coverImage}
                  icon={customIcons.projectIcon}
                  label={projectOption.name}
                  clickAction={() => {
                    getChildrenBox(projectOption)
                  }}
                />
              ))}
            </>
          )}
          {/* BOX SEARCH */}
          {showing === 'boxes' && (
            <>
              {listOfBoxes.map((boxesOption: any) => (
                <React.Fragment key={boxesOption.id}>
                  {boxesOption.id !== movingResource?.id && (
                    <MoveToItem
                      image={boxesOption.coverImage}
                      label={boxesOption.name}
                      clickAction={() => {
                        getChildrenBox(boxesOption)
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </Box>
      </ModalBody>
      <ModalFooter py={0} px={8}>
        <Flex justify="space-between" w="full" h="105px" py={8} borderTop="1px" borderColor="borderRegular">
          <Button
            variant="iconBtnRed"
            label="Create a new folder"
            leftIcon={<Icon as={customIcons.addBoxIcon} color="accent" w="24px" h="24px" pt={0} />}
            onClick={() => {
              setOpenCreate('newBox')
            }}
            style={{
              maxWidth: '180px',
              maxHeight: '40px',
              padding: 0,
              fontSize: '16px',
            }}
            disabled={showing !== 'boxes'}
          />
          <Flex>
            <Button variant="btnOutline" onClick={closeAction} label="Cancel" style={{ marginRight: '16px' }} />
            <Button
              variant="defaultButton"
              onClick={() => {
                setDisableButton(true)
                handleMove()
              }}
              label="Move"
              disabled={showing !== 'boxes' || disableButton}
            />
          </Flex>
        </Flex>
      </ModalFooter>
      {/* NEW BOX */}
      <ModalExternalButton
        header="New Folder"
        isOpen={openCreate === 'newBox'}
        close={() => {
          setOpenCreate(openCreate === 'newBox' ? '' : openCreate)
        }}
      >
        <CreateNewFolder openModal={openCreate} setOpenModal={setOpenCreate} moveToCreate={handleCreateBox} inMoveTo />
      </ModalExternalButton>
    </>
  )
}

export default MoveToMenu
