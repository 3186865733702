import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useAppSlice, useWorkflowSlice } from 'features/redux'
import { Flex, Box, useDisclosure } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { uniqBy } from 'lodash'
import { workflowConstant } from 'helpers/constant'
import { useFlowActions } from 'features/workflow/hooks'
import { USERS, GET_USER_GUEST_SHARE_MEMBERS } from 'features/graphql'
import { Toast, UsersAndGroupsSelect, UsersSelectInput } from 'components'
import { Flow, Maybe, Node, User, UserGroup } from 'types/graphqlSchema'
import { regs } from 'helpers/vali'
import { useFlowForm } from './hooks/useFlowForm'
import { UserOrGuest, FlowGuest as Guest } from './types/flow-form-types'
import { useAdmin } from 'pages/Admin/hooks'
import ShareTaskFormFooter from './components/ShareTaskFormFooter'
import { useNodeDetails, useNodeMenuActions } from 'features/node'
import ShareTaskFormMessage from './components/ShareTaskFormOptions'
import ShareTaskFormRecipientList from './components/ShareTaskFormRecipientList'
import ShareTaskFormTaskUI from './components/ShareTaskFormTaskUI'
import { areArraysEqual } from 'helpers/utils'

interface props {
  flowType: string
  node: Node
  flowState: FlowState
  setFlowState: React.Dispatch<React.SetStateAction<FlowState>>
  handleNewFlowOnNode?: () => void
  flowsData?: Flow[]
  includeTasks?: boolean
  onClose: () => void
}

// Type Helpers
const isUser = (user: UserOrGuest): user is User => 'id' in user
const isValidUser = (user: Maybe<User>): user is User => user !== null && user !== undefined && 'id' in user
export const taskTypes = {
  approval: 'approval',
  file: 'file',
  feedback: 'feedback',
} as const
export type TaskType = (typeof taskTypes)[keyof typeof taskTypes]

const ShareTaskForm = ({
  node,
  flowState,
  setFlowState,
  flowType,
  handleNewFlowOnNode,
  flowsData,
  includeTasks = false,
  onClose,
}: props) => {
  // Users
  const { user } = useAppSlice()
  const [fetchUsers] = useLazyQuery(USERS)
  const [fetchGuests] = useLazyQuery(GET_USER_GUEST_SHARE_MEMBERS)

  // Groups
  const { userGroups, setUserGroups } = useAdmin()
  const [recipientGroups, setRecipientGroups] = useState<UserGroup[]>([])
  const [groupSelectOptions, setGroupSelectOptions] = useState<UserGroup[]>([])

  const usersAndGroupsSelectControls = useDisclosure()
  const [groupForModal, setGroupForModal] = useState<UserGroup | null>(null)

  // Tasks
  const { workflows } = useWorkflowSlice()
  const { createFlow, startFlow, removeUserFromFlow } = useFlowActions()

  const [selectedTaskType, setSelectedTaskType] = useState<TaskType>(taskTypes.approval)
  const handleChangeTaskType = (newValue: TaskType) => {
    setSelectedTaskType(newValue)
  }

  // Sharing
  const { shareNode, unshareNode, removeGroupFromNode } = useNodeMenuActions()

  // Task Participants
  const { members, sharedGroups, refetchNodeGroups } = useNodeDetails(node)
  const [recipientsInputValue, setRecipientsInputValue] = useState('')
  const [usersData, setUsersData] = useState<User[]>()
  const [userSelectOptions, setUserSelectOptions] = useState<UserOrGuest[]>([])
  const [recipients, setRecipients] = useState<UserOrGuest[]>([])
  const recipientEmails = useMemo(() => {
    const recEmails = [...recipients].map((r) => r.email)
    const recGroupEmails = recipientGroups.flatMap((g) => g.users?.map((u) => u?.email) || [])
    return [...new Set([...recEmails, ...recGroupEmails].filter(Boolean))] as string[]
  }, [recipients, recipientGroups])

  useEffect(() => {
    if (!includeTasks) {
      setRecipients(members)
    }
  }, [includeTasks, members])

  useEffect(() => {
    setRecipientGroups(sharedGroups)
  }, [sharedGroups])

  const { isMobile, setIsSubmitting, usersSelectOpen, onUsersSelectOpen, onUsersSelectClose, isSubmitting } =
    useFlowForm({
      recipientGroupsLength: recipientGroups.length,
      flowRecipientEmails: recipientEmails,
      flowState: {
        due: flowState.due,
        name: flowState.name,
      },
      attachedProject: node.project ?? undefined,
    })

  // Create and Add Guests
  const [newGuest, setNewGuest] = useState<Guest | null>(null)
  const [createdGuests, setCreatedGuests] = useState<Guest[]>([])

  // Effects and Memos 10

  const modalHeight = useMemo(() => {
    const baseHeight = includeTasks ? 320 : 260
    const usersLength =
      recipientEmails.filter((r) => !sharedGroups.some((g) => g.users?.some((u) => u?.email === r))).length +
      recipientGroups.length

    let heightAddition = 0
    if (usersSelectOpen && !includeTasks) heightAddition += 155

    if (!usersSelectOpen && usersLength > 1) {
      heightAddition += 85
    }

    if (includeTasks) heightAddition += 90

    return baseHeight + heightAddition
  }, [recipientEmails?.length, recipientGroups?.length, isMobile, includeTasks, usersSelectOpen, recipients])

  // Initialize recipient options
  useEffect(() => {
    const initRecipientOptions = async () => {
      let potentialFlowMembers: User[] = []
      const [usersResult, guestUsersResult] = await Promise.all([fetchUsers(), fetchGuests()])
      const orgUsers = usersResult.data.users
      const guestUsers = guestUsersResult.data?.me?.shareEmailSuggestions || []
      potentialFlowMembers = uniqBy([...orgUsers, ...guestUsers], 'id')

      // TODO: allow self-tasking
      potentialFlowMembers = potentialFlowMembers.filter((u) => u.email !== user.email)
      // currently current user is removed

      const userDataWithEmailValue = potentialFlowMembers.map((user) => ({
        ...user,
        value: user.email,
        label: user.firstName + ' ' + user.lastName,
      }))
      setGroupSelectOptions(userGroups)
      setUsersData(potentialFlowMembers)
      setUserSelectOptions(userDataWithEmailValue)
    }
    initRecipientOptions()
  }, [userGroups, members, selectedTaskType])

  // EFFECT -
  useEffect(() => {
    filterRecipientOptions()
    filterGroupOptions()
  }, [usersData, createdGuests])

  // SETTERS

  const filterRecipientOptions = (currentValue?: string) => {
    if (!usersData && (!createdGuests || createdGuests.length === 0)) {
      setUserSelectOptions([])
      return
    }
    const userDataWithEmailValue = usersData
      ? usersData.map((user) => ({
          ...user,
          value: user.email || '',
          label: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
        }))
      : []
    const options =
      Array.isArray(createdGuests) && createdGuests.length > 0
        ? [...createdGuests, ...userDataWithEmailValue]
        : userDataWithEmailValue
    const trimmedInput = (currentValue || recipientsInputValue || '').toLowerCase().trim()

    let filteredOptions = options
    if (trimmedInput.length > 1) {
      filteredOptions = options.filter((option) => {
        const firstName = (option.firstName || '').toLowerCase()
        const lastName = (option.lastName || '').toLowerCase()
        const email = (option.email || '').toLowerCase()
        const fullName = `${firstName} ${lastName}`.trim()
        return (
          firstName.startsWith(trimmedInput) ||
          lastName.startsWith(trimmedInput) ||
          fullName.startsWith(trimmedInput) ||
          email.startsWith(trimmedInput)
        )
      })
    }
    setUserSelectOptions(filteredOptions)
  }

  const filterGroupOptions = () => {
    if (!userGroups || userGroups.length === 0) return

    let filteredOptions = userGroups
    if (recipientsInputValue.length > 1) {
      filteredOptions = filteredOptions.filter((option) => {
        if (!option.users || option.users.length === 0) return false
        return option.users.some((user) => {
          const isUser = (user: Maybe<User>): user is User => {
            if (!user) return false
            return 'id' in user
          }
          if (!isUser(user)) return false
          const fullName = `${user.firstName} ${user.lastName}`.toLowerCase()
          return (
            user.firstName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
            user.lastName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
            fullName.startsWith(recipientsInputValue.toLowerCase()) ||
            user.email.toLowerCase().startsWith(recipientsInputValue.toLowerCase())
          )
        })
      })
    }

    const filteredList = filteredOptions
    setGroupSelectOptions(filteredList)
  }

  // Memo
  const selectedRecipients = useMemo(() => {
    return recipients
  }, [recipients])

  const selectedGroups = useMemo(() => {
    return recipientGroups
  }, [recipientGroups])

  // HANDLERS

  const handleInputChange = (value: string) => {
    // Check if deletion happened (new value is empty or shorter)
    const isDeletion = !value || (recipientsInputValue && value.length < recipientsInputValue.length)

    setRecipientsInputValue(value)
    const isEmail = regs.email.test.test(value)
    const isMyEmail = user?.email === value
    const isEmailFoundInUsersData = usersData?.some((u) => u.email === value)

    filterGroupOptions()

    if (isDeletion) {
      if (!value.trim()) {
        // deleted whole input
        setUserSelectOptions(usersData || [])
      } else {
        filterRecipientOptions(value)
      }
    } else {
      filterRecipientOptions(value)
    }

    if (!isEmail || isMyEmail || isEmailFoundInUsersData) {
      setNewGuest(null)
      return
    }

    const capitalize = (part: string) => part.charAt(0).toUpperCase() + part.slice(1)
    const [firstName, rest] = value.split('@')
    const [lastName] = rest.split('.')
    const guest = {
      email: value,
      firstName: capitalize(firstName),
      lastName: capitalize(lastName),
      organization: { name: 'Guest' },
    }
    setNewGuest(guest)
  }

  const handleAddGuest = () => {
    if (newGuest) {
      setCreatedGuests((prev) => {
        if (prev) {
          return [newGuest, ...prev]
        } else {
          return [newGuest]
        }
      })
      filterRecipientOptions('')
      setRecipientsInputValue('')
      setNewGuest(null)
    }
  }

  const toggleSelectUser = (emailAddress: string) => {
    if (!emailAddress) return
    const user = userSelectOptions.find((u) => u.email === emailAddress)
    if (!user) return

    const selected = recipients.some((sel) => sel.email === user.email)
    if (selected) {
      const updatedList = recipients.filter((r) => r.email !== user.email)
      setRecipients(updatedList)
    } else {
      setRecipients((prev) => [...prev, user])
    }
  }

  const toggleSelectGroup = (groupId: string) => {
    const group = userGroups.find((g) => g.id === groupId)
    if (!group) return

    const selected = selectedGroups?.some((sel) => sel.id === groupId)
    if (selected) {
      setRecipientGroups((prev) => prev.filter((g) => g.id !== groupId))
    } else {
      setRecipientGroups((prev) => [...prev, group])
    }
  }

  const handleMessageChange = (e) => {
    let inputValue = e.target.value
    const maxLength = 250

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength)
    }

    setFlowState((state) => ({
      ...state,
      message: inputValue,
    }))
  }

  const handleShareSent = async () => {
    if (!node) return
    if (!recipients && (!recipientGroups || recipientGroups.length === 0)) {
      return
    }

    // Don't re-share existing members
    // Helpful when switching from public to private (org members won't also be sharedMembers)
    const alreadySharedEmails = members?.map((sm) => sm?.email?.toLowerCase()) || []

    // emails from selections in modal
    const recipientEmails = recipients.filter((r) => !alreadySharedEmails.includes(r.email?.toLowerCase()))

    // emails from groups selected in modal
    const recipientGroupEmails = recipientGroups
      .flatMap((g) => g?.users?.map((u) => u?.email))
      .filter((email): email is string => email !== undefined && !alreadySharedEmails.includes(email))

    // final unique new emails list
    const uniqueEmails = [...new Set([...recipientEmails.map((r) => r.email), ...recipientGroupEmails])]
      .filter((email) => email && !alreadySharedEmails.includes(email.toLowerCase()))
      .join(', ')

    const shareResponse = await shareNode(uniqueEmails, node.id, '', recipientGroups.map((g) => g.id) ?? [''])

    if (shareResponse?.id) {
      setRecipientGroups([])
      setRecipients([])
      Toast.show({
        icon: 'check',
        message: 'Recipients Added',
      })
      onClose()
      return shareResponse.id
    }
  }

  const handleShareAndTasks = async () => {
    setIsSubmitting(true)

    try {
      await handleShareSent()

      if (!includeTasks) {
        return
      }

      if (recipientEmails.length > 0) {
        const taskCreated = await createTaskBasedOnType()

        if (taskCreated && handleNewFlowOnNode) {
          handleNewFlowOnNode()
        }
      }
      onClose()
    } catch (error) {
      console.error('Error in handleShareAndTasks:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const createTaskBasedOnType = async () => {
    const workflowIds = {
      approval: workflows?.find((wf) => wf?.name === workflowConstant.approval.name)?.id,
      feedback: workflows?.find((wf) => wf?.name === workflowConstant.feedback.name)?.id,
    }

    const taskConfig = {
      flowName: node.name,
      projectId: node.projectId,
      organizationId: node.organizationId,
      nodeId: node.id,
      dueDate: flowState.due,
      urgent: flowState.urgent,
      message: flowState.message,
      emails: recipientEmails,
      reminderFrequency: flowState.reminder.selected ? flowState.reminder.interval : 0,
    }

    switch (selectedTaskType) {
      case 'approval':
        if (workflowIds.approval) {
          return await startNewWorkflow(
            workflowConstant.approval.name,
            workflowIds.approval,
            { ...taskConfig, uploadNeed: false },
            'Approval',
          )
        }
        return false

      case 'feedback':
        if (workflowIds.feedback) {
          return await startNewWorkflow(
            workflowConstant.feedback.name,
            workflowIds.feedback,
            { ...taskConfig, uploadNeed: false },
            'Feedback',
          )
        }
        return false

      case 'file':
        if (workflowIds.feedback) {
          return await startNewWorkflow(
            workflowConstant.feedback.name,
            workflowIds.feedback,
            { ...taskConfig, uploadNeed: true },
            'File request',
          )
        }
        return false

      default:
        return false
    }
  }

  const startNewWorkflow = async (workflowName, workflowId, config, taskLabel) => {
    try {
      const startingFlow = await createFlow({
        name: config.flowName,
        projectId: config.projectId,
        organizationId: config.organizationId,
        nodeId: config.nodeId,
      })

      const { flowId } = await startFlow(workflowName, {
        flowId: startingFlow?.id,
        dueDate: config.dueDate,
        urgent: config.urgent,
        uploadNeed: config.uploadNeed,
        requesterMessage: config.message,
        projectId: config.projectId,
        workflowId,
        participantsEmails: config.emails,
        remindersFrequency: config.reminderFrequency,
      })

      if (flowId) {
        Toast.show({
          icon: 'check',
          message: `${taskLabel} task created.`,
        })
        return true
      }

      return false
    } catch (error) {
      console.error(`An error occurred while creating ${taskLabel.toLowerCase()} task:`, error)
      return false
    }
  }

  const unshareGroupUsers = async (userGroup: UserGroup, nodeId: string): Promise<void> => {
    if (!userGroup.users?.length) return

    // type check users and exclude current user
    const validGroupUsers = userGroup.users.filter(isValidUser).filter((u) => u.id !== user.id)

    const usersInOtherGroups = sharedGroups
      .filter((group) => group.id !== userGroup.id) // Exclude current group
      .flatMap((group) => group.users || [])
      .filter(isValidUser)
      .map((u) => u.id)

    // Find users that only exist in this group
    // so overlapping users from other groups will remain shared
    const uniqueUsersToThisGroup = validGroupUsers.filter((u) => !usersInOtherGroups.includes(u.id))

    for (const u of uniqueUsersToThisGroup) {
      try {
        if (flowsData) {
          await removePendingFlows(u, flowsData)
        }

        await unshareNode(u.id, nodeId)
      } catch (error) {
        console.error('Failed to remove user:', error)
      }
    }
  }

  const removePendingFlows = async (user: User, flowsData: Flow[]): Promise<void> => {
    const pendingFlowsWithUser = flowsData.filter(
      (f) => f?.state?.stateName === 'Pending' && f?.participants?.some((p) => p?.id === user.id),
    )

    if (pendingFlowsWithUser.length > 0) {
      await Promise.all(
        pendingFlowsWithUser
          .filter((f): f is Flow & { id: string } => f !== null && f !== undefined && typeof f.id === 'string')
          .map((f) => removeUserFromFlow(f.id, user.id)),
      )
    }
  }

  const handleUnshare = async (user?: UserOrGuest, userGroup?: UserGroup) => {
    if (userGroup) {
      const isPending = !sharedGroups.some((g) => g.id === userGroup.id)

      if (isPending || includeTasks) {
        const newRecipients = Array.from(
          new Set([...recipients.filter((r) => !userGroup.users?.find((u) => u?.email === r.email)), ...members]),
        )
        setRecipients(newRecipients)
        setRecipientGroups((prev) => prev.filter((g) => g.id !== userGroup.id))
      } else {
        if (userGroup.users?.length) {
          await unshareGroupUsers(userGroup, node.id)
          await removeGroupFromNode(node.id, userGroup.id)
          refetchNodeGroups({ id: node.id })
        }
      }
      return
    }

    if (user) {
      const isPending = !members.some((m) => m.email === user.email)
      if (isPending || includeTasks) {
        setRecipients((prev) => prev.filter((r) => r.email !== user.email))
        return
      }
      if (!isUser(user)) {
        console.error('User is not a valid user')
        return
      }
      try {
        if (flowsData) {
          await removePendingFlows(user, flowsData)
        }
        await unshareNode(user.id, node.id)
      } catch (error) {
        console.error('Failed to remove user:', error)
      }
    }
  }

  const submitButtonDisabled = () => {
    if (usersSelectOpen) return false

    return (
      isSubmitting ||
      (recipients.length === 0 && recipientGroups.length === 0) ||
      (!includeTasks &&
        areArraysEqual(
          members.map((m) => m.email),
          [...recipientEmails, ...recipientGroups.map((g) => g?.users?.map((u) => u?.email))].flat(),
        ))
    )
  }

  // Handle blur for Users Select (close on click outside)
  const userSelectContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (usersAndGroupsSelectControls.isOpen) return
    const handleClickOutside = (event) => {
      if (userSelectContainerRef.current && !userSelectContainerRef.current.contains(event.target)) {
        if (usersSelectOpen) {
          onUsersSelectClose()
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [userSelectContainerRef, usersSelectOpen, usersAndGroupsSelectControls.isOpen])

  return (
    <motion.div
      layout
      key={flowType}
      aria-label={flowType + ' Flow'}
      initial={{ height: 30, opacity: 0 }}
      animate={{
        height: modalHeight,
        opacity: 1,
      }}
      transition={{
        type: 'ease',
        duration: 0.6,
        opacity: { delay: 0.6 },
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '.5rem' : '1rem',
        width: '100%',
        fontSize: isMobile ? '12px' : '14px',
        overflow: 'hidden',
      }}
    >
      <Flex ref={userSelectContainerRef} mx={6} mt={4} flexDir="column">
        <Box
          w="full"
          border="1px solid var(--chakra-colors-borderLight)"
          roundedTop={6}
          roundedBottom={usersSelectOpen ? 0 : 6}
          borderBottom={usersSelectOpen ? '1px solid transparent' : '1px solid var(--chakra-colors-borderLight)'}
        >
          <UsersSelectInput
            value={recipientsInputValue}
            onChange={handleInputChange}
            isOpen={usersSelectOpen}
            onOpen={onUsersSelectOpen}
            onClose={onUsersSelectClose}
          />
        </Box>
        {usersSelectOpen && (
          <UsersAndGroupsSelect
            userSelectOptions={userSelectOptions}
            selectedOptions={selectedRecipients}
            selectedGroups={selectedGroups}
            toggleSelectUser={toggleSelectUser}
            toggleSelectGroup={toggleSelectGroup}
            guestValue={newGuest}
            handleAddGuest={handleAddGuest}
            userGroups={groupSelectOptions}
            setUserGroups={setUserGroups}
            users={usersData ?? []}
            usersAndGroupsSelectControls={usersAndGroupsSelectControls}
            groupForModal={groupForModal}
            setGroupForModal={setGroupForModal}
          />
        )}
      </Flex>

      {!usersSelectOpen && (
        <>
          <ShareTaskFormRecipientList
            recipientGroups={recipientGroups}
            handleUnshare={handleUnshare}
            sharedGroups={sharedGroups}
            recipients={recipients}
            members={members}
            isPrivate={node.private ?? false}
            organization={node.organization ?? undefined}
            isShare={!includeTasks}
          />
          {includeTasks && (
            <ShareTaskFormTaskUI
              recipientGroups={recipientGroups}
              selectedTaskType={selectedTaskType}
              recipients={recipients}
              handleChangeTaskType={handleChangeTaskType}
              flowState={flowState}
              setFlowState={setFlowState}
            />
          )}

          <ShareTaskFormMessage flowState={flowState} handleMessageChange={handleMessageChange} />
        </>
      )}
      <ShareTaskFormFooter
        label={includeTasks ? 'Assign Task' : 'Share'}
        owner={node?.owner}
        submitDisabled={submitButtonDisabled()}
        usersSelectOpen={usersSelectOpen}
        onUsersSelectClose={onUsersSelectClose}
        submit={handleShareAndTasks}
        isSubmitting={isSubmitting}
      />
    </motion.div>
  )
}

export default ShareTaskForm
