import {
  VStack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Icon,
  PopoverBody,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import { IconButton } from 'components'
import { useAppSlice } from 'features/redux'
import { Flow } from 'types/graphqlSchema'
import { flowActions, workflowConstant } from 'helpers/constant'
import { icons } from 'theme'

/**
 * popover body contents for pending state
 * @param param
 * @returns
 */
function FlowMenuPendingState({
  isRequester,
  onClickMenu,
}: {
  isRequester: boolean
  onClickMenu: (action: string) => void
}) {
  return isRequester ? (
    <IconButton
      label="Cancel"
      ariaLabel={flowActions.cancelFlow}
      icon={icons.FcCancel}
      onClick={onClickMenu}
      iconMarginLeft={-2}
      iconSize={{ width: 5, height: 4 }}
      variant="dropDownButton"
      iconMarginRight={2}
    />
  ) : (
    <IconButton
      label="Remove myself"
      ariaLabel={flowActions.removeFromFlow}
      onClick={onClickMenu}
      variant="dropDownButton"
    />
  )
}

/**
 * popover body contents for archived state
 * @param param
 * @returns
 */
function FlowMenuArchivedState({ onClickMenu }: { onClickMenu: (string) => void }) {
  return (
    <IconButton
      label="Delete"
      ariaLabel={flowActions.deleteFlow}
      icon={icons.deleteIcon}
      onClick={onClickMenu}
      iconMarginLeft={-2}
      iconSize={{ width: 5, height: 4 }}
      variant="dropDownButton"
      iconMarginRight={2}
      style={{ color: 'accent' }}
    />
  )
}

/**
 * popover body contents for other state
 * @param param
 * @returns
 */
function FlowMenuOtherState({ onClickMenu }: { onClickMenu: (string) => void }) {
  return (
    <IconButton
      label="Move to Archive"
      ariaLabel={flowActions.archiveFlow}
      onClick={onClickMenu}
      variant="dropDownButton"
      style={{ paddingTop: 3, paddingBottom: 3 }}
    />
  )
}

function FlowMenuPopup({
  flow,
  isHovering,
  onClickMenu,
  onMouseEnter,
  onMouseLeave,
  isArchived,
  position = 'absolute',
}: {
  flow: Flow
  isHovering: boolean
  onClickMenu: (action: string) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  isArchived?: boolean
  position?: string
}) {
  const { user } = useAppSlice()
  const { isOpen, onToggle, onClose } = useDisclosure()

  const isRequester = flow.requester?.id === user.id
  const isParticipant = flow.participants?.some((participant) => participant?.id === user.id)
  const isPendingState = flow.state?.stateName === workflowConstant.approval.state.pending

  if (!isRequester && isArchived && isParticipant) return <></>
  return (
    <Popover isLazy closeOnBlur onClose={onClose} variant="autoWidth" isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          sx={{ position }}
          right={2}
          variant="transparentButton"
          visibility={isHovering ? 'visible' : 'hidden'}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onToggle}
        >
          <Icon as={icons.menuIcon} w="20px" h="16px" color={useColorModeValue('black', 'white')} />
        </Button>
      </PopoverTrigger>
      <PopoverContent p={0} bg="black" border="none" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <PopoverBody display="flex" flexDir="column" p={0} bg="textHighlight" borderRadius="8px" overflow="hidden">
          <VStack w="full">
            {isPendingState && <FlowMenuPendingState isRequester={isRequester} onClickMenu={onClickMenu} />}
            {isArchived && (!isParticipant || isRequester) && <FlowMenuArchivedState onClickMenu={onClickMenu} />}
            {!isPendingState && !isArchived && <FlowMenuOtherState onClickMenu={onClickMenu} />}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default FlowMenuPopup
