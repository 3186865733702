import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ProductFruits } from 'react-product-fruits'
import { Box, Flex } from '@chakra-ui/react'
import { Loader } from 'components'
import { useAppSlice } from 'features/app'
import CONFIG from 'helpers/config'

const Main = lazy(() => import('./main'))
const Auth = lazy(() => import('./auth'))

const AppRoutes = () => {
  const { dispatch, user, loggedIn, notAuthorized, addAccount, linkShareAccess, authenticate, toggleNotAuthorization } =
    useAppSlice()

  const redirect = async () => {
    try {
      dispatch(toggleNotAuthorization(false))
      await dispatch(authenticate())
    } catch (error) {
      throw new Error(`checking authenticate error: ${error}`)
    }
  }

  useEffect(() => {
    redirect()
    return () => {
      dispatch(toggleNotAuthorization(false))
    }
  }, [])

  const renderRoutes = () => {
    if (loggedIn && !notAuthorized && !linkShareAccess && !addAccount) {
      return (
        <Box w="full">
          <Main />
        </Box>
      )
    } else {
      return <Auth />
    }
  }

  return (
    <BrowserRouter>
      <Flex w="full" h="full" justify="center" alignItems="center" alignContent="center">
        <Suspense fallback={<Loader />}>{renderRoutes()}</Suspense>
      </Flex>
    </BrowserRouter>
  )
}

export default AppRoutes
