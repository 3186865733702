import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  Avatar,
  Text,
  Flex,
  Box,
  Portal,
  Image,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { formateDeleteDate } from 'helpers/utils'
import { useNodeDetails } from 'features/node/hooks'
import { AvatarsTooltip } from 'components/elements'
import { EntityType, Node, Tag } from 'types/graphqlSchema'
import React, { useEffect } from 'react'
import { useTags } from 'features/tags'
import { images } from 'theme'

type NodeInfoPopOverProps = {
  node: Node
  setOpenModal?: (name: string) => void
  children: React.ReactNode
  tags?: Tag[]
}

function NodeInfoPopover({ node, setOpenModal, children, tags }: NodeInfoPopOverProps) {
  const {
    type,
    itemName,
    ownerName,
    ownerUrl,
    members,
    totalSize,
    lastModifiedBy,
    createdDate,
    updatedDate,
    updatedAt,
    refetchNodeMembers,
  } = useNodeDetails(node)
  const { pathname } = useLocation()
  const { fetchTags } = useTags()

  useEffect(() => {
    if (tags && tags?.length > 0) return
    if (!node || !node.id) return
    fetchTags(node.id, EntityType.Node)
  }, [node, tags])

  useEffect(() => {
    refetchNodeMembers()
  }, [node.updatedAt])

  return (
    <Popover isLazy placement="auto" closeOnBlur={true}>
      <PopoverTrigger>
        <Button
          variant="transparentButton"
          p={0}
          h="auto"
          _focusVisible={{ boxShadow: '0 0 0 1px var(--chakra-colors-borderFocus)' }}
        >
          {children}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          p={4}
          bg="textHighlight"
          border="1px"
          borderColor="textSoft"
          color="textBlack"
          onClick={(e) => e.stopPropagation()}
        >
          <PopoverCloseButton mr="8px" mt="20px" />
          <PopoverHeader borderBottom="1px" borderColor="textSoft" pb="16px">
            {itemName || 'Untitled'}
          </PopoverHeader>
          <PopoverBody display="flex" flexDir="column" px={0} color="textBlack">
            {pathname.includes('trash') && (
              <Text fontSize="15px" color="alert" mb={6}>
                {`This ${type?.toLowerCase()} will be permanently deleted on ${formateDeleteDate(updatedAt, 1)}`}
              </Text>
            )}
            <Avatar size="sm" name={ownerName} src={ownerUrl} border="1px solid white" mb={2} />
            <Text mb={6} fontSize="15px" fontWeight="normal" color="textRegular">
              Owner{' '}
              <Text as="span" color="textBlack">
                {ownerName}
              </Text>
            </Text>
            <Box mb={members.length > 0 ? 2 : 0}>
              <AvatarsTooltip
                users={members}
                max={9}
                size="sm"
                clickAction={() => setOpenModal && setOpenModal('share')}
              />
            </Box>
            <Text fontSize="15px" fontWeight="normal" color="textRegular" mb="8px">
              Shared with
            </Text>
            <Flex flexDir="column" px={0} py="8px" borderTop="1px" borderColor="textSoft" fontSize="15px">
              <Text mb={4} color="textRegular">
                Created{' '}
                <Text as="span" color="textBlack">
                  {createdDate}
                </Text>
              </Text>
              <Text mb={4} color="textRegular">
                Last Modified <Text as="span" color="textBlack">{`${updatedDate} by ${lastModifiedBy}`}</Text>
              </Text>
              <Flex direction="row" justify="space-between">
                <Text flex={1} color="textRegular">
                  Size{' '}
                  <Text as="span" color="textBlack">
                    {totalSize}
                  </Text>
                </Text>
              </Flex>
            </Flex>
            {tags && tags?.length > 0 && (
              <Box borderTop="1px" borderColor="textSoft" fontSize="15px" mt={4} pt={4}>
                <Flex fontSize="15px" mb={2}>
                  <Image src={images.tagGrey} alt="tag" />
                  <Text pl={1} color="textRegular">
                    Tags
                  </Text>
                </Flex>
                {tags.map((tag) => (
                  <Flex fontSize="15px" key={tag.id}>
                    <Image src={images.tagGrey} alt="tag" />
                    <Text pl={1} color={'textBlack'}>
                      {tag.name}
                    </Text>
                  </Flex>
                ))}
              </Box>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default NodeInfoPopover
