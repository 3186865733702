import React, { useCallback, useEffect, ReactNode, useState } from 'react'
import { Grid, Box, Text, Flex, Spinner } from '@chakra-ui/react'
import { useAppSlice } from 'features/app/app.slice'
import { Droppable } from 'react-beautiful-dnd'
import { TipCard } from './TipCard'
import { Node } from 'types/graphqlSchema'

interface TipsGridProps {
  tips: Node[]
  viewType: string
  pageType?: string
  noBorder?: boolean
  shouldTipReorder?: boolean
  setDragEnterIndex?: React.Dispatch<React.SetStateAction<number | undefined>>
  setDraggingTip?: React.Dispatch<React.SetStateAction<string>>
  displayBar?: ReactNode
}

const TipsGrid = ({
  tips,
  viewType,
  pageType,
  noBorder,
  shouldTipReorder,
  setDragEnterIndex,
  setDraggingTip,
  displayBar,
}: TipsGridProps) => {
  const { dispatch, setPopupMenuTips, sortType } = useAppSlice()
  const [duplicatingTip, setDuplicatingTip] = useState<string>('')
  const [deletingTip, setDeletingTip] = useState<string>('')

  const [displayedTips, setDisplayedTips] = useState<Node[]>([])

  useEffect(() => {
    if (deletingTip || !tips || tips.length === 0) return

    const formattedTips = tips.map((item) => ({
      itemId: item.id,
      isOpen: false,
    }))

    dispatch(setPopupMenuTips(formattedTips))

    const isSortedByName = sortType.default === 'Name'
    const sortedTips = isSortedByName ? [...tips].sort((a, b) => a.name.localeCompare(b.name)) : tips

    if (!duplicatingTip && !deletingTip) {
      setDisplayedTips(sortedTips)
    }
  }, [tips, duplicatingTip, deletingTip, sortType])

  const tipsLayout = useCallback(
    (placeholder?: any) => (
      <Grid
        gridTemplateColumns={
          viewType === 'grid'
            ? [
                'repeat(auto-fill, minmax(103px, 1fr))',
                null,
                'repeat(auto-fill, minmax(90px, 1fr))',
                'repeat(auto-fill, minmax(130px, 1fr))',
                'repeat(auto-fill, minmax(180px, 1fr))',
              ]
            : 'full'
        }
        position="relative"
        gridGap={viewType === 'grid' ? ['3rem 1rem', null, null, '3rem 1rem'] : [4]}
      >
        {displayedTips?.map((tip, idx) => {
          const showSpinner = duplicatingTip === tip.id
          return (
            <React.Fragment key={tip?.id}>
              <TipCard
                key={tip?.id}
                tip={tip}
                alerts={0}
                index={idx}
                pageType={pageType}
                noBorder={noBorder}
                shouldTipReorder={shouldTipReorder}
                setDragEnterIndex={setDragEnterIndex}
                setDraggingTip={setDraggingTip}
                setDuplicatingTip={setDuplicatingTip}
                setDeletingTip={setDeletingTip}
                deletingTip={deletingTip}
              />
              {showSpinner && (
                <Spinner
                  key={tip.id}
                  placeSelf="center"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="accent"
                  size="lg"
                />
              )}
            </React.Fragment>
          )
        })}
        {placeholder}
      </Grid>
    ),
    [viewType, displayedTips, pageType, noBorder, shouldTipReorder, duplicatingTip, deletingTip],
  )
  // * Variables
  const droppableContainer = (
    <Droppable droppableId="2" direction={viewType === 'grid' ? 'horizontal' : 'vertical'}>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.droppableProps}>
          {tipsLayout(provided.placeholder)}
        </Box>
      )}
    </Droppable>
  )
  const nonDroppableContainer = tipsLayout()
  return (
    <Flex flex={1} mb={12} direction="column" w="full">
      <Flex dir="row" justify="space-between" align="center" mb={[4, null, null, 6]}>
        <Text fontWeight="700">Files</Text>
        {displayBar}
      </Flex>
      {viewType === 'grid' ? nonDroppableContainer : droppableContainer}
    </Flex>
  )
}

export default TipsGrid
