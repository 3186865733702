// NOTE: Not sure why we needed all these manual settings for opening and closing popover?
// That causes problem with https://tipbox.atlassian.net/browse/TA-428
// These seem to be unneccessary, hence commented out those code until we find the reason for this.
import React, { useEffect, useRef } from 'react'
import { IconType } from 'react-icons'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Flex,
  PopoverProps,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { IconButton } from 'components'

interface IconPopoverProps extends PopoverProps {
  icon: IconType
  children: React.ReactNode
  variantBtn?: string
  iconSize?:
    | { height: number | string; width: number | string }
    | { height: (number | string | null)[]; width: (number | string | null)[] }
  iconColor?: string
  variantPopover?: string
  buttonStyle?: React.CSSProperties
  style?: React.CSSProperties
  showPortal?: boolean
  flexMinWidth?: number | string
  xMargin?: number | string
  menuOpen?: boolean
  setMenuOpen?: (menuOpen: boolean) => void
  itemId?: string
  menuType?: 'project' | 'box' | 'tip' | 'flow' | null | undefined
  testId?: string
}

const IconPopover = ({
  icon,
  variantBtn = 'iconBtn',
  iconSize,
  iconColor,
  variantPopover = 'autoWidth',
  placement = 'bottom-end',
  buttonStyle,
  children,
  style,
  flexMinWidth = 4,
  showPortal = true,
  xMargin = 'auto',
  testId,
}: IconPopoverProps) => {
  const popoverRef = useRef<HTMLElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    document.addEventListener('mousedown', onClose)
    return () => {
      document.removeEventListener('mousedown', onClose)
    }
  }, [popoverRef, onOpen, onClose, isOpen])

  return (
    // <Popover placement={placement} variant={variantPopover} isOpen={isWindowOpen()} isLazy>
    <Popover placement={placement} variant={variantPopover} isLazy isOpen={isOpen}>
      <PopoverTrigger>
        <Flex minW={flexMinWidth} mx={xMargin} align="center" sx={{ ...style }}>
          <IconButton
            ariaLabel="popover button"
            onClick={() => onOpen()}
            iconSize={iconSize}
            icon={icon}
            flexDir={['column', null, 'column']}
            iconColor={iconColor}
            variant={variantBtn}
            justify={['center']}
            style={buttonStyle}
            testId={testId}
          />
        </Flex>
      </PopoverTrigger>
      {showPortal ? (
        <Portal>
          <PopoverContent ref={popoverRef} p={0} _focus={{ boxShadow: 'none' }} borderRadius={8}>
            <PopoverBody p={0} borderRadius={8}>
              {children}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      ) : (
        <PopoverContent p={0} _focus={{ boxShadow: 'none' }} borderRadius={8} bg="whiteAlpha.600" zIndex="10">
          <PopoverBody p={0} borderRadius={8}>
            {children}
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  )
}

export default IconPopover
