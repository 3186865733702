import React, { useState, Dispatch, useEffect } from 'react'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Image,
  Avatar,
  Text,
  Button,
  useBreakpointValue,
  useColorModeValue,
  Divider,
  Center,
  // Progress,
  useDisclosure,
} from '@chakra-ui/react'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { find } from 'lodash'
import { AlertWithAction, IconButton } from 'components'
import { icons, images } from 'theme'
import { getResizedImage } from 'helpers/storage'
import { useAppSlice } from 'features/app'
import { PrivacySetting, User, UserGroup } from 'types/graphqlSchema'
import { CreateUserVariables, UserRoles } from 'pages/Admin/hooks/useAdmin'
import { useAdmin } from 'pages/Admin/hooks'
import { FetchResult } from '@apollo/client'

export interface AdminContext {
  privacyValue: PrivacySetting
  setPrivacyValue: Dispatch<React.SetStateAction<PrivacySetting>>
  orgNameFromDB: string
  orgLogoFromDB: string
  orgNameValue: string
  setOrgNameValue: Dispatch<React.SetStateAction<string>>
  users?: User[]
  userGroups?: UserGroup[]
  setUserGroups: React.Dispatch<React.SetStateAction<UserGroup[]>>
  userRoles: UserRoles
  setUserRoles: React.Dispatch<React.SetStateAction<UserRoles>>
  handleInviteUser: (variables: {
    variables: {
      input: CreateUserVariables['input']
    }
  }) => Promise<
    FetchResult<{
      createUser: User
    }>
  >
}

const AdminLayout = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [navigateValue, setNavigateValue] = useState<string>('')
  const [hovering, setHovering] = useState('')

  const {
    adminMenus,
    users,
    userGroups,
    setUserGroups,
    privacyValue,
    setPrivacyValue,
    orgNameValue,
    setOrgNameValue,
    changedValues,
    setChangedValues,
    handleSaveChanges,
    userRoles,
    setUserRoles,
    isSaving,
    orgData,
    fetchOrgData,
    handleInviteUser,
  } = useAdmin()

  const {
    user: {
      firstName,
      lastName,
      avatarSecureUrl,
      organization,
      organization: { name: orgNameFromDB, logoSecureUrl, privacySetting: orgPrivacySetting },
    },
  } = useAppSlice()

  const handleBackButton = () => {
    if (changedValues.length < 1) {
      navigate(-1)
    } else {
      setNavigateValue('back')
      onOpenAlert()
    }
  }

  const resetValues = async () => {
    await onCloseAlert()
    await fetchOrgData()
  }

  useEffect(() => {
    if (!orgData) return

    const newChangedValues = Object.keys({
      privacyValue: privacyValue !== orgPrivacySetting,
      orgNameValue: orgNameValue !== orgNameFromDB,
      userRoles:
        JSON.stringify(userRoles) !==
        JSON.stringify(
          orgData.organization.users.map((user) => {
            return { role: user.role, userId: user.id, userOrgId: user.organizationId }
          }),
        ),
    }).filter((key) => {
      return {
        privacyValue: privacyValue !== orgPrivacySetting,
        orgNameValue: orgNameValue !== orgNameFromDB,
        userRoles:
          JSON.stringify(userRoles) !==
          JSON.stringify(
            orgData.organization.users.map((user) => {
              return { role: user.role, userId: user.id, userOrgId: user.organizationId }
            }),
          ),
      }[key]
    })
    setChangedValues(newChangedValues)
  }, [userRoles, orgData, orgNameValue, orgNameFromDB, privacyValue, orgPrivacySetting])

  return (
    <>
      <Grid
        flexDir="column"
        h="full"
        w="full"
        minH="99.8vh"
        bg="background"
        templateAreas={`"header header"
                      "nav subheader"
                      "nav main"
                      "nav footer"`}
        gridTemplateRows={'min-content 100px 1fr 75px'}
        gridTemplateColumns={'min-content 1fr'}
      >
        {/* Admin main header */}
        <GridItem area={'header'}>
          <Flex alignItems="center" px={6} py={2}>
            <Flex alignItems="center" onClick={handleBackButton} style={{ cursor: 'pointer' }} w="80px">
              <IconButton
                variant="iconBtnSm"
                onClick={() => {}}
                ariaLabel="go back"
                icon={icons.backArrow}
                style={{
                  color: 'accentIcon',
                }}
              />
              <Heading textAlign="center" color="textRegular">
                Back
              </Heading>
            </Flex>
            <Spacer />
            <Flex alignItems="center" gap={6} align={'center'}>
              <Link to="/dashboard" className="homeLink">
                <Image src={useColorModeValue(images.Logo, images.LogoDark)} />
              </Link>

              <Avatar
                name={avatarSecureUrl ? '' : `${firstName} ${lastName}`}
                size={'sm'}
                src={avatarSecureUrl ? getResizedImage(avatarSecureUrl, 'profile.main') : ''}
              />
            </Flex>
          </Flex>
        </GridItem>
        {/* Left Nav */}
        <GridItem area={'nav'} bg="menuBackground" mr={3} pt={4}>
          <Flex direction="column" h="full" px={2}>
            <Box px={4}>
              <Text variant="smallBold" color="textRegular" lineHeight="32px">
                {organization.name}
              </Text>
              <Text variant="largeHighlightBold">Administration</Text>
            </Box>
            <Box py={7}>
              <Divider borderColor="borderRegular" />
            </Box>
            <Flex as="nav" justify="flex-start" flexDir="column" h="full">
              {adminMenus.map((menu) => (
                <Flex
                  key={menu.path}
                  height="50px"
                  onMouseEnter={() => setHovering(menu.name)}
                  onMouseLeave={() => setHovering('')}
                >
                  <Center>
                    <Divider
                      visibility={pathname === menu.path ? 'visible' : 'hidden'}
                      orientation="vertical"
                      borderColor="accentIcon"
                      borderWidth="1px"
                      height="20px"
                    />
                  </Center>
                  <IconButton
                    ariaLabel={menu.name}
                    onClick={() => {
                      setNavigateValue(menu.path)
                      if (changedValues.length < 1) {
                        navigate(menu.path, { replace: true })
                      } else {
                        onOpenAlert()
                      }
                    }}
                    icon={menu.icon}
                    iconSize={{ width: 5, height: 5 }}
                    iconColor={pathname === menu.path || hovering === menu.name ? 'accentIcon' : undefined}
                    iconMarginRight={3}
                    label={menu.name}
                    variant={pathname === menu.path ? 'listButtonSelect' : 'listButton'}
                  />
                </Flex>
              ))}
            </Flex>
            {/* <Spacer /> */}
            {/* <Box px={2} py={4}>
              <Flex alignItems={'center'}>
                <Text variant="tiny" color="textRegular">
                  1.8 MB of 10.0 GB used
                </Text>
                <Spacer />
                <IconButton
                  icon={icons.externalLink}
                  iconSize={{ width: 4, height: 4 }}
                  iconColor="textRegular"
                  onClick={() => {}}
                  ariaLabel="Data Storage"
                  flexDir={['row']}
                />
              </Flex>
              <Progress bgColor="textRegular" size="xs" value={18} />
            </Box> */}
          </Flex>
        </GridItem>
        {/* Subheader */}
        <GridItem area={'subheader'} px={isMobile ? 0 : 6} bg="menuBackground">
          <Flex alignItems={'center'}>
            <Text variant="largeHighlightBold">{find(adminMenus, ['path', pathname])?.subtitle}</Text>
            <Spacer />
            <Button
              variant="defaultButton"
              isDisabled={changedValues.length < 1 || isSaving}
              px={6}
              mt={6}
              onClick={handleSaveChanges}
              isLoading={isSaving}
            >
              Save Changes
            </Button>
          </Flex>
        </GridItem>
        {/* Main Content */}
        <GridItem area={'main'} w="full" px={isMobile ? 0 : 6} bg="menuBackground">
          <Outlet
            context={
              {
                privacyValue,
                setPrivacyValue,
                orgNameValue,
                setOrgNameValue,
                orgNameFromDB,
                orgLogoFromDB: logoSecureUrl,
                users,
                userGroups,
                setUserGroups,
                userRoles,
                setUserRoles,
                handleInviteUser,
              } satisfies AdminContext
            }
          />
        </GridItem>
        {/* Footer */}
        <GridItem area={'footer'} bg="menuBackground"></GridItem>
      </Grid>
      <AlertWithAction
        action={async () => {
          await handleSaveChanges()
          onCloseAlert()
          if (navigateValue === 'back') {
            navigate(-1)
          } else {
            await resetValues()
            navigate(navigateValue, { replace: true })
          }
        }}
        cancelAction={async () => {
          await onCloseAlert()
          if (navigateValue === 'back') {
            navigate(-1)
          } else {
            await resetValues()
            navigate(navigateValue, { replace: true })
          }
        }}
        actionText="You have unsaved changes"
        warningText={['Would you like to save the changes you made?']}
        confirmButtonText="Save Changes"
        cancelButtonText="No"
        isOpen={isOpenAlert}
        onClose={onCloseAlert}
      />
    </>
  )
}

export default AdminLayout
