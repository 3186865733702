import { useState, useMemo } from 'react'
import { Box, Flex, IconButton, Popover, PopoverTrigger, PopoverContent, Portal, Icon, Tooltip } from '@chakra-ui/react'
import { Dot } from 'components/elements'
import { useDashboardUpdates } from 'features/dashboard/hooks'
import { TrackingEvent } from 'types/graphqlSchema'
import AlertItem from './AlertItem'
import { icons } from 'theme'

const filterTypes = {
  mentions: 'mentions',
  flows: 'flows',
  following: 'following',
  comments: 'comments',
  shares: 'shares',
}

export default function Alerts() {
  const { dotAlerts, handleItemClick, allMentionsEvents, allFlowsEvents, allFollowingEvents, allOtherEvents } =
    useDashboardUpdates()

  const [activeFilters, setActiveFilters] = useState(Object.values(filterTypes))

  const allEvents = useMemo(() => {
    const events = [...allMentionsEvents, ...allFlowsEvents, ...allFollowingEvents, ...allOtherEvents].sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )

    return events.filter((event) => {
      if (event.eventType.includes('MENTIONED') && activeFilters.includes(filterTypes.mentions)) return true
      if (event.eventType.includes('FLOW') && activeFilters.includes(filterTypes.flows)) return true
      if (event.eventType.includes('FOLLOWED') && activeFilters.includes(filterTypes.following)) return true
      if (event.eventType.includes('SHARED') && activeFilters.includes(filterTypes.shares)) return true
      if (event.eventType.includes('COMMENT') && activeFilters.includes(filterTypes.comments)) return true
      return false
    })
  }, [allMentionsEvents, allFlowsEvents, allFollowingEvents, allOtherEvents, activeFilters])

  const hasUnreadNotifications = dotAlerts.some((alert) => alert.alert)

  const toggleFilter = (filterType: string) => {
    setActiveFilters((prev) =>
      prev.includes(filterType) ? prev.filter((f) => f !== filterType) : [...prev, filterType],
    )
  }

  const FilterButton = ({ type, icon, label }) => {
    const activeFilterColor = activeFilters.includes(type) ? 'accent' : 'textSoft'
    return (
      <Tooltip label={label} zIndex={999} hasArrow fontSize="13px" rounded="lg" size="lg" placement="top" bg="accent">
        <IconButton
          color={activeFilterColor}
          aria-label={label}
          icon={<Icon as={icon} />}
          variant="ghost"
          colorScheme={activeFilterColor}
          onClick={() => toggleFilter(type)}
        />
      </Tooltip>
    )
  }

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Box position="relative" p={4}>
          <IconButton color="textHighlight" aria-label="Notifications" icon={<Icon as={icons.BiBell} h={8} w={8} />} />
          {hasUnreadNotifications && (
            <Dot visible={true} size={2} bgColor="red.400" position="absolute" top={2} right={2} />
          )}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxH="600px" overflowY="auto" bgColor={'white'}>
          <Box p={4}>
            <Flex justify="center" align="space-evenly" gap={2} mb={4}>
              <FilterButton type={filterTypes.mentions} icon={icons.GoMention} label="Mentions" />
              <FilterButton type={filterTypes.flows} icon={icons.TbArrowsExchange} label="Tasks" />
              <FilterButton type={filterTypes.following} icon={icons.BsFolder2} label="Following" />
              <FilterButton type={filterTypes.comments} icon={icons.BsChatDots} label="Comments" />
              <FilterButton type={filterTypes.shares} icon={icons.HiShare} label="Shares" />
            </Flex>

            {allEvents.map((event: TrackingEvent) => (
              <AlertItem key={event.id} event={event} handleItemClick={handleItemClick} />
            ))}
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
