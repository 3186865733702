import { Grid } from '@chakra-ui/react'
import { FlowFilter } from 'features/workflow/Flows.types'
import { useFlowFilters } from 'features/workflow/hooks'
import { useNavigate } from 'react-router-dom'
import { icons } from 'theme'
import FlowTodoCard from './FlowTodoCard'
import { Node } from 'types/graphqlSchema'

interface FTDCprops {
  project?: Node
}

const FlowTodoCards = ({ project }: FTDCprops) => {
  const { todoFlows, todoUrgentFlows, todoOverdueFlows, todoDueTodayFlows } = useFlowFilters()
  const navigate = useNavigate()

  const all = project?.id ? todoFlows.filter((flow) => flow.projectId === project?.id) : todoFlows
  const urgent = project?.id ? todoUrgentFlows.filter((flow) => flow.projectId === project?.id) : todoUrgentFlows
  const overdue = project?.id ? todoOverdueFlows.filter((flow) => flow.projectId === project?.id) : todoOverdueFlows
  const today = project?.id ? todoDueTodayFlows.filter((flow) => flow.projectId === project?.id) : todoDueTodayFlows

  return (
    <Grid
      gridTemplateColumns={{
        base: 'repeat(2, 1fr)',
        md: 'repeat(4, 1fr)',
        lg: 'repeat(2,  1fr)',
        xl: 'repeat(4, 1fr)',
      }}
      mx={{ base: 'auto', md: 'unset' }}
      mb={{ base: 8, xl: 0 }}
      gap={{ base: '2rem', md: '1rem' }}
    >
      <FlowTodoCard
        name="Urgent"
        icon={icons.BsExclamationSquareFill}
        highlight={urgent.length > 0}
        count={urgent.length}
        onClick={() => navigate('/flows', { state: { filter: FlowFilter.urgent, projectId: project?.id } })}
      />
      <FlowTodoCard
        name="Overdue"
        count={overdue.length}
        onClick={() => navigate('/flows', { state: { filter: FlowFilter.overdue, projectId: project?.id } })}
      />
      <FlowTodoCard
        name="Today"
        count={today.length}
        onClick={() => navigate('/flows', { state: { filter: FlowFilter.today, projectId: project?.id } })}
      />
      <FlowTodoCard
        name="All"
        count={all.length}
        onClick={() => navigate('/flows', { state: { projectId: project?.id } })}
      />
    </Grid>
  )
}

export default FlowTodoCards
