import { Flex, Icon, Text } from '@chakra-ui/react'
import { IconType } from 'react-icons'

type FlowCardProps = {
  name: string
  icon?: IconType
  highlight?: boolean
  count: number
  onClick: () => void
}

const FlowTodoCard = ({ name, icon, highlight = false, count, onClick }: FlowCardProps) => {
  return (
    <Flex
      direction="column-reverse"
      w={{ base: '24vw', md: '6.5rem', xl: '6.5vw' }}
      h={{ base: '24vw', md: '6.5rem', xl: '7.5vw' }}
      p={4}
      bg="background"
      border="1px solid"
      borderRadius={6}
      borderColor={highlight ? 'textSoft' : 'transparent'}
      onClick={onClick}
      position="relative"
      color="textRegular"
      _hover={{ bg: 'accent', color: 'textHighlight', cursor: 'pointer' }}
    >
      <Flex align="center" color="inherit" fontSize={{ base: 12, '2xl': 14 }} fontWeight={400} lineHeight={'.85rem'}>
        {icon && (
          <Icon as={icon} mr={1} color="inherit" boxSize={{ base: 4, md: 3, '2xl': 3 }} transform={'translateY(1px)'} />
        )}
        {name}
      </Flex>

      <Text
        fontSize={{ base: '1.65rem', sm: '7vw', md: '1.5rem', lg: '1.75rem', '2xl': '2rem' }}
        fontWeight={600}
        color="inherit"
        top={{ base: '.5rem', sm: '.6rem', md: '.7rem', xl: '.65rem', '2xl': '1.65rem' }}
        position="absolute"
      >
        {count}
      </Text>
    </Flex>
  )
}

export default FlowTodoCard
