import { useState } from 'react'
import { Flex, Avatar, Text as ChakraText, Box } from '@chakra-ui/react'
import { EditorContent, useEditor } from '@tiptap/react'
import Mention from '@tiptap/extension-mention'
import Text from '@tiptap/extension-text'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import { Dot } from 'components/elements'
import { formatTimeSince } from 'helpers/utils'
import { EVENT_TYPE } from 'helpers/constant'
import { ItemProps } from 'features/dashboard/Dashboard.types'
import './mentions.scss'

const MentionsItem = ({ event, handleItemClick }: ItemProps) => {
  const [isHovering, setHovering] = useState(false)
  const editor = useEditor({
    content: event.customFields.message,
    extensions: [
      Document,
      Paragraph,
      Text,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
      }),
    ],
  })

  return (
    <Box
      as="button"
      w="full"
      textAlign="left"
      onClick={() => handleItemClick(event)}
      borderLeft="16px solid"
      borderColor={isHovering ? 'accent' : 'transparent'}
      bg="bg"
      _hover={{ bg: 'bg' }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Flex p={4} borderTop="1px" borderColor="textSoft" mb={event.isRead ? 0 : '2px'}>
        <Avatar
          size="md"
          name={event.user?.avatarSecureUrl ? '' : `${event.user?.firstName} ${event.user?.lastName}`}
          src={event.user?.avatarSecureUrl ? event.user?.avatarSecureUrl : ''}
        />
        <Flex pl={4} flexDir="column" flex={1}>
          <ChakraText variant="mediumHighlight">
            {event.user?.firstName} {event.user?.lastName} mentioned you in a comment -
          </ChakraText>
          <ChakraText variant="mediumHighlightBold">
            {event.eventType === EVENT_TYPE.USER_MENTIONED
              ? ` File: ${event.customFields.entityName}`
              : ` Flow: ${event.customFields.entityName}`}
          </ChakraText>
          <Box py={2}>
            <EditorContent className="tiptapDashboardMentions" editor={editor} />
          </Box>
          <Flex gap={6}>
            <ChakraText variant="tinyCharcoal">{formatTimeSince(event.createdAt, 'time')}</ChakraText>
            <ChakraText variant="tinyCharcoal">Project: {event.customFields.projectName}</ChakraText>
          </Flex>
        </Flex>
        <Flex align="center" px={2}>
          <Dot visible={!event.isRead} size="10px" bgColor="accent" />
        </Flex>
      </Flex>
    </Box>
  )
}

export default MentionsItem
