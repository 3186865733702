import { useEffect, useState } from 'react'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import {
  FETCH_AVAILABLE_TAGS,
  FETCH_TAGS_FROM_NODE,
  FETCH_TAGS_FROM_FLOW,
  TAG_ENTITY,
  UNTAG_ENTITY,
  CREATE_TAG,
  UPDATE_TAG,
} from 'features/graphql'
import { EntityType, Tag } from 'types/graphqlSchema'

export default () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [availableTags, setAvailableTags] = useState<Tag[]>([])
  const [attachedTags, setAttachedTags] = useState<string[]>([])
  const [previousTags, setPreviousTags] = useState<Tag[]>([])

  const [mutateCreateTag] = useMutation(CREATE_TAG)

  const [fetchAvailableTags] = useLazyQuery(FETCH_AVAILABLE_TAGS, {
    onCompleted: (data) => {
      if (!data) return
      setAvailableTags(data.tags)
    },
    onError: (error: ApolloError) => {
      console.log(error.message)
    },
  })
  const [fetchTagsFromNode] = useLazyQuery(FETCH_TAGS_FROM_NODE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: async (data) => {
      if (data && data.node?.tags) {
        setPreviousTags(data.node.tags)
      }
    },
    onError: (error: ApolloError) => {
      console.log(error.message)
    },
  })
  const [fetchTagsFromFlow] = useLazyQuery(FETCH_TAGS_FROM_FLOW, {
    notifyOnNetworkStatusChange: true,
    onCompleted: async (data) => {
      if (data && data.flowById?.flow?.tags) {
        setPreviousTags(data.flowById.flow.tags)
      }
    },
    onError: (error: ApolloError) => {
      console.log(error.message)
    },
  })

  const [mutateAddTag] = useMutation(TAG_ENTITY)
  const [mutateRemoveTag] = useMutation(UNTAG_ENTITY)

  useEffect(() => {
    if (!previousTags) return
    setAttachedTags(previousTags.map((prev) => prev.name))
  }, [previousTags])

  const fetchTags = async (entityId, entityType) => {
    if (!entityId || !entityType) return
    setLoading(true)
    if (entityType === EntityType.Node) {
      await fetchTagsFromNode({ variables: { id: entityId } })
    } else if (entityType === EntityType.Flow) {
      await fetchTagsFromFlow({ variables: { id: entityId } })
    }
    setLoading(false)
  }

  const addTag = async (entityId: string, entityType: EntityType, tagId: string) => {
    if (!entityId || !tagId) return
    try {
      const { data } = await mutateAddTag({
        variables: {
          tagId,
          entityId,
          entityType,
        },
      })
      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  const createTag = async (tagName: string, entityId = '', entityType: EntityType = EntityType.Node) => {
    if (!tagName) return
    try {
      const { data } = await mutateCreateTag({
        variables: {
          name: tagName,
        },
      })
      if (data?.createTag && entityId && entityType) {
        await mutateAddTag({
          variables: {
            tagId: data.createTag.id,
            entityId,
            entityType,
          },
        })
      }
      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  const [updateTag] = useMutation(UPDATE_TAG)

  const removeTag = async (entityId: string, entityType: EntityType, tagId: string) => {
    if (!entityId || !tagId) return
    try {
      const { data } = await mutateRemoveTag({
        variables: {
          tagId,
          entityId,
          entityType,
        },
      })
      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  return {
    fetchAvailableTags,
    fetchTags,
    createTag,
    addTag,
    removeTag,
    setAttachedTags,
    attachedTags,
    updateTag,
    availableTags,
    previousTags,
    loading,
  }
}
