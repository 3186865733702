import { useEffect, useState } from 'react'
import { useNodeDetails, useNodeMenuActions } from 'features/node'
import { useAppSlice } from 'features/redux'
import PrivacyToggle from '../PrivacySwitch/PrivacyToggle'

export function ProjectPrivacySwitch({ node }) {
  const { user } = useAppSlice()
  const { updateNode } = useNodeMenuActions()
  const { refetchNodeMembers } = useNodeDetails(node)
  const isProject = node?.type === 'PROJECT'
  const showPrivacySwitch = node && !node?.parent?.private
  const [isPrivate, setIsPrivate] = useState(() => !!node.private)

  const handlePrivacyChange = (privacySetting: boolean) => {
    if (isPrivate !== privacySetting) {
      setIsPrivate(privacySetting)
    }
  }

  useEffect(() => {
    if (node.private === isPrivate) return

    const handleUpdate = async () => {
      try {
        await updateNode({ private: isPrivate }, node)
        refetchNodeMembers()
      } catch (error) {
        console.error('Error updating node privacy:', error)
      }
    }
    handleUpdate()
  }, [isPrivate])

  return (
    isProject && showPrivacySwitch && <PrivacyToggle user={user} node={node} onPrivacyChange={handlePrivacyChange} />
  )
}
