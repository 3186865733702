import { useState } from 'react'
import { Flex, Text as ChakraText, Box, Image, Icon } from '@chakra-ui/react'
import { Dot } from 'components/elements'
import { formatForDate, formatTimeSince } from 'helpers/utils'
import { useFlowContents } from 'features/workflow/hooks'
import { ItemProps } from 'features/dashboard/Dashboard.types'

const FlowsItem = ({ event, handleItemClick }: ItemProps) => {
  const [isHovering, setHovering] = useState(false)
  const { getFlowEventContent } = useFlowContents()
  const flowEventContent = getFlowEventContent(event)
  const { flow, projectName } = event.customFields

  return (
    <Box
      as="button"
      w="full"
      textAlign="left"
      onClick={() => handleItemClick(event)}
      borderLeft="16px solid"
      borderColor={isHovering ? 'accent' : 'transparent'}
      bg="bg"
      _hover={{ bg: 'bg' }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Flex p={4} borderTop="1px" borderColor="textSoft" mb={event.isRead ? 0 : '2px'}>
        <Image src={flowEventContent.image} />
        <Flex pl={4} flexDir="column" flex={1}>
          {flowEventContent.header && (
            <Flex align="center">
              {flowEventContent.headerIcon && (
                <Icon as={flowEventContent.headerIcon} mr={2} color="accent" boxSize={18} />
              )}
              <ChakraText variant="mediumHighlightBold">{flowEventContent.header}</ChakraText>
            </Flex>
          )}
          <ChakraText variant="mediumHighlight">{flowEventContent.subHeader}</ChakraText>
          <ChakraText variant="mediumHighlightBold">Task Name: {flow.name}</ChakraText>
          <ChakraText variant="smallCharcoal">Due: {formatForDate(flow?.dueDate)}</ChakraText>
          {flow?.requesterMessage && (
            <ChakraText variant="tiny" py={1}>
              "{flow?.requesterMessage}"
            </ChakraText>
          )}
          <Flex gap={6}>
            <ChakraText variant="tinyCharcoal">{formatTimeSince(flow.createdAt, 'time')}</ChakraText>
            <ChakraText variant="tinyCharcoal">Project: {projectName}</ChakraText>
          </Flex>
        </Flex>
        <Flex align="center" px={2}>
          <Dot visible={!event.isRead} size="10px" bgColor="accent" />
        </Flex>
      </Flex>
    </Box>
  )
}

export default FlowsItem
