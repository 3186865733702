import React from 'react'
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, Button, PopoverProps } from '@chakra-ui/react'

interface ButtonPopoverProps extends PopoverProps {
  icon?: JSX.Element
  rightIcon?: JSX.Element
  children: React.ReactNode
  variantBtn?: string
  buttonLabel: string
  buttonContent?: React.ReactNode
  varinantPopover?: string
  buttonStyle?: React.CSSProperties
  btnWidth?: string
  marginXBtn?: string
  isDisable?: boolean
  testId?: string
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
}

const ButtonPopover = ({
  icon,
  buttonLabel,
  variantBtn = 'iconBtn',
  varinantPopover = 'autoWidth',
  placement = 'bottom-end',
  buttonStyle,
  buttonContent,
  rightIcon,
  marginXBtn = 'auto',
  btnWidth = '8.125rem',
  isDisable = false,
  testId,
  isOpen,
  onOpen,
  onClose,
  children,
}: ButtonPopoverProps) => {
  return (
    <Popover
      placement={placement}
      variant={varinantPopover}
      isLazy
      returnFocusOnClose={false}
      closeOnBlur
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Button
          data-testid={testId}
          aria-label={buttonLabel}
          w={btnWidth}
          mx={marginXBtn}
          leftIcon={icon}
          rightIcon={rightIcon}
          variant={variantBtn}
          style={buttonStyle}
          isDisabled={isDisable}
        >
          {buttonContent || buttonLabel}
        </Button>
      </PopoverTrigger>
      <PopoverContent p={0} _focus={{ boxShadow: 'none' }} borderRadius={8}>
        <PopoverBody p={0} borderRadius={8}>
          {children}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default ButtonPopover
