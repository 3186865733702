import React from 'react'
import { Button, Input, useColorModeValue } from '@chakra-ui/react'
import { CountrySelector, usePhoneInput, defaultCountries, parseCountry } from 'react-international-phone'
import { colors } from 'theme'
import './PhoneNumberInput.css'

interface PhoneNumberInputProps {
  value: string
  onChange: (phone) => void
  disabled: boolean
  fontSize?: number
}
// OPTION: Add Countries Dropdown Later if we expand beyond USA and CANADA
// const countries = defaultCountries.filter((country) => {
//   const { iso2 } = parseCountry(country)
//   return ['us', 'ca'].includes(iso2)
// })

const PhoneNumberInput = ({ value, onChange, disabled, fontSize }: PhoneNumberInputProps) => {
  const phoneInput = usePhoneInput({
    defaultCountry: 'ca',
    value,
    onChange: (data) => {
      onChange(data.phone)
    },
  })

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* <CountrySelector
        countries={countries}
        selectedCountry={phoneInput.country}
        onSelect={(country) => phoneInput.setCountry(country.iso2)}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button {...rootProps} variant="outline" px="4px" mr="8px" isDisabled={disabled}>
            {children}
          </Button>
        )}
      /> */}
      <Input
        fontSize={fontSize}
        placeholder="Enter Mobile Number"
        type="tel"
        value={phoneInput.phone}
        onChange={phoneInput.handlePhoneValueChange}
        width={250}
        ref={phoneInput.inputRef}
        mr="8px"
        borderColor={'tipbox.dark.border.light'}
        rounded={'8px'}
        variant="outline"
        borderRadius={4}
        disabled={disabled}
      />
    </div>
  )
}

export default PhoneNumberInput
