import { Flex, Input } from '@chakra-ui/react'
import { AnimatedMenuChevron } from 'components/elements'
import React from 'react'

interface UserSelectProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  value: string
  onChange: (value: string) => void
}

const UsersSelectInput = ({ isOpen, onOpen, onClose, value, onChange }: UserSelectProps) => {
  return (
    <Flex align="center" flex={1} pr={2}>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => onOpen()}
        flex={1}
        placeholder="Recipients: Enter a name or email address"
        bg="transparent"
        color="textBlack"
        fontSize="14px"
        boxShadow="none"
        border="none"
        _placeholder={{ color: 'tipbox.dark.text.charcoalGrey' }}
        _focusVisible={{ border: 'none', boxShadow: 'none' }}
        _active={{ border: 'none', boxShadow: 'none' }}
      />
      <button onClick={() => (isOpen ? onClose() : onOpen())} style={{ color: 'var(--chakra-colors-textBlack)' }}>
        <AnimatedMenuChevron isOpen={isOpen} />
      </button>
    </Flex>
  )
}

export default UsersSelectInput
