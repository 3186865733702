import { useState, useEffect } from 'react'
import { formatTimeString, formatForTime, formatBytes } from 'helpers/utils'
import { getResizedImage } from 'helpers/storage'
import { useAppSlice } from 'features/redux'
import { Node, User, UserGroup } from 'types/graphqlSchema'
import { useQuery } from '@apollo/client'
import { GET_NODE_SHARED_MEMBERS, GET_NODE_SHARED_GROUPS } from '../node.schema'

export type SharedMemberWithUrl = User & { url: string }

export default function useNodeDetails(node?: Node | null) {
  const { user } = useAppSlice()
  const [type, setType] = useState<string | undefined | null>(null)

  // node members
  const [id, setId] = useState<string>('')
  const [itemName, setItemName] = useState<string>('')
  const [ownerName, setOwnerName] = useState<string>('')
  const [ownerUrl, setOwnerUrl] = useState<string>('')
  const [members, setMembers] = useState<SharedMemberWithUrl[]>([])
  const [sharedGroups, setSharedGroups] = useState<UserGroup[]>([])

  // node details
  const [itemCount, setItemCount] = useState<number>(0)
  const [totalSize, setTotalSize] = useState<string>('')
  const [lastModifiedBy, setLastModifiedBy] = useState<string>('')
  const [createdDate, setCreatedDate] = useState<string>('')
  const [updatedDate, setUpdatedDate] = useState<string>('')

  const { data: nsmQueryData, refetch: refetchNodeMembers } = useQuery(GET_NODE_SHARED_MEMBERS, {
    skip: !id,
    variables: { id },
  })

  const { data: nsgQueryData, refetch: refetchNodeGroups } = useQuery(GET_NODE_SHARED_GROUPS, {
    skip: !id,
    variables: { id },
  })

  useEffect(() => {
    if (node?.sharedMembers?.length !== members.length) refetchNodeMembers()
  }, [node?.sharedMembers?.length, members.length])

  useEffect(() => {
    if (!node || !node?.type) return setType(null)
    const {
      id: nodeId,
      name,
      owner,
      createdAt,
      updatedAt,
      totalSize: size,
      updatedBy,
      children,
      sharedGroups: nodeSharedGroups,
    } = node

    // set type
    if (node.type) setType(node.type)

    if (nodeSharedGroups && Array.isArray(nodeSharedGroups)) {
      setSharedGroups(nodeSharedGroups as UserGroup[])
    }

    // node id
    setId(nodeId)

    // node name
    setItemName(name)

    // owner name
    setOwnerName(`${owner?.firstName} ${owner?.lastName}`)

    // owner url
    const getUrl = (user: User | undefined | null) =>
      user?.avatarSecureUrl ? getResizedImage(user.avatarSecureUrl, 'profile.main') : ''
    setOwnerUrl(getUrl(owner?.id === user?.id ? user : owner) ?? '')

    // shared members
    const getAllUrls = async () => {
      if (!Array.isArray(nsmQueryData?.nodeSharedMembers)) return
      const sharedMembersArray = [...nsmQueryData.nodeSharedMembers]
        // Bug: why is the owner also a shared member?
        // https://tipbox.atlassian.net/browse/TA-1357
        .filter((sm) => sm.id !== owner?.id)
        .reduce<SharedMemberWithUrl[]>((sum, sharedMember) => {
          if (!sharedMember) return sum
          return [
            ...sum,
            {
              ...sharedMember,
              url: getUrl(sharedMember),
            },
          ]
        }, [])
      setMembers(sharedMembersArray)
    }
    getAllUrls()
    const getGroups = async () => {
      if (!Array.isArray(nsgQueryData?.nodeSharedGroups)) return
      const sharedGroupsArray = [...nsgQueryData.nodeSharedGroups]
      setSharedGroups(sharedGroupsArray)
    }
    getGroups()

    // item count
    setItemCount(children ? children.length : 0)

    // total size
    if (size) {
      setTotalSize(formatBytes(size, 2))
    } else {
      let totalSize = 0
      if (Array.isArray(children)) {
        children.forEach((child) => {
          if (child?.totalSize as number) totalSize += child?.totalSize || 0
        })
      }
      setTotalSize(formatBytes(totalSize, 2))
    }

    // last modified by
    const modifiedBy = updatedBy
      ? `${updatedBy?.firstName} ${updatedBy?.lastName}`
      : `${owner?.firstName} ${owner?.lastName}`
    setLastModifiedBy(modifiedBy)

    // created
    setCreatedDate(`${formatTimeString(createdAt)} ${formatForTime(createdAt)}`)

    // updated
    setUpdatedDate(`${formatTimeString(updatedAt)} at ${formatForTime(updatedAt)}`)
  }, [node, user, nsmQueryData, nsgQueryData, node?.sharedMembers?.length, node?.sharedGroups?.length])

  return {
    id,
    type,
    itemName,
    ownerName,
    ownerUrl,
    members,
    sharedGroups,
    totalSize,
    itemCount,
    lastModifiedBy,
    createdDate,
    updatedDate,
    updatedAt: node?.updatedAt,
    refetchNodeMembers,
    refetchNodeGroups,
  }
}
