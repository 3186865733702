import { Flex } from '@chakra-ui/react'
import { Node } from 'types/graphqlSchema'
import { TaskList } from 'components/elements/TaskList'

interface InfoPanelProps {
  node?: Node
}

const InfoPanel = ({ node }: InfoPanelProps) => {
  return (
    <Flex
      direction="column"
      flex={1}
      rounded={6}
      align="center"
      border="1px solid var(--chakra-colors-borderRegular)"
      bg="menuBackground"
      overflow="hidden"
      gap="4rem"
      aspectRatio={'16/9'}
    >
      <TaskList currentProject={node} />
    </Flex>
  )
}

export default InfoPanel
