import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type Block = {
  __typename?: 'Block';
  blockRevisions?: Maybe<Array<Maybe<BlockRevision>>>;
  content?: Maybe<Scalars['String']>;
  contentSecureUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<Scalars['String']>;
  flow?: Maybe<Flow>;
  flowId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  node?: Maybe<Node>;
  nodeId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  owner?: Maybe<User>;
  ownerId: Scalars['ID'];
  preview?: Maybe<Scalars['String']>;
  previewSecureUrl?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  type: BlockType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlockCreateInput = {
  content: Scalars['String'];
  extension?: InputMaybe<Scalars['String']>;
  flowId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  nodeId?: InputMaybe<Scalars['ID']>;
  order: Scalars['Int'];
  preview?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  type: BlockType;
};

export type BlockModifiedSub = {
  __typename?: 'BlockModifiedSub';
  action?: Maybe<Scalars['String']>;
  block?: Maybe<Block>;
};

export type BlockRevision = {
  __typename?: 'BlockRevision';
  block?: Maybe<Block>;
  blockId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  revisionNumber?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
  values?: Maybe<Scalars['JSON']>;
};

export enum BlockType {
  Audio = 'AUDIO',
  Embed = 'EMBED',
  File = 'FILE',
  Image = 'IMAGE',
  Text = 'TEXT',
  Video = 'VIDEO'
}

export type BlockUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  flowId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  nodeId?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<BlockType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ConditionObj = {
  category?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Defaults to INVITE_ONLY if not provided */
  privacySetting?: InputMaybe<PrivacySetting>;
  settings?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['String']>;
};

export enum EntityType {
  Block = 'BLOCK',
  Flow = 'FLOW',
  Message = 'MESSAGE',
  Node = 'NODE'
}

export enum ErrorCode {
  InvalidNewPassword = 'INVALID_NEW_PASSWORD',
  InvalidPassword = 'INVALID_PASSWORD',
  OrderNotFound = 'ORDER_NOT_FOUND',
  ServerError = 'SERVER_ERROR'
}

export enum EventType {
  EmailVerification = 'EMAIL_VERIFICATION',
  FeedbackFlowSubmitted = 'FEEDBACK_FLOW_SUBMITTED',
  FlowActivity = 'FLOW_ACTIVITY',
  FlowApproved = 'FLOW_APPROVED',
  FlowArchived = 'FLOW_ARCHIVED',
  FlowCancelled = 'FLOW_CANCELLED',
  FlowIntervalReminderAssignee = 'FLOW_INTERVAL_REMINDER_ASSIGNEE',
  FlowIntervalReminderCreator = 'FLOW_INTERVAL_REMINDER_CREATOR',
  FlowRejected = 'FLOW_REJECTED',
  FlowSent = 'FLOW_SENT',
  FlowUnresolved = 'FLOW_UNRESOLVED',
  FlowUserMentioned = 'FLOW_USER_MENTIONED',
  FlowUserRemoved = 'FLOW_USER_REMOVED',
  InTheLoop = 'IN_THE_LOOP',
  ResourceFollowed = 'RESOURCE_FOLLOWED',
  ResourceShared = 'RESOURCE_SHARED',
  SlackVerification = 'SLACK_VERIFICATION',
  SmsVerification = 'SMS_VERIFICATION',
  UserMentioned = 'USER_MENTIONED'
}

export type ExecuteTransitionInput = {
  flowId: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
  transitionLabel: Scalars['String'];
};

export type FilterObj = {
  conditions?: InputMaybe<ConditionObj>;
  data?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Flow = {
  __typename?: 'Flow';
  blocks?: Maybe<Array<Maybe<Block>>>;
  children?: Maybe<Array<Maybe<Flow>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  flowLogs?: Maybe<Array<Maybe<FlowLog>>>;
  flowState?: Maybe<FlowState>;
  id?: Maybe<Scalars['ID']>;
  messages?: Maybe<Message>;
  metadata?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  node?: Maybe<Node>;
  nodeId?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  owner?: Maybe<User>;
  ownerId: Scalars['ID'];
  parent?: Maybe<Flow>;
  parentId?: Maybe<Scalars['ID']>;
  participants?: Maybe<Array<Maybe<User>>>;
  project?: Maybe<Node>;
  projectId?: Maybe<Scalars['ID']>;
  requester?: Maybe<User>;
  requesterId: Scalars['ID'];
  requesterMessage?: Maybe<Scalars['String']>;
  sharedMembers?: Maybe<Array<Maybe<SharedMember>>>;
  state?: Maybe<WorkflowState>;
  stateId?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  totalSize?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadNeed?: Maybe<Scalars['Boolean']>;
  urgent: Scalars['Boolean'];
  watchers?: Maybe<Array<Maybe<User>>>;
  workflow?: Maybe<Workflow>;
  workflowId?: Maybe<Scalars['ID']>;
};

export type FlowCreatedInput = {
  flowState?: InputMaybe<FlowState>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  nodeId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  parentId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type FlowFilterInput = {
  nodeId?: InputMaybe<Scalars['ID']>;
  participantsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  requesterId?: InputMaybe<Scalars['ID']>;
  stateId?: InputMaybe<Scalars['ID']>;
  watchersIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  workflowId?: InputMaybe<Scalars['ID']>;
};

export type FlowLog = {
  __typename?: 'FlowLog';
  createdAt?: Maybe<Scalars['DateTime']>;
  flow?: Maybe<Flow>;
  flowId?: Maybe<Scalars['ID']>;
  fromState?: Maybe<WorkflowState>;
  fromStateId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  toState?: Maybe<WorkflowState>;
  toStateId?: Maybe<Scalars['ID']>;
  transition?: Maybe<WorkflowTransition>;
  transitionId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type FlowLogOutput = {
  __typename?: 'FlowLogOutput';
  error?: Maybe<Scalars['String']>;
  flowLog?: Maybe<FlowLog>;
};

export type FlowOutput = {
  __typename?: 'FlowOutput';
  error?: Maybe<Scalars['String']>;
  flow?: Maybe<Flow>;
};

export enum FlowState {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

export type FlowUpdateInput = {
  flowState?: InputMaybe<FlowState>;
  id: Scalars['ID'];
  metadata?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  totalSize?: InputMaybe<Scalars['Int']>;
};

export type FollowedNode = {
  __typename?: 'FollowedNode';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  node?: Maybe<Node>;
  nodeId: Scalars['ID'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type Message = {
  __typename?: 'Message';
  createdAt?: Maybe<Scalars['DateTime']>;
  flow?: Maybe<Flow>;
  flowId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  mentions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  message?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  node?: Maybe<Node>;
  nodeId?: Maybe<Scalars['ID']>;
  reactions?: Maybe<Array<Maybe<Reaction>>>;
  receiver?: Maybe<User>;
  receiverId?: Maybe<Scalars['ID']>;
  sender?: Maybe<User>;
  senderId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MessageAddInput = {
  flowId?: InputMaybe<Scalars['ID']>;
  mentions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  message?: InputMaybe<Scalars['String']>;
  messageType?: InputMaybe<MessageType>;
  nodeId?: InputMaybe<Scalars['ID']>;
  receiverId?: InputMaybe<Scalars['ID']>;
};

export type MessageEditInput = {
  mentions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  message: Scalars['String'];
  messageId: Scalars['ID'];
  messageType?: InputMaybe<MessageType>;
  receiverId?: InputMaybe<Scalars['ID']>;
};

export enum MessageType {
  Comment = 'COMMENT',
  Flow = 'FLOW',
  Note = 'NOTE',
  Share = 'SHARE'
}

export type Mutation = {
  __typename?: 'Mutation';
  addComment?: Maybe<Message>;
  addMessage?: Maybe<Message>;
  addSavedSearch: SavedSearch;
  addTrackingEvents: Array<Maybe<TrackingEvent>>;
  addUserToGroup?: Maybe<Scalars['Boolean']>;
  addWorkflow?: Maybe<Workflow>;
  addWorkflowAction?: Maybe<WorkflowAction>;
  addWorkflowState: WorkflowState;
  addWorkflowTransition?: Maybe<WorkflowTransition>;
  archiveFlow?: Maybe<FlowLogOutput>;
  associateTagWithEntity?: Maybe<Scalars['Boolean']>;
  cancelFlow?: Maybe<FlowLogOutput>;
  cancelFlowRemindersFromReceiver: Scalars['Int'];
  createBlock?: Maybe<Block>;
  createFlow?: Maybe<FlowOutput>;
  createNode?: Maybe<Node>;
  createNodeFromFlow?: Maybe<NodeOutput>;
  createOrganization?: Maybe<Organization>;
  createTag?: Maybe<Tag>;
  createTemplate?: Maybe<Template>;
  createUser?: Maybe<User>;
  createUserGroup?: Maybe<UserGroup>;
  deleteBlock?: Maybe<Block>;
  deleteBlocksFromNode?: Maybe<Scalars['ID']>;
  deleteFlow?: Maybe<FlowOutput>;
  deleteMe?: Maybe<Scalars['Boolean']>;
  deleteNode?: Maybe<Scalars['ID']>;
  deleteSavedSearch?: Maybe<Scalars['Boolean']>;
  deleteWorkflow?: Maybe<Scalars['Boolean']>;
  deleteWorkflowAction?: Maybe<Scalars['Boolean']>;
  deleteWorkflowState: Scalars['Boolean'];
  deleteWorkflowTransition: Scalars['Boolean'];
  duplicateNode?: Maybe<Node>;
  editComment?: Maybe<Message>;
  executeTransition?: Maybe<FlowLogOutput>;
  followNode?: Maybe<FollowedNode>;
  /** Allow users to remove themselves from shared nodes. */
  leaveSharedNode?: Maybe<Node>;
  moveNode?: Maybe<Node>;
  permanentlyDeleteNode?: Maybe<Scalars['ID']>;
  permanentlyDeleteNodes?: Maybe<Scalars['Int']>;
  processReminders: Scalars['Int'];
  removeComment?: Maybe<Message>;
  /** Remove a group from a node. Only the node owner can perform this action. */
  removeGroupFromNode?: Maybe<Node>;
  removeMeFromFlow?: Maybe<FlowLogOutput>;
  removeTagFromEntity?: Maybe<Scalars['Boolean']>;
  removeUserFromFlow?: Maybe<FlowLogOutput>;
  removeUserFromGroup?: Maybe<Scalars['Boolean']>;
  removeUserGroup?: Maybe<Scalars['Boolean']>;
  restoreAllNodes?: Maybe<Scalars['Int']>;
  restoreNode?: Maybe<Scalars['ID']>;
  setFlowIntervalReminders: Scalars['Int'];
  shareNode?: Maybe<Node>;
  startWorkflow?: Maybe<FlowLogOutput>;
  toggleReactionType?: Maybe<ReactionOutput>;
  unfollowNode?: Maybe<FollowedNode>;
  /** Unshare a node from a specific user. Only the node owner can perform this action. */
  unshareNode?: Maybe<Node>;
  updateBlock?: Maybe<Block>;
  updateFlow?: Maybe<FlowOutput>;
  updateMe?: Maybe<User>;
  updateMyNotificationPreference?: Maybe<NotificationPreference>;
  updateNode?: Maybe<Node>;
  updateNotificationEmail?: Maybe<Scalars['Boolean']>;
  updateOrganization?: Maybe<Organization>;
  updatePhoneNumber?: Maybe<Scalars['Boolean']>;
  updateSavedSearch: SavedSearch;
  updateSlackId?: Maybe<Scalars['Boolean']>;
  updateTemplate?: Maybe<Template>;
  updateTrackingEvent: TrackingEventOutput;
  updateUser?: Maybe<User>;
  updateUserGroup?: Maybe<UserGroup>;
  updateUserRole?: Maybe<User>;
  updateUserTrackingEvents?: Maybe<Scalars['Int']>;
  updateWorkflow?: Maybe<Workflow>;
  updateWorkflowAction?: Maybe<WorkflowAction>;
  updateWorkflowState?: Maybe<WorkflowState>;
  updateWorkflowTransition?: Maybe<WorkflowTransition>;
  uploadNodes?: Maybe<UploadNodeOutput>;
  verifyNotificationEmail?: Maybe<Scalars['Boolean']>;
  verifyPhoneNumber?: Maybe<Scalars['Boolean']>;
  verifySlackId?: Maybe<Scalars['Boolean']>;
};


export type MutationAddCommentArgs = {
  input: MessageAddInput;
};


export type MutationAddMessageArgs = {
  input: MessageAddInput;
};


export type MutationAddSavedSearchArgs = {
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  searchParameters: Scalars['JSON'];
};


export type MutationAddTrackingEventsArgs = {
  events: Array<InputMaybe<TrackingEventAddInput>>;
};


export type MutationAddUserToGroupArgs = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddWorkflowArgs = {
  input?: InputMaybe<WorkflowInput>;
};


export type MutationAddWorkflowActionArgs = {
  input?: InputMaybe<WorkflowActionInput>;
};


export type MutationAddWorkflowStateArgs = {
  input: WorkflowStateInput;
};


export type MutationAddWorkflowTransitionArgs = {
  input?: InputMaybe<WorkflowTransitionInput>;
};


export type MutationArchiveFlowArgs = {
  flowId: Scalars['ID'];
};


export type MutationAssociateTagWithEntityArgs = {
  entityId: Scalars['String'];
  entityType: EntityType;
  tagId: Scalars['String'];
};


export type MutationCancelFlowArgs = {
  flowId: Scalars['ID'];
};


export type MutationCancelFlowRemindersFromReceiverArgs = {
  flowId: Scalars['ID'];
};


export type MutationCreateBlockArgs = {
  input: BlockCreateInput;
};


export type MutationCreateFlowArgs = {
  input?: InputMaybe<FlowCreatedInput>;
};


export type MutationCreateNodeArgs = {
  input?: InputMaybe<NodeCreateInput>;
};


export type MutationCreateNodeFromFlowArgs = {
  flowId: Scalars['ID'];
};


export type MutationCreateOrganizationArgs = {
  input?: InputMaybe<CreateOrganizationInput>;
};


export type MutationCreateTagArgs = {
  name: Scalars['String'];
};


export type MutationCreateTemplateArgs = {
  input?: InputMaybe<TemplateCreateInput>;
};


export type MutationCreateUserArgs = {
  input?: InputMaybe<UserCreateInput>;
};


export type MutationCreateUserGroupArgs = {
  groupType: Scalars['String'];
  name: Scalars['String'];
  userIds: Array<Scalars['String']>;
};


export type MutationDeleteBlockArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBlocksFromNodeArgs = {
  nodeId: Scalars['ID'];
};


export type MutationDeleteFlowArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNodeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSavedSearchArgs = {
  id: Scalars['String'];
};


export type MutationDeleteWorkflowArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkflowActionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkflowStateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkflowTransitionArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateNodeArgs = {
  id: Scalars['ID'];
};


export type MutationEditCommentArgs = {
  input: MessageEditInput;
};


export type MutationExecuteTransitionArgs = {
  input?: InputMaybe<ExecuteTransitionInput>;
};


export type MutationFollowNodeArgs = {
  nodeId: Scalars['ID'];
};


export type MutationLeaveSharedNodeArgs = {
  nodeId: Scalars['ID'];
};


export type MutationMoveNodeArgs = {
  destinationId: Scalars['ID'];
  resourceId: Scalars['ID'];
};


export type MutationPermanentlyDeleteNodeArgs = {
  id: Scalars['ID'];
};


export type MutationPermanentlyDeleteNodesArgs = {
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationRemoveCommentArgs = {
  messageId: Scalars['ID'];
};


export type MutationRemoveGroupFromNodeArgs = {
  groupId: Scalars['String'];
  nodeId: Scalars['ID'];
};


export type MutationRemoveMeFromFlowArgs = {
  flowId: Scalars['ID'];
};


export type MutationRemoveTagFromEntityArgs = {
  entityId: Scalars['String'];
  entityType: EntityType;
  tagId: Scalars['String'];
};


export type MutationRemoveUserFromFlowArgs = {
  flowId: Scalars['ID'];
  userId: Scalars['String'];
};


export type MutationRemoveUserFromGroupArgs = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveUserGroupArgs = {
  groupId: Scalars['String'];
};


export type MutationRestoreNodeArgs = {
  id: Scalars['ID'];
};


export type MutationSetFlowIntervalRemindersArgs = {
  flowId: Scalars['ID'];
  frequency: Scalars['Int'];
};


export type MutationShareNodeArgs = {
  input?: InputMaybe<ShareNodeInput>;
};


export type MutationStartWorkflowArgs = {
  input?: InputMaybe<StartWorkflowInput>;
};


export type MutationToggleReactionTypeArgs = {
  input: ReactionInput;
};


export type MutationUnfollowNodeArgs = {
  nodeId: Scalars['ID'];
};


export type MutationUnshareNodeArgs = {
  input: UpdateShareNodeInput;
};


export type MutationUpdateBlockArgs = {
  input: BlockUpdateInput;
};


export type MutationUpdateFlowArgs = {
  input?: InputMaybe<FlowUpdateInput>;
};


export type MutationUpdateMeArgs = {
  input?: InputMaybe<UserUpdateInput>;
};


export type MutationUpdateMyNotificationPreferenceArgs = {
  input?: InputMaybe<NotificationPreferenceInput>;
};


export type MutationUpdateNodeArgs = {
  input?: InputMaybe<NodeUpdateInput>;
};


export type MutationUpdateNotificationEmailArgs = {
  notificationEmail: Scalars['String'];
};


export type MutationUpdateOrganizationArgs = {
  input?: InputMaybe<UpdateOrganizationInput>;
};


export type MutationUpdatePhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationUpdateSavedSearchArgs = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  searchParameters?: InputMaybe<Scalars['JSON']>;
};


export type MutationUpdateSlackIdArgs = {
  slackId: Scalars['String'];
};


export type MutationUpdateTemplateArgs = {
  input?: InputMaybe<TemplateCreateInput>;
};


export type MutationUpdateTrackingEventArgs = {
  input: TrackingEventUpdateInput;
};


export type MutationUpdateUserArgs = {
  input?: InputMaybe<UserUpdateInput>;
};


export type MutationUpdateUserGroupArgs = {
  groupId: Scalars['String'];
  groupType: Scalars['String'];
  name: Scalars['String'];
  userIds: Array<Scalars['String']>;
};


export type MutationUpdateUserRoleArgs = {
  role: Scalars['String'];
  userId: Scalars['String'];
  userOrgId: Scalars['String'];
};


export type MutationUpdateUserTrackingEventsArgs = {
  input: UserTrackingEventsInput;
};


export type MutationUpdateWorkflowArgs = {
  input?: InputMaybe<WorkflowUpdateInput>;
};


export type MutationUpdateWorkflowActionArgs = {
  input?: InputMaybe<WorkflowActionInput>;
};


export type MutationUpdateWorkflowStateArgs = {
  input?: InputMaybe<WorkflowStateInput>;
};


export type MutationUpdateWorkflowTransitionArgs = {
  input?: InputMaybe<WorkflowTransitionInput>;
};


export type MutationUploadNodesArgs = {
  files?: InputMaybe<Array<InputMaybe<UploadNodeInput>>>;
  parentId?: InputMaybe<Scalars['ID']>;
};


export type MutationVerifyNotificationEmailArgs = {
  verificationCode: Scalars['String'];
};


export type MutationVerifyPhoneNumberArgs = {
  verificationCode: Scalars['String'];
};


export type MutationVerifySlackIdArgs = {
  verificationCode: Scalars['String'];
};

export type Node = {
  __typename?: 'Node';
  absolutePaths?: Maybe<Array<Maybe<Scalars['ID']>>>;
  blocks?: Maybe<Array<Maybe<Block>>>;
  breadcrumbs?: Maybe<Array<Maybe<Node>>>;
  category?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Node>>>;
  childrenIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  color?: Maybe<NodeColor>;
  coverImage?: Maybe<Scalars['String']>;
  coverImageSecureUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  flows?: Maybe<Array<Maybe<Flow>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nodeRevisions?: Maybe<Array<Maybe<NodeRevision>>>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  owner?: Maybe<User>;
  ownerId: Scalars['ID'];
  parent?: Maybe<Node>;
  parentId?: Maybe<Scalars['ID']>;
  private?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Node>;
  projectId?: Maybe<Scalars['ID']>;
  projectLinks?: Maybe<Array<Maybe<ProjectLink>>>;
  sharedGroups?: Maybe<Array<Maybe<UserGroup>>>;
  sharedMembers?: Maybe<Array<Maybe<SharedMember>>>;
  state: NodeState;
  tags?: Maybe<Array<Maybe<Tag>>>;
  totalSize?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
};

export enum NodeColor {
  Blue = 'BLUE',
  DarkGray = 'DARK_GRAY',
  Green = 'GREEN',
  LightGray = 'LIGHT_GRAY',
  Orange = 'ORANGE',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export type NodeCreateInput = {
  category?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<NodeColor>;
  coverImage?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  ownerPermissionId?: InputMaybe<Scalars['ID']>;
  parentId?: InputMaybe<Scalars['ID']>;
  private?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['ID']>;
  state: NodeState;
  templateId?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
  updatedById?: InputMaybe<Scalars['ID']>;
};

export type NodeError = {
  __typename?: 'NodeError';
  code?: Maybe<NodeErrorCode>;
};

export enum NodeErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  NodeNotFound = 'NODE_NOT_FOUND',
  StateIsNotSet = 'STATE_IS_NOT_SET',
  TypeIsNotSet = 'TYPE_IS_NOT_SET'
}

export type NodeOutput = {
  __typename?: 'NodeOutput';
  error?: Maybe<Scalars['String']>;
  node?: Maybe<Node>;
};

export type NodeRevision = {
  __typename?: 'NodeRevision';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  node?: Maybe<Node>;
  nodeId: Scalars['ID'];
  revisionNumber?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  updatedById?: Maybe<Scalars['ID']>;
  values?: Maybe<Scalars['JSON']>;
};

export enum NodeSortBy {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum NodeState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export enum NodeType {
  Box = 'BOX',
  Project = 'PROJECT',
  Tip = 'TIP'
}

export type NodeUpdateInput = {
  category?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<NodeColor>;
  coverImage?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  ownerPermissionId?: InputMaybe<Scalars['ID']>;
  parentId?: InputMaybe<Scalars['ID']>;
  private?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['ID']>;
  projectLinks?: InputMaybe<Array<InputMaybe<ProjectLinkInput>>>;
  state?: InputMaybe<NodeState>;
  type?: InputMaybe<Scalars['String']>;
  updatedById?: InputMaybe<Scalars['ID']>;
};

export type NodesResult = {
  __typename?: 'NodesResult';
  count?: Maybe<Scalars['Int']>;
  nodes: Array<Maybe<Node>>;
};

export enum NotificationChannel {
  Email = 'EMAIL',
  MsTeams = 'MS_TEAMS',
  Push = 'PUSH',
  Slack = 'SLACK',
  Sms = 'SMS'
}

export type NotificationOutbox = {
  __typename?: 'NotificationOutbox';
  channel: NotificationChannel;
  eventType: EventType;
  fromUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  payload: Scalars['JSON'];
  processed?: Maybe<Scalars['Boolean']>;
  toUserId: Scalars['ID'];
  user: User;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  channels?: Maybe<Array<Maybe<NotificationChannel>>>;
  eventType: EventType;
  id: Scalars['ID'];
  user: User;
  userId: Scalars['ID'];
};

export type NotificationPreferenceInput = {
  channels?: InputMaybe<Array<InputMaybe<NotificationChannel>>>;
  eventType: EventType;
};

export type NotificationTemplate = {
  __typename?: 'NotificationTemplate';
  HTMLMessage?: Maybe<Scalars['String']>;
  eventType: EventType;
  id: Scalars['ID'];
  organization: Organization;
  organizationId: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  textMessage?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  logoSecureUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nodes?: Maybe<Array<Maybe<Node>>>;
  privacySetting?: Maybe<PrivacySetting>;
  settings?: Maybe<Scalars['JSON']>;
  subdomain?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<Maybe<Template>>>;
  theme?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<Array<Maybe<UserGroup>>>;
  users?: Maybe<Array<Maybe<User>>>;
  workflows?: Maybe<Array<Maybe<Workflow>>>;
};

export type Permission = {
  __typename?: 'Permission';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<SpecificPermission>>>;
};

export type PermissionError = {
  __typename?: 'PermissionError';
  code?: Maybe<PermissionError>;
};

export type PermissionOutput = {
  __typename?: 'PermissionOutput';
  error?: Maybe<PermissionError>;
  permission?: Maybe<Permission>;
};

/** Defines the privacy settings for an organization's content */
export enum PrivacySetting {
  InviteOnly = 'INVITE_ONLY',
  OrganizationWide = 'ORGANIZATION_WIDE'
}

export enum ProjectErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  ProjectNotFound = 'PROJECT_NOT_FOUND',
  ProjectStateIsNotSet = 'PROJECT_STATE_IS_NOT_SET',
  TypeIsNotSet = 'TYPE_IS_NOT_SET'
}

export type ProjectLink = {
  __typename?: 'ProjectLink';
  content: Scalars['String'];
  id: Scalars['ID'];
  nodeId: Scalars['String'];
  order: Scalars['Int'];
};

export type ProjectLinkInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
};

export enum ProjectSortBy {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ProjectCategoryAsc = 'PROJECT_CATEGORY_ASC',
  ProjectCategoryDesc = 'PROJECT_CATEGORY_DESC',
  ProjectStateAsc = 'PROJECT_STATE_ASC',
  ProjectStateDesc = 'PROJECT_STATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum ProjectType {
  Organizational = 'ORGANIZATIONAL',
  Personal = 'PERSONAL'
}

export type Query = {
  __typename?: 'Query';
  blocks: Array<Block>;
  comments: Array<Message>;
  flowBlocks: Array<Block>;
  flowById?: Maybe<FlowOutput>;
  flows?: Maybe<Array<Maybe<Flow>>>;
  followedNodes: Array<FollowedNode>;
  getFlowReminders: Array<Reminder>;
  getRecentViewedProjects: Array<Maybe<Node>>;
  getSavedSearches: Array<SavedSearch>;
  getSuggestions: SuggestionResult;
  getTrackingEvents: Array<Maybe<TrackingEvent>>;
  getUserFlowsEvents: Array<Maybe<TrackingEvent>>;
  getUserFollowingEvents: Array<Maybe<TrackingEvent>>;
  getUserMentionsEvents: Array<Maybe<TrackingEvent>>;
  getUserOtherEvents: Array<Maybe<TrackingEvent>>;
  me?: Maybe<User>;
  myActiveProjects: Array<Node>;
  myNodes: Array<Node>;
  myNotificationPreferences: Array<NotificationPreference>;
  mySharedNodeOwners: Array<User>;
  node?: Maybe<Node>;
  nodeSharedMembers?: Maybe<Array<SharedUser>>;
  nodes: Array<Node>;
  organization?: Maybe<Organization>;
  recentNodes: Array<Node>;
  search: SearchResults;
  shareSuggestionsUsers: Array<User>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  template?: Maybe<Template>;
  templates: Array<Template>;
  trashNodes: Array<Node>;
  user?: Maybe<User>;
  userGroup?: Maybe<UserGroup>;
  users: Array<User>;
  workflow?: Maybe<Workflow>;
  workflowAction?: Maybe<WorkflowAction>;
  workflowActions: Array<Workflow>;
  workflowState?: Maybe<WorkflowState>;
  workflowStates: Array<WorkflowState>;
  workflowTransition?: Maybe<WorkflowTransition>;
  workflowTransitions: Array<WorkflowTransition>;
  workflows: Array<Workflow>;
};


export type QueryBlocksArgs = {
  nodeId: Scalars['ID'];
};


export type QueryCommentsArgs = {
  flowId?: InputMaybe<Scalars['ID']>;
  nodeId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryFlowBlocksArgs = {
  flowId: Scalars['ID'];
};


export type QueryFlowByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFlowsArgs = {
  filter?: InputMaybe<FlowFilterInput>;
};


export type QueryGetFlowRemindersArgs = {
  flowId: Scalars['ID'];
};


export type QueryGetRecentViewedProjectsArgs = {
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryGetSuggestionsArgs = {
  indexes: Array<SearchIndex>;
  term: Scalars['String'];
};


export type QueryGetTrackingEventsArgs = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<EntityType>;
  eventType?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserMentionsEventsArgs = {
  isRead?: InputMaybe<Scalars['Boolean']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodeSharedMembersArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  filter?: InputMaybe<FilterObj>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryRecentNodesArgs = {
  count?: InputMaybe<Scalars['Int']>;
  nodeType?: InputMaybe<Scalars['String']>;
};


export type QuerySearchArgs = {
  filter?: InputMaybe<Scalars['JSON']>;
  indexes: Array<SearchIndex>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id: Scalars['String'];
};


export type QueryTemplateArgs = {
  id: Scalars['String'];
};


export type QueryTrashNodesArgs = {
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserGroupArgs = {
  groupId: Scalars['String'];
};


export type QueryWorkflowArgs = {
  id: Scalars['ID'];
};


export type QueryWorkflowActionArgs = {
  id: Scalars['ID'];
};


export type QueryWorkflowActionsArgs = {
  filter?: InputMaybe<WorkflowActionInput>;
};


export type QueryWorkflowStateArgs = {
  id: Scalars['ID'];
};


export type QueryWorkflowStatesArgs = {
  workflowId: Scalars['ID'];
};


export type QueryWorkflowTransitionArgs = {
  id: Scalars['ID'];
};


export type QueryWorkflowTransitionsArgs = {
  workflowId: Scalars['ID'];
};


export type QueryWorkflowsArgs = {
  filter: WorkflowInput;
};

export type Reaction = {
  __typename?: 'Reaction';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  message?: Maybe<Message>;
  messageId: Scalars['ID'];
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type ReactionInput = {
  messageId: Scalars['ID'];
  type: Scalars['String'];
};

export type ReactionOutput = {
  __typename?: 'ReactionOutput';
  action?: Maybe<Scalars['String']>;
  reaction?: Maybe<Reaction>;
};

export type Reminder = {
  __typename?: 'Reminder';
  createdAt?: Maybe<Scalars['DateTime']>;
  dateToSend?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['JSON']>;
  receiver?: Maybe<User>;
  receiverId?: Maybe<Scalars['ID']>;
  sender?: Maybe<User>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export type SavedSearch = {
  __typename?: 'SavedSearch';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  searchParameters?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** The indices available for searching. */
export enum SearchIndex {
  Blocks = 'BLOCKS',
  Flows = 'FLOWS',
  Messages = 'MESSAGES',
  Nodes = 'NODES',
  Tags = 'TAGS',
  Users = 'USERS'
}

export type SearchResults = {
  __typename?: 'SearchResults';
  /** Returns the Blocks that match the search. */
  blocks?: Maybe<Array<Maybe<Block>>>;
  /** Returns the Flows that match the search. */
  flows?: Maybe<Array<Maybe<Flow>>>;
  /** Returns the Messages that match the search. */
  messages?: Maybe<Array<Maybe<Message>>>;
  /** Returns the Nodes that match the search. */
  nodes?: Maybe<Array<Maybe<Node>>>;
  /** Returns Tags that match the search. */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** Returns the Users that match the search. */
  users?: Maybe<Array<Maybe<User>>>;
};

export type ShareNodeInput = {
  message?: InputMaybe<Scalars['String']>;
  nodeId: Scalars['ID'];
  sharedGroupIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ShareType {
  Coowner = 'COOWNER',
  Editor = 'EDITOR',
  Linkdownload = 'LINKDOWNLOAD',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
  Vieweruploader = 'VIEWERUPLOADER'
}

export type SharedMember = {
  __typename?: 'SharedMember';
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  entityType: EntityType;
  id: Scalars['ID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  permission?: Maybe<Permission>;
  permissionId: Scalars['ID'];
  sharedBy?: Maybe<User>;
  sharedById?: Maybe<Scalars['ID']>;
  sharedWith?: Maybe<User>;
  sharedWithId?: Maybe<Scalars['ID']>;
};

export type SharedUser = {
  __typename?: 'SharedUser';
  avatar?: Maybe<Scalars['String']>;
  avatarSecureUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organizationId: Scalars['String'];
  userGroups?: Maybe<Array<Maybe<UserGroup>>>;
};

export type SpecificPermission = {
  __typename?: 'SpecificPermission';
  allowed: Scalars['Boolean'];
  entityType: EntityType;
  permissionName: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
};

export type StartWorkflowInput = {
  dueDate?: InputMaybe<Scalars['DateTime']>;
  flowId: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
  participantsEmails: Array<InputMaybe<Scalars['String']>>;
  projectId?: InputMaybe<Scalars['ID']>;
  remindersFrequency?: InputMaybe<Scalars['Int']>;
  requesterMessage?: InputMaybe<Scalars['String']>;
  uploadNeed?: InputMaybe<Scalars['Boolean']>;
  urgent?: InputMaybe<Scalars['Boolean']>;
  watchersEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  workflowId: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  commentModified?: Maybe<Message>;
  flowUpdated?: Maybe<FlowLogOutput>;
  flowsUpdated?: Maybe<FlowLogOutput>;
  updatesWidgetsUpdated?: Maybe<TrackingEvent>;
};


export type SubscriptionCommentModifiedArgs = {
  key: Scalars['String'];
};


export type SubscriptionFlowUpdatedArgs = {
  key: Scalars['String'];
};


export type SubscriptionUpdatesWidgetsUpdatedArgs = {
  key: Scalars['String'];
};

export type SuggestionOption = {
  __typename?: 'SuggestionOption';
  score?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
};

export type SuggestionResult = {
  __typename?: 'SuggestionResult';
  /** Returns the Blocks suggestions that match the term. */
  blocks?: Maybe<Array<Maybe<SuggestionOption>>>;
  /** Returns the Flows suggestions that match the term. */
  flows?: Maybe<Array<Maybe<SuggestionOption>>>;
  /** Returns the Nodes suggestions that match the term. */
  nodes?: Maybe<Array<Maybe<SuggestionOption>>>;
  /** Returns Tags suggestions that match the term. */
  tags?: Maybe<Array<Maybe<SuggestionOption>>>;
  /** Returns the Users suggestions that match the term. */
  users?: Maybe<Array<Maybe<SuggestionOption>>>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  boxes?: Maybe<Array<Maybe<TemplateBox>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  oragnization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TemplateBox = {
  __typename?: 'TemplateBox';
  children?: Maybe<Array<Maybe<TemplateBox>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  template?: Maybe<Template>;
  templateBox?: Maybe<TemplateBox>;
  templateBoxId?: Maybe<Scalars['ID']>;
  templateId?: Maybe<Scalars['ID']>;
};

export type TemplateCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TrackingEvent = {
  __typename?: 'TrackingEvent';
  createdAt?: Maybe<Scalars['DateTime']>;
  customFields?: Maybe<Scalars['JSON']>;
  entityId: Scalars['String'];
  entityType: EntityType;
  eventType: Scalars['String'];
  eventUrl?: Maybe<Scalars['String']>;
  geoIP?: Maybe<Scalars['JSON']>;
  hasSeen?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isRead?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<User>;
  receiverId?: Maybe<Scalars['ID']>;
  referralURL?: Maybe<Scalars['String']>;
  state?: Maybe<TrackingEventState>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userAgent?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type TrackingEventAddInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  entityId: Scalars['String'];
  entityType: EntityType;
  eventType: Scalars['String'];
  eventUrl?: InputMaybe<Scalars['String']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  receiverId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<TrackingEventState>;
};

export type TrackingEventOutput = {
  __typename?: 'TrackingEventOutput';
  error?: Maybe<Scalars['String']>;
  trackingEvent?: Maybe<TrackingEvent>;
};

export enum TrackingEventState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export type TrackingEventUpdateInput = {
  customFields?: InputMaybe<Scalars['JSON']>;
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<EntityType>;
  eventUrl?: InputMaybe<Scalars['String']>;
  hasSeen?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isRead?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<TrackingEventState>;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID'];
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  privacySetting?: InputMaybe<PrivacySetting>;
  settings?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['String']>;
};

export type UpdateShareNodeInput = {
  nodeId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UploadNodeInput = {
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
};

export type UploadNodeOutput = {
  __typename?: 'UploadNodeOutput';
  alias?: Maybe<Scalars['String']>;
  boxIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  node?: Maybe<Node>;
  uploadedTipInfos?: Maybe<Array<Maybe<UploadTipInfo>>>;
};

export type UploadTipInfo = {
  __typename?: 'UploadTipInfo';
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  tipId?: Maybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  avatarSecureUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  followedNodes?: Maybe<Array<Maybe<FollowedNode>>>;
  geoIP?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  lastPasswordChange?: Maybe<Scalars['DateTime']>;
  myProject?: Maybe<Node>;
  myProjectId?: Maybe<Scalars['ID']>;
  notificationEmail?: Maybe<Scalars['String']>;
  notificationEmailVerified?: Maybe<Scalars['Boolean']>;
  notificationsOutboxes?: Maybe<Array<Maybe<NotificationOutbox>>>;
  notificationsPreferences?: Maybe<Array<Maybe<NotificationPreference>>>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
  primaryUserId?: Maybe<Scalars['ID']>;
  role: Role;
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>;
  shareEmailSuggestions?: Maybe<Array<Maybe<User>>>;
  sharedMembers?: Maybe<Array<Maybe<SharedMember>>>;
  slackId?: Maybe<Scalars['String']>;
  slackIdVerified?: Maybe<Scalars['Boolean']>;
  teamsId?: Maybe<Scalars['String']>;
  teamsIdVerified?: Maybe<Scalars['Boolean']>;
  trackingEvents?: Maybe<Array<Maybe<TrackingEvent>>>;
  twoStepVerification?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userGroups?: Maybe<Array<Maybe<UserGroup>>>;
  userState?: Maybe<UserState>;
  username: Scalars['String'];
  verificationCode?: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  avatar?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  geoIP?: InputMaybe<Scalars['JSON']>;
  isInvite?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  lastPasswordChange?: InputMaybe<Scalars['DateTime']>;
  organizationName?: InputMaybe<Scalars['String']>;
  organizationSubdomain: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  primaryUserId?: InputMaybe<Scalars['ID']>;
  role: Role;
  twoStepVerification?: InputMaybe<Scalars['Boolean']>;
  userState: UserState;
  username?: InputMaybe<Scalars['String']>;
};

export enum UserErrorCode {
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  RoleIsNotSet = 'ROLE_IS_NOT_SET',
  UserIsOwner = 'USER_IS_OWNER',
  UserNotFound = 'USER_NOT_FOUND'
}

export type UserGroup = {
  __typename?: 'UserGroup';
  createdAt?: Maybe<Scalars['DateTime']>;
  groupType: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  users?: Maybe<Array<Maybe<User>>>;
};

export enum UserSortBy {
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  UserStateAsc = 'USER_STATE_ASC',
  UserStateDesc = 'USER_STATE_DESC'
}

export enum UserState {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export type UserTrackingEventsInput = {
  eventTypes: Array<InputMaybe<Scalars['String']>>;
  hasSeen?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<TrackingEventState>;
};

export type UserUpdateInput = {
  avatar?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  geoIP?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastPasswordChange?: InputMaybe<Scalars['DateTime']>;
  notificationEmail?: InputMaybe<Scalars['String']>;
  notificationEmailVerified?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['String']>;
  primaryUserId?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<Role>;
  slackId?: InputMaybe<Scalars['String']>;
  slackIdVerified?: InputMaybe<Scalars['Boolean']>;
  teamsId?: InputMaybe<Scalars['String']>;
  twoStepVerification?: InputMaybe<Scalars['Boolean']>;
  userState?: InputMaybe<UserState>;
  verificationCode?: InputMaybe<Scalars['String']>;
};

export type Workflow = {
  __typename?: 'Workflow';
  active?: Maybe<Scalars['Boolean']>;
  flows?: Maybe<Array<Maybe<Flow>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nodeTypes?: Maybe<Array<NodeType>>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  workflowStates?: Maybe<Array<Maybe<WorkflowState>>>;
  workflowTransitions?: Maybe<Array<Maybe<WorkflowTransition>>>;
};

export type WorkflowAction = {
  __typename?: 'WorkflowAction';
  functionName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  workflowTransitions?: Maybe<Array<Maybe<WorkflowTransition>>>;
};

export type WorkflowActionInput = {
  functionName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type WorkflowInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nodeTypes?: InputMaybe<Array<InputMaybe<NodeType>>>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type WorkflowState = {
  __typename?: 'WorkflowState';
  flows?: Maybe<Array<Maybe<Flow>>>;
  id: Scalars['ID'];
  stateName: Scalars['String'];
  workflow?: Maybe<Array<Maybe<Workflow>>>;
  workflowId: Scalars['ID'];
};

export type WorkflowStateInput = {
  id?: InputMaybe<Scalars['ID']>;
  stateName?: InputMaybe<Scalars['String']>;
  workflowId?: InputMaybe<Scalars['ID']>;
};

export type WorkflowTransition = {
  __typename?: 'WorkflowTransition';
  actions?: Maybe<Array<Maybe<WorkflowAction>>>;
  flowlogs?: Maybe<Array<Maybe<FlowLog>>>;
  fromState?: Maybe<WorkflowState>;
  fromStateId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  toState?: Maybe<WorkflowState>;
  toStateId?: Maybe<Scalars['ID']>;
  transitionLabel: Scalars['String'];
  workflow?: Maybe<Workflow>;
  workflowId: Scalars['ID'];
};

export type WorkflowTransitionInput = {
  actionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  fromStateId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  toStateId?: InputMaybe<Scalars['ID']>;
  transitionLabel?: InputMaybe<Scalars['String']>;
  workflowId?: InputMaybe<Scalars['ID']>;
};

export type WorkflowUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nodeTypes?: InputMaybe<Array<InputMaybe<NodeType>>>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type FilterDateRange = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type FiltersObj = {
  dateRange?: InputMaybe<FilterDateRange>;
  location?: InputMaybe<Scalars['String']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tipType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SearchFiltersObj = {
  filters?: InputMaybe<FiltersObj>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type SearchParamsObj = {
  keyword?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  rootId?: InputMaybe<Scalars['String']>;
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    