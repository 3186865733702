import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useSubscription, ApolloError } from '@apollo/client'
import { partition } from 'lodash'
import {
  GET_USER_MENTIONS_EVENTS,
  GET_USER_FLOWS_EVENTS,
  GET_USER_FOLLOWING_EVENTS,
  GET_USER_OTHER_EVENTS,
  UPDATE_TRACKING_EVENT,
  UPDATE_USER_TRACKING_EVENTS,
  UPDATES_WIDGETS_SUBSCRIPTION,
} from 'features/trackingEvents/trackingEvents.schema'
import { useAppSlice } from 'features/redux'
import { EVENT_TYPE, FLOW_ACTIVITY_EVENT_TYPES } from 'helpers/constant'
import { NodeType, TrackingEvent, TrackingEventState } from 'types/graphqlSchema'
import { UpdatesType } from '../Dashboard.types'

export default () => {
  const navigate = useNavigate()
  const { user } = useAppSlice()
  const [allMentionsEvents, setAllMentionsEvents] = useState<TrackingEvent[]>([])
  const [unreadMentionsEvents, setUnreadMentionsEvents] = useState<TrackingEvent[]>([])
  const [allFlowsEvents, setAllFlowsEvents] = useState<TrackingEvent[]>([])
  const [todoFlowsEvents, setTodoFlowsEvents] = useState<TrackingEvent[]>([])
  const [doneFlowsEvents, setDoneFlowsEvents] = useState<TrackingEvent[]>([])
  const [reminderFlowsEvents, setReminderFlowsEvents] = useState<TrackingEvent[]>([])
  const [otherFlowsEvents, setOtherFlowsEvents] = useState<TrackingEvent[]>([])
  const [allFollowingEvents, setAllFollowingEvents] = useState<TrackingEvent[]>([])
  const [projectsFollowingEvents, setProjectsFollowingEvents] = useState<TrackingEvent[]>([])
  const [foldersFollowingEvents, setFoldersFollowingEvents] = useState<TrackingEvent[]>([])
  const [filesFollowingEvents, setFilesFollowingEvents] = useState<TrackingEvent[]>([])
  const [allOtherEvents, setAllOtherEvents] = useState<TrackingEvent[]>([])
  const [sharesOtherEvents, setSharesOtherEvents] = useState<TrackingEvent[]>([])
  const [otherOtherEvents, setOtherOtherEvents] = useState<TrackingEvent[]>([])
  const [dotAlerts, setDotAlerts] = useState<{ type: string; alert: boolean }[]>([
    { type: UpdatesType.mentions, alert: false },
    { type: UpdatesType.flows, alert: false },
    { type: UpdatesType.following, alert: false },
    { type: UpdatesType.other, alert: false },
  ])

  // GraphQL Call
  useSubscription(UPDATES_WIDGETS_SUBSCRIPTION, {
    variables: { key: user?.id },
    skip: !user,
    onData: ({
      data: {
        data: {
          updatesWidgetsUpdated: { eventType },
        },
      },
    }) => {
      if (eventType === EVENT_TYPE.USER_MENTIONED || eventType === EVENT_TYPE.FLOW_USER_MENTIONED) {
        refetchUserMentionsEvents()
      } else if (FLOW_ACTIVITY_EVENT_TYPES.includes(eventType)) {
        refetchUserFlowsEvents()
      } else if (eventType === EVENT_TYPE.RESOURCE_FOLLOWED) {
        refetchUserFollowingsEvents()
      } else if (eventType === EVENT_TYPE.RESOURCE_SHARED) {
        refetchUserOthersEvents()
      }
    },
    onError: (error: ApolloError) => {
      console.log(error.message)
    },
  })

  const { refetch: refetchUserMentionsEvents } = useQuery(GET_USER_MENTIONS_EVENTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data || !data.getUserMentionsEvents) return
      const unreadMentions = data.getUserMentionsEvents.filter((event) => event.isRead === false)
      if (data.getUserMentionsEvents?.some((event) => event.hasSeen === false)) {
        setDotAlerts((prev) => prev.map((el) => (el.type === UpdatesType.mentions ? { ...el, alert: true } : el)))
      }
      setAllMentionsEvents(data.getUserMentionsEvents)
      setUnreadMentionsEvents(unreadMentions)
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  const { refetch: refetchUserFlowsEvents } = useQuery(GET_USER_FLOWS_EVENTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data || !data.getUserFlowsEvents) return
      const { getUserFlowsEvents } = data
      if (getUserFlowsEvents?.some((event) => event.hasSeen === false)) {
        setDotAlerts((prev) => prev.map((el) => (el.type === UpdatesType.flows ? { ...el, alert: true } : el)))
      }
      const todoEvents = getUserFlowsEvents.filter((event) => event.eventType === EVENT_TYPE.FLOW_SENT)
      const doneEvents = getUserFlowsEvents.filter(
        (event) =>
          event.eventType === EVENT_TYPE.FLOW_APPROVED ||
          event.eventType === EVENT_TYPE.FLOW_REJECTED ||
          event.eventType === EVENT_TYPE.FLOW_UNRESOLVED ||
          event.eventType === EVENT_TYPE.FEEDBACK_FLOW_SUBMITTED,
      )
      const reminderEvents = getUserFlowsEvents.filter(
        (event) =>
          event.eventType === EVENT_TYPE.FLOW_INTERVAL_REMINDER_CREATOR ||
          event.eventType === EVENT_TYPE.FLOW_INTERVAL_REMINDER_ASSIGNEE,
        // extra flow reminder events (later)
        // EVENT_TYPE.FLOW_TODAY_DUE_REMINDER_CREATOR,
        // EVENT_TYPE.FLOW_TODAY_DUE_REMINDER_ASSIGNEE,
        // EVENT_TYPE.FLOW_PAST_DUE_REMINDER_CREATOR,
        // EVENT_TYPE.FLOW_PAST_DUE_REMINDER_ASSIGNEE,
      )
      const otherEvents = getUserFlowsEvents.filter(
        (event) => !todoEvents.includes(event) && !doneEvents.includes(event) && !reminderEvents.includes(event),
      )
      setAllFlowsEvents(getUserFlowsEvents)
      setTodoFlowsEvents(todoEvents)
      setDoneFlowsEvents(doneEvents)
      setReminderFlowsEvents(reminderEvents)
      setOtherFlowsEvents(otherEvents)
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  const { refetch: refetchUserFollowingsEvents } = useQuery(GET_USER_FOLLOWING_EVENTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data || !data.getUserFollowingEvents) return
      const { getUserFollowingEvents } = data
      if (getUserFollowingEvents?.some((event) => event.hasSeen === false)) {
        setDotAlerts((prev) => prev.map((el) => (el.type === UpdatesType.following ? { ...el, alert: true } : el)))
      }
      const projectsEvents = getUserFollowingEvents.filter(
        (event) => event.customFields.followedResourceType === NodeType.Project,
      )
      const foldersEvents = getUserFollowingEvents.filter(
        (event) => event.customFields.followedResourceType === NodeType.Box,
      )
      const filesEvents = getUserFollowingEvents.filter(
        (event) => event.customFields.followedResourceType === NodeType.Tip,
      )
      setAllFollowingEvents(getUserFollowingEvents)
      setProjectsFollowingEvents(projectsEvents)
      setFoldersFollowingEvents(foldersEvents)
      setFilesFollowingEvents(filesEvents)
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  const { refetch: refetchUserOthersEvents } = useQuery(GET_USER_OTHER_EVENTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data || !data.getUserOtherEvents) return
      const { getUserOtherEvents } = data
      if (getUserOtherEvents?.some((event) => event.hasSeen === false)) {
        setDotAlerts((prev) => prev.map((el) => (el.type === UpdatesType.other ? { ...el, alert: true } : el)))
      }
      const [shares, others] = partition(getUserOtherEvents, (event) => event.eventType === EVENT_TYPE.RESOURCE_SHARED)
      setAllOtherEvents(getUserOtherEvents)
      setSharesOtherEvents(shares)
      setOtherOtherEvents(others)
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  const [updateUserEvent] = useMutation(UPDATE_TRACKING_EVENT, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.error(error)
    },
  })

  const [updateUserTrackingEvents] = useMutation(UPDATE_USER_TRACKING_EVENTS, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.error(error)
    },
  })

  const getEventTypes = (updateType) => {
    switch (updateType) {
      case UpdatesType.mentions:
        return [EVENT_TYPE.FLOW_USER_MENTIONED, EVENT_TYPE.USER_MENTIONED]
      case UpdatesType.flows:
        return FLOW_ACTIVITY_EVENT_TYPES
      case UpdatesType.following:
        return [EVENT_TYPE.RESOURCE_FOLLOWED]
      case UpdatesType.other:
        return [EVENT_TYPE.RESOURCE_SHARED]
      default:
        return []
    }
  }

  const handlePopoverOpen = async (updatesType) => {
    const eventTypes = getEventTypes(updatesType)
    await updateUserTrackingEvents({
      variables: {
        input: {
          eventTypes,
          hasSeen: true,
        },
      },
    })
    setDotAlerts((prev) => prev.map((el) => (el.type === updatesType ? { ...el, alert: false } : el)))
  }

  const handleMenuAction = async (typeAction: string) => {
    const updateType = typeAction.split('_')[0]
    const action = typeAction.split('_')[1]
    const eventTypes = getEventTypes(updateType)
    if (action === 'readAll') {
      const { data } = await updateUserTrackingEvents({
        variables: {
          input: {
            eventTypes,
            isRead: true,
          },
        },
      })
      if (data.updateUserTrackingEvents < 0) {
        throw new Error("Something went wrong while updating user's tracking events")
      }
    } else if (action === 'deleteAll') {
      const { data } = await updateUserTrackingEvents({
        variables: {
          input: {
            eventTypes,
            state: TrackingEventState.Inactive,
          },
        },
      })
      if (data.updateUserTrackingEvents < 0) {
        throw new Error("Something went wrong while updating user's tracking events")
      }
    }
    switch (updateType) {
      case UpdatesType.mentions:
        await refetchUserMentionsEvents()
        break
      case UpdatesType.flows:
        await refetchUserFlowsEvents()
        break
      case UpdatesType.following:
        await refetchUserFollowingsEvents()
        break
      case UpdatesType.other:
        await refetchUserOthersEvents()
        break
    }
  }

  const handleItemClick = async (event) => {
    // mark as read
    await updateUserEvent({
      variables: {
        input: {
          id: event.id,
          isRead: true,
        },
      },
    })
    // navigate. TODO: handle external links
    // TODO: remove this check when TA-1339 is done
    if (event.eventType !== EVENT_TYPE.FLOW_CANCELLED) {
      navigate(event.eventUrl)
    }
  }

  return {
    handlePopoverOpen,
    handleMenuAction,
    handleItemClick,
    dotAlerts,
    allMentionsEvents,
    unreadMentionsEvents,
    allFlowsEvents,
    todoFlowsEvents,
    doneFlowsEvents,
    reminderFlowsEvents,
    otherFlowsEvents,
    allFollowingEvents,
    projectsFollowingEvents,
    foldersFollowingEvents,
    filesFollowingEvents,
    allOtherEvents,
    sharesOtherEvents,
    otherOtherEvents,
  }
}
