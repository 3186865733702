import { Flex, Icon, Text, useBreakpointValue, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { isSafari } from 'react-device-detect'
import { Button, IconButton, IconPopover, ButtonPopover } from 'components'
import { disableOptions, menuActions } from 'helpers/constant'
import { usePermissions } from 'hooks'
import { customIcons, icons } from 'theme'
import DotActionsMenu from './components/DotActionsMenu'
import NewActionsMenu from './components/NewActionsMenu'
import { FollowedNode, Node } from 'types/graphqlSchema'
import { useAppSlice } from 'features/redux'
import { useEffect, useState, useMemo } from 'react'

interface ActionBarProps {
  currentNode?: Node
  openModal: string
  setOpenModal: (openModal: string) => void
  pathname: string
  handleMenuAction: (action: string) => void
}

const ActionBar = ({ openModal, setOpenModal, pathname, currentNode, handleMenuAction }: ActionBarProps) => {
  const [disableMenuOptions, setDisableMenuOptions] = useState<string[]>([])
  const isMobile = useBreakpointValue({
    xs: true,
    sm: true,
    md: true,
    lg: false,
  })
  const mobileShareIcon = 'textHighlight'
  const { hasPermission } = usePermissions()
  const { user } = useAppSlice()
  const isSharedFolder =
    currentNode && currentNode.type === 'BOX'
      ? currentNode.sharedMembers?.some((sm) => sm?.sharedWithId === user.id && user.id !== currentNode.ownerId)
      : false
  const newActionsMenuPopover = useDisclosure()

  const showCreateNewItem = !pathname.includes('/file/')
  // const showDownloadBtn = !!currentTip
  const showShareBtn = !pathname.includes('/personal')

  const isFollowed = useMemo(() => {
    if (!user.followedNodes) return false
    return user.followedNodes.some((f: FollowedNode) => f.nodeId === currentNode?.id)
  }, [user, currentNode])

  useEffect(() => {
    if (!currentNode) {
      return
    }
    if (pathname.includes('personal') && currentNode?.type === 'PROJECT') {
      setDisableMenuOptions(disableOptions.personal)
      return
    }
    setDisableMenuOptions(disableOptions[currentNode?.type] || [])
  }, [currentNode])

  if (isMobile) {
    return (
      <Flex align="center">
        {showShareBtn && (
          <Flex width="30px">
            <IconButton
              ariaLabel="shareButton"
              icon={customIcons.menuShare}
              variant="passwordBtn"
              iconSize={{ height: 5, width: 5 }}
              iconMarginRight={2}
              style={{
                color: mobileShareIcon,
                padding: 0,
                width: '30px',
              }}
              onClick={() => handleMenuAction(menuActions.share)}
              isDisable={!hasPermission('share')}
            />
          </Flex>
        )}
        {/* <IconPopover
          icon={icons.menuIcon}
          iconSize={{ width: 6, height: 6 }}
          iconColor={useColorModeValue('', 'white')}
          variantBtn={'passwordBtn'}
          testId="action-bar-dot-popover-button"
          xMargin={0}
          buttonStyle={{
            width: '48px',
            height: '48px',
            marginLeft: 4,
            marginRight: 0,
            padding: 0,
            color: 'accent',
          }}
        >
          <DotActionsMenu
            follow={isFollowed}
            handleMenuAction={handleMenuAction}
            disableMenuOptions={disableMenuOptions}
          />
        </IconPopover> */}
      </Flex>
    )
  }

  return (
    <Flex align="center">
      {showCreateNewItem && (
        <ButtonPopover
          variantBtn="btnOutline"
          buttonLabel="New"
          buttonContent={
            <Flex align={'center'} gap={1}>
              <Icon as={icons.plus} color="white" boxSize={6} strokeWidth={'1.1'} />
              <Text fontWeight={500}>New</Text>
            </Flex>
          }
          marginXBtn="0"
          buttonStyle={{
            width: '105px',
            height: '3rem',
            padding: 0,
            marginLeft: 4,
          }}
          isDisable={!hasPermission('update') && !hasPermission('upload')}
          isOpen={newActionsMenuPopover.isOpen}
          onOpen={newActionsMenuPopover.onOpen}
          onClose={newActionsMenuPopover.onClose}
        >
          <NewActionsMenu
            setOpenModal={setOpenModal}
            openModal={openModal}
            handleMenuAction={async (action) => {
              handleMenuAction(action)
              newActionsMenuPopover.onClose()
            }}
          />
        </ButtonPopover>
      )}

      {/* {showDownloadBtn && ( */}
      {/*   <Button */}
      {/*     variant="btnOutline" */}
      {/*     leftIcon={<icons.downLoad />} */}
      {/*     label="Download" */}
      {/*     style={{ */}
      {/*       width: '5.75rem', */}
      {/*       height: '3rem', */}
      {/*       padding: 0, */}
      {/*       fontSize: '15.7px', */}
      {/*       letterSpacing: isSafari ? '-1px' : 'normal', */}
      {/*       marginLeft: 4, */}
      {/*     }} */}
      {/*     onClick={() => handleMenuAction(menuActions.download)} */}
      {/*     disabled={!hasPermission('download')} */}
      {/*   /> */}
      {/* )} */}

      {showShareBtn && (
        <Button
          label="Share"
          leftIcon={<Icon as={icons.share} color="inherit" boxSize={5} />}
          variant="defaultButton"
          disabled={!hasPermission('share')}
          onClick={() => handleMenuAction(menuActions.share)}
          style={{
            width: '105px',
            height: '3rem',
            marginLeft: 4,
            padding: 0,
            fontSize: '15.7px',
            letterSpacing: isSafari ? '-1px' : 'normal',
          }}
        />
      )}

      {/* <IconPopover
        icon={icons.menuIcon}
        iconSize={{ width: 6, height: 6 }}
        iconColor={useColorModeValue('', 'white')}
        variantBtn={'btnOutline'}
        testId="action-bar-dot-popover-button"
        xMargin={0}
        buttonStyle={{
          width: '3rem',
          height: '3rem',
          marginLeft: 4,
          marginRight: 0,
          padding: 0,
          color: 'accent',
        }}
      >
        <DotActionsMenu
          isSharedWithMe={isSharedFolder}
          follow={isFollowed}
          handleMenuAction={handleMenuAction}
          disableMenuOptions={disableMenuOptions}
        />
      </IconPopover> */}
    </Flex>
  )
}

export default ActionBar
