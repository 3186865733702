import { useState } from 'react'
import { Flex, Avatar, Text as ChakraText, Box } from '@chakra-ui/react'
import { Dot } from 'components/elements'
import { formatTimeSince } from 'helpers/utils'
import { useFlowContents } from 'features/workflow/hooks'
import { ItemProps } from 'features/dashboard/Dashboard.types'

const OtherItem = ({ event, handleItemClick }: ItemProps) => {
  const [isHovering, setHovering] = useState(false)
  const { getOtherEventContent } = useFlowContents()
  const otherEventContent = getOtherEventContent(event)
  const { projectName, resourceType, resourceName } = event.customFields

  return (
    <Box
      as="button"
      w="full"
      textAlign="left"
      onClick={() => handleItemClick(event)}
      borderLeft="16px solid"
      borderColor={isHovering ? 'accent' : 'transparent'}
      bg="bg"
      _hover={{ bg: 'bg' }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Flex p={4} borderTop="1px" borderColor="textSoft" mb={event.isRead ? 0 : '2px'}>
        <Avatar
          size="md"
          name={event.user?.avatarSecureUrl ? '' : `${event.user?.firstName} ${event.user?.lastName}`}
          src={event.user?.avatarSecureUrl ? event.user?.avatarSecureUrl : ''}
        />
        <Flex pl={4} flexDir="column" flex={1}>
          <ChakraText variant="mediumHighlight">
            {otherEventContent.header} <span style={{ fontWeight: 'bold' }}>{resourceName}</span>
          </ChakraText>
          <Flex gap={6}>
            <ChakraText variant="tinyCharcoal">{formatTimeSince(event.createdAt, 'time')}</ChakraText>
            {resourceType !== 'PROJECT' && <ChakraText variant="tinyCharcoal">Project: {projectName}</ChakraText>}
          </Flex>
        </Flex>
        <Flex align="center" px={2}>
          <Dot visible={!event.isRead} size="10px" bgColor="accent" />
        </Flex>
      </Flex>
    </Box>
  )
}

export default OtherItem
