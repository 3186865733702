import { Flex, Radio, RadioGroup, Stack, Tooltip, Text } from '@chakra-ui/react'
import { images } from 'theme'
import { DueDatePopover } from 'components'
import FlowModalToggle from './FlowModalToggle'
import { TaskType, taskTypes } from '../ShareTaskForm'
import { UserGroup } from 'types/graphqlSchema'
import { UserOrGuest } from '../types/flow-form-types'

interface props {
  recipientGroups: UserGroup[]
  selectedTaskType: string
  handleChangeTaskType: (newValue: TaskType) => void
  recipients: UserOrGuest[]
  flowState: FlowState
  setFlowState: (FlowState) => void
}

const taskTooltipContent = (type: TaskType) => {
  switch (type) {
    case taskTypes.approval:
      return 'An Approval task request requires the recipients to Approve or Reject the assets in this document'
    case taskTypes.feedback:
      return 'A Feedback task request requires the recipients to submit written feedback regarding the assets in this document'
    case taskTypes.file:
      return 'A File task request requires the recipients to attach one or more files to this document'
    default:
      break
  }
}

const ShareTaskFormTaskUI = ({
  recipientGroups,
  selectedTaskType,
  handleChangeTaskType,
  recipients,
  flowState,
  setFlowState,
}: props) => {
  return (
    <>
      <Flex
        w="full"
        justifyContent={'space-between'}
        gap={12}
        px={6}
        mb={4}
        pt={[...recipients].length > 0 || recipientGroups.length > 0 ? 4 : 0}
      >
        <RadioGroup value={selectedTaskType} onChange={handleChangeTaskType}>
          <Stack direction="row" spacing="80px">
            {Object.keys(taskTypes).map((type: TaskType) => (
              <Radio key={type} value={type} borderColor="#B3B3B3">
                <Tooltip
                  label={
                    <Text fontSize=".75rem" fontWeight={500} color="inherit">
                      {taskTooltipContent(type)}
                    </Text>
                  }
                  placement="bottom"
                  alignItems="flex-start"
                  bgColor="textBlack"
                  color="textHighlight"
                  border="0"
                  width="full"
                  rounded={6}
                  px={5}
                  py={2}
                  hasArrow
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </Tooltip>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </Flex>
      <Flex align="center" pl={6} gap={6} mb={4}>
        <Flex>
          <DueDatePopover flowState={flowState} onChange={(a) => setFlowState(a)} isRequired />
        </Flex>
        {/* Urgent */}
        <FlowModalToggle
          falseImage={images.flowToggleExclamationNegative}
          trueImage={images.flowToggleExclamation}
          toggleItem={flowState.urgent}
          onChange={() => {
            setFlowState((prev) => ({
              ...prev,
              urgent: !prev.urgent,
            }))
          }}
        >
          {flowState.urgent ? 'Urgent' : 'Mark Urgent'}
        </FlowModalToggle>
      </Flex>
    </>
  )
}

export default ShareTaskFormTaskUI
