import { Flex, Grid, GridItem, Image, Text, Tooltip } from '@chakra-ui/react'
import { AvatarsTooltip } from 'components'
import React, { useMemo } from 'react'
import { images, scrollbar } from 'theme'
import { Organization, UserGroup } from 'types/graphqlSchema'
import { UserOrGuest } from '../types/flow-form-types'
import { SharedMemberWithUrl } from 'features/node/hooks/useNodeDetails'

interface props {
  recipientGroups: UserGroup[]
  handleUnshare: (user?: UserOrGuest, userGroup?: UserGroup) => Promise<void>
  sharedGroups: UserGroup[]
  recipients: UserOrGuest[]
  members: SharedMemberWithUrl[]
  isPrivate: boolean
  organization?: Organization
  isShare: boolean
}

const ShareTaskFormRecipientList = ({
  recipientGroups,
  sharedGroups,
  recipients,
  members,
  organization,
  isPrivate,
  isShare,
  handleUnshare,
}: props) => {
  const listedRecipients = useMemo(() => {
    return [...recipients]
      .sort((a, b) => {
        // Check if recipients are pending (not in members)
        const isPendingA = !members?.some((mem) => mem.email === a.email)
        const isPendingB = !members?.some((mem) => mem.email === b.email)

        // Sort pending recipients first
        if (isPendingA && !isPendingB) return -1
        if (!isPendingA && isPendingB) return 1

        // Then sort based on shared groups
        return !sharedGroups.some((g) => g.users?.some((u) => u?.email === a.email)) ? -1 : 1
      })
      .filter((rec) => !sharedGroups.some((g) => g.users?.some((u) => u?.email === rec.email)))
  }, [recipients, members, sharedGroups])

  return (
    <Grid
      templateColumns="1fr"
      overflowY="scroll"
      sx={{
        ...scrollbar,
        scrollbarColor: 'var(--chakra-colors-textRegular) var(--chakra-colors-textHighlight)',
      }}
      maxH="111px"
      pl={6}
    >
      {[...recipientGroups].map((group) => (
        <React.Fragment key={group.id}>
          <GridItem display="flex" gap={3} alignItems="center">
            <Tooltip
              hasArrow
              fontSize={'13px'}
              rounded="lg"
              px=".75rem"
              py=".75rem"
              placement="top"
              bg="textBlack"
              label={
                <Flex flexDir={'column'}>
                  <Text p={0} m={0} color={'textHighlight'}>{`Remove ${group.name}`}</Text>
                </Flex>
              }
            >
              <button
                onClick={() => {
                  handleUnshare(undefined, group)
                }}
              >
                <Grid w="24px" h="24px" placeContent="center">
                  <Image p={0} m={0} src={images.xSmall} />
                </Grid>
              </button>
            </Tooltip>
            <Flex align="center" gap={2}>
              <Grid bg="accentIcon" rounded="full" h="2rem" w="2rem" placeContent="center">
                <Text fontWeight={400} color="textBlack">
                  + {group.users?.length ?? 0}
                </Text>
              </Grid>
              <Flex flexDir="column">
                <Text fontSize={14} fontWeight={400} color="tipbox.dark.text.charcoalGrey">
                  {group.name}
                </Text>
                <Text fontSize={12} color="tipbox.dark.text.charcoalGrey">
                  {group.users?.length ?? 0} members
                </Text>
              </Flex>
            </Flex>
            {!sharedGroups?.some((g) => g.id === group.id) && (
              <Text color="tipbox.dark.text.charcoalGrey" fontStyle="italic" fontSize={12}>
                (pending)
              </Text>
            )}
          </GridItem>
        </React.Fragment>
      ))}
      {listedRecipients.map((r) => {
        const publicNodeAndMemberIsInOrg = !isPrivate && r.organizationId === organization?.id && isShare
        const isPending = !members?.some((mem) => mem.email === r.email)
        return (
          <React.Fragment key={r.email}>
            <GridItem display="flex" gap={3} alignItems="center">
              <Tooltip
                hasArrow
                fontSize={'13px'}
                rounded="lg"
                px=".75rem"
                py=".75rem"
                placement="top"
                bg="textBlack"
                label={
                  <Text p={0} m={0} color={'textHighlight'}>
                    {publicNodeAndMemberIsInOrg
                      ? 'This resource is public to your organization. Set it to private in order to remove organization members.'
                      : `Remove ${r.firstName} ${r.lastName}`}
                  </Text>
                }
              >
                <button
                  style={{ cursor: publicNodeAndMemberIsInOrg ? 'default' : 'pointer' }}
                  onClick={() => {
                    if (!publicNodeAndMemberIsInOrg) handleUnshare(r)
                  }}
                >
                  {publicNodeAndMemberIsInOrg ? (
                    <Grid
                      w="24px"
                      h="24px"
                      bgColor="accentIcon"
                      bgImage={organization?.logoSecureUrl ?? ''}
                      bgSize="contain"
                      rounded="md"
                      color="textBlack"
                      placeContent="center"
                    >
                      {organization?.logoSecureUrl ? '' : organization?.name?.charAt(0) || ''}
                    </Grid>
                  ) : (
                    <Grid w="24px" h="24px" placeContent="center">
                      <Image p={0} src={images.xSmall} />
                    </Grid>
                  )}
                </button>
              </Tooltip>
              <AvatarsTooltip users={[r]} />
              {isPending && (
                <Text color="tipbox.dark.text.charcoalGrey" fontStyle="italic" fontSize={12}>
                  (pending)
                </Text>
              )}
            </GridItem>
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

export default ShareTaskFormRecipientList
