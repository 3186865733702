import { useState } from 'react'
import { Flex, Avatar, Text as ChakraText, Box, Icon } from '@chakra-ui/react'
import { EditorContent, useEditor } from '@tiptap/react'
import Mention from '@tiptap/extension-mention'
import Text from '@tiptap/extension-text'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import { Dot } from 'components/elements'
import { formatTimeSince } from 'helpers/utils'
import { FOLLOWING_EVENT_TYPES } from 'helpers/constant'
import { useFlowContents } from 'features/workflow/hooks'
import { icons } from 'theme'
import { ItemProps } from 'features/dashboard/Dashboard.types'
import './mentions.scss'

const SubHeaderContent = ({ event, subContent }) => {
  if (!subContent) return null

  const {
    customFields: { followingEventType },
  } = event

  if (followingEventType === FOLLOWING_EVENT_TYPES.COMMENT_ON_NODE) {
    const editor = useEditor({
      content: subContent,
      extensions: [
        Document,
        Paragraph,
        Text,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
        }),
      ],
    })
    return (
      <Box py={1}>
        <EditorContent className="tiptapDashboardMentions" editor={editor} />
      </Box>
    )
  }

  return <ChakraText variant="mediumHighlight">{subContent}</ChakraText>
}

const FollowingItem = ({ event, handleItemClick }: ItemProps) => {
  const [isHovering, setHovering] = useState(false)
  const { getFollowingEventContent } = useFlowContents()
  const followingEventContent = getFollowingEventContent(event)
  const { projectName, followedResourceName } = event.customFields

  return (
    <Box
      as="button"
      w="full"
      textAlign="left"
      onClick={() => handleItemClick(event)}
      borderLeft="16px solid"
      borderColor={isHovering ? 'accent' : 'transparent'}
      bg="bg"
      _hover={{ bg: 'bg' }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Flex p={4} borderTop="1px" borderColor="textSoft" mb={event.isRead ? 0 : '2px'}>
        <Avatar
          size="md"
          name={event.user?.avatarSecureUrl ? '' : `${event.user?.firstName} ${event.user?.lastName}`}
          src={event.user?.avatarSecureUrl ? event.user?.avatarSecureUrl : ''}
        />
        <Flex pl={4} flexDir="column" flex={1}>
          <ChakraText variant="mediumHighlight">{followingEventContent.header}</ChakraText>
          <Flex alignItems="center">
            <Icon as={icons.follow} color="accent" mr={2} />
            <ChakraText variant="mediumHighlightBold">{followedResourceName}</ChakraText>
          </Flex>
          <SubHeaderContent event={event} subContent={followingEventContent.subHeader} />
          <Flex gap={6}>
            <ChakraText variant="tinyCharcoal">{formatTimeSince(event.createdAt, 'time')}</ChakraText>
            <ChakraText variant="tinyCharcoal">Project: {projectName}</ChakraText>
          </Flex>
        </Flex>
        <Flex align="center" px={2}>
          <Dot visible={!event.isRead} size="10px" bgColor="accent" />
        </Flex>
      </Flex>
    </Box>
  )
}

export default FollowingItem
