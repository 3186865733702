import { useState, useEffect } from 'react'
import { useDisclosure, Button, Icon } from '@chakra-ui/react'
import { User, Node } from 'types/graphqlSchema'
import { privacySetting } from 'helpers/constant'
import { icons } from 'theme'
import { AlertWithAction } from 'components/fragments'

interface PrivacyToggleProps {
  user: User
  node?: Node
  currentPrivacySetting?: boolean | null
  onPrivacyChange: (isPrivate: boolean) => void
  useConfirmation?: boolean
}

export default function PrivacyToggle({
  user,
  node,
  currentPrivacySetting,
  onPrivacyChange,
  useConfirmation = true,
}: PrivacyToggleProps) {
  const orgWidePrivacy = user?.organization?.privacySetting === privacySetting.organizationWide
  const {
    isOpen: isOpenPrivacyConfirmation,
    onOpen: onOpenPrivacyConfirmation,
    onClose: onClosePrivacyConfirmation,
  } = useDisclosure()

  const [isPrivate, setIsPrivate] = useState(() => {
    if (currentPrivacySetting !== null && currentPrivacySetting !== undefined) {
      return currentPrivacySetting
    }
    if (node) {
      return node.private ?? false
    }
    return !orgWidePrivacy
  })

  const ownerIsCurrentUser = user.id === node?.ownerId?.toString()

  useEffect(() => {
    onPrivacyChange(isPrivate)
  }, [isPrivate, onPrivacyChange])

  const getConfirmationType = (node: Node): string => {
    switch (node.type) {
      case 'PROJECT':
        return 'project'
      case 'BOX':
        return 'folder'
      default:
        return 'item'
    }
  }

  const handlePrivacyToggle = () => {
    if (useConfirmation) {
      onOpenPrivacyConfirmation()
    } else {
      setIsPrivate(!isPrivate)
    }
  }

  return (
    <>
      <Button
        aria-label="Toggle privacy"
        bg="background"
        color="textRegular"
        display="flex"
        alignItems="center"
        h="40px"
        w="40px"
        justifyContent="center"
        border="1px solid"
        borderColor="transparent"
        opacity="80%"
        _hover={{ bg: 'accent', color: 'textHighlight', opacity: '100%' }}
        _active={{ color: 'textHighlight', borderColor: 'textHighlight', opacity: '100%' }}
        onClick={handlePrivacyToggle}
        disabled={!ownerIsCurrentUser}
      >
        <Icon as={isPrivate ? icons.IoEyeOffOutline : icons.IoEyeOutline} boxSize={7} />
      </Button>
      <AlertWithAction
        action={() => {
          setIsPrivate((prev) => !prev)
          onClosePrivacyConfirmation()
        }}
        isOpen={isOpenPrivacyConfirmation}
        onClose={onClosePrivacyConfirmation}
        actionText={`Set ${node?.type && getConfirmationType(node)} ${isPrivate ? 'Public' : 'Private'}?`}
        warningText={[
          isPrivate
            ? `Make this public?  Other members of your organization will be able to view this ${
                node?.type && getConfirmationType(node)
              }.`
            : `Make this private?  Other members of your organization will not be able to view this ${
                node?.type && getConfirmationType(node)
              }.`,
        ]}
        confirmButtonText="Yes"
      />
    </>
  )
}
