import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useClipboard } from 'hooks'
import { useNodeMenuActions } from 'features/node/hooks'
import { useAppSlice, useProjectSlice, useBoxSlice, useTipSlice } from 'features/redux'
import { GET_RECENT_NODES, GET_FOLLOWED_NODES, GET_RECENT_VIEWED_PROJECTS } from 'features/graphql'
import CONFIG from 'helpers/config'
import { menuActions, nodeType } from 'helpers/constant'
import { FollowedNode, Node } from 'types/graphqlSchema'

export default () => {
  const { downloadNode, shareNode, updateShareNode, unshareNode, updateFollowedNode, navigateTo } = useNodeMenuActions()
  const { copyToClipBoard } = useClipboard()
  const { dispatch, user, setFollowedNodes } = useAppSlice()
  const { clearCurrentProject } = useProjectSlice()
  const { clearCurrentBox } = useBoxSlice()
  const { clearCurrentTip } = useTipSlice()
  const [recentFoldersFiles, setRecentFoldersFiles] = useState([])
  const [followings, setFollowings] = useState([])
  const [menuActionModel, setMenuActionModel] = useState('')
  const [modelDetails, setModelDetails] = useState<Node | null>()

  // GraphQL Calls
  const {
    loading: recentViewedProjectsLoading,
    refetch: refetchRecentViewedProjects,
    data: recentViewedProjects,
  } = useQuery(GET_RECENT_VIEWED_PROJECTS, {
    variables: { count: 10 },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  useQuery(GET_RECENT_NODES, {
    variables: { count: 10 },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ recentNodes }) => {
      setRecentFoldersFiles(recentNodes?.filter((node) => node.type !== nodeType.project))
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  const { refetch: refetchFollowing } = useQuery(GET_FOLLOWED_NODES, {
    variables: { count: 0 },
    onCompleted: ({ followedNodes }) => {
      setFollowings(followedNodes.map((p: FollowedNode) => p.node))
      dispatch(setFollowedNodes(followedNodes))
    },
    onError: (error) => {
      throw new Error(error.message)
    },
  })

  // Not sure if this still needed
  useEffect(() => {
    dispatch(clearCurrentProject())
    dispatch(clearCurrentBox())
    dispatch(clearCurrentTip())
  }, [])

  const handleShareNode = async (usersEmails: string, nodeId: string, emailMessage: string, shareType: string) => {
    const shareInfo = await shareNode(usersEmails, nodeId, emailMessage, shareType)
    if (modelDetails) {
      setModelDetails({
        ...modelDetails,
        sharedMembers: shareInfo.sharedMembers || [],
      })
    }
  }

  const handleUpdateShare = async (userId: string, nodeId: string, shareType: string) => {
    const shareInfo = await updateShareNode(userId, nodeId, shareType, 'recend')

    if (modelDetails) {
      setModelDetails({
        ...modelDetails,
        sharedMembers: shareInfo.sharedMembers || [],
      })
    }
  }

  const handleUnshareNode = async (userId: string, nodeId: string) => {
    const shareInfo = await unshareNode(userId, nodeId)
    if (modelDetails) {
      setModelDetails({
        ...modelDetails,
        sharedMembers: shareInfo.sharedMembers || [],
      })
    }
  }

  const handleCopyLinkShare = async (node: Node) => {
    if (node?.id) copyToClipBoard(`${CONFIG.APP.URL}/link/${node.id}`)
  }

  const handleMenuAction = async (action: string, node: Node): Promise<void> => {
    setMenuActionModel(action)
    switch (action) {
      case menuActions.share:
        setModelDetails(node)
        break
      case menuActions.copyLink:
        handleCopyLinkShare(node)
        break
      case menuActions.download:
        downloadNode(node)
        break
      case menuActions.follow:
        try {
          await updateFollowedNode(node.id)
          const { data: followedNodes } = await refetchFollowing()
          // const { followedNodes } = data
          setFollowings(followedNodes.map((p: FollowedNode) => p.node))
          dispatch(setFollowedNodes(followedNodes))
        } catch (error) {
          console.error(error)
        }
        break
      default:
        break
    }
  }

  const handleClick = (node: Node) => {
    const { type } = node
    navigateTo(type?.toLowerCase(), node.id)
  }

  return {
    user,
    refetchRecentViewedProjects,
    recentViewedProjectsLoading,
    recentViewedProjects,
    recentFoldersFiles,
    modelDetails,
    menuActionModel,
    setMenuActionModel,
    handleMenuAction,
    handleClick,
    handleShareNode,
    handleUpdateShare,
    handleUnshareNode,
    followings,
  }
}
