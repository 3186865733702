import React from 'react'
import { Button, Icon, Text, useBreakpointValue } from '@chakra-ui/react'
import { FilterType } from './TaskList'
import { Flow } from 'types/graphqlSchema'
import { icons } from 'theme'

interface FilterButtonProps {
  type: FilterType
  activeFilter: string
  filterTypes: Record<FilterType, string>
  filteredTasks: {
    all: Flow[]
    urgent: Flow[]
    overdue: Flow[]
    sent: Flow[]
  }
  toggleFilter: (type: FilterType) => void
}

const TaskFilterButton: React.FC<FilterButtonProps> = ({
  type,
  activeFilter,
  filterTypes,
  filteredTasks,
  toggleFilter,
}) => {
  const largeScreen = useBreakpointValue({ base: false, '2xl': true })

  return (
    <Button
      aria-label={filterTypes[type]}
      bg="background"
      color="textRegular"
      onClick={() => toggleFilter(type)}
      isActive={activeFilter === type}
      display="flex"
      gap={2}
      alignItems="center"
      h="40px"
      justifyContent="flex-start"
      flex={1}
      border="1px solid"
      borderColor="transparent"
      px={{ base: 2, '2xl': 4 }}
      _hover={{ bg: 'accent', color: 'textHighlight' }}
      _active={{ color: 'textHighlight', borderColor: 'textHighlight' }}
    >
      <Text
        fontWeight={600}
        color="inherit"
        fontSize={{ base: 14, sm: 16, lg: 14, '2xl': 20 }}
        mr={{ base: 0, '2xl': 1 }}
      >
        {filteredTasks[type]?.length}
      </Text>
      {type === 'urgent' && (
        <Icon
          as={icons.BsExclamationSquareFill}
          w={{ base: 3, '2xl': 4 }}
          h={{ base: 3, '2xl': 4 }}
          color="inherit"
          display={{ base: 'none', sm: 'block', lg: 'none', xl: 'block' }}
        />
      )}
      <Text aria-label="Filter Name" color="inherit" fontWeight={400} fontSize={{ base: 12, sm: 14, lg: 12, xl: 14 }}>
        {filterTypes[type]}
      </Text>
    </Button>
  )
}

export default TaskFilterButton
