import { useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Flex,
  Divider,
} from '@chakra-ui/react'
import { IoSearch } from 'react-icons/io5'
import { useAppSlice } from 'features/redux'
import { Organization } from 'types/graphqlSchema'

interface props {
  isOpen: boolean
  onClose: () => void
  pageType: string
  orgs: Organization[]
}

const OrgsListModal = ({ isOpen, onClose, orgs, pageType }: props) => {
  const [searchTerm, setSearchTerm] = useState('')
  const filteredOrgs = Array.from(new Map(orgs.map((item) => [item.id, item])).values()).filter((item) =>
    item.name?.toLowerCase().includes(searchTerm.toLowerCase()),
  )
  const { dispatch, sortType, setSortType, setOrgFilterId } = useAppSlice()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="textHighlight" color="textBlack">
        <ModalBody py={6}>
          <InputGroup variant="filled">
            <InputLeftElement fontSize="1.5rem" left={5} color="textRegular" pointerEvents="none">
              <IoSearch />
            </InputLeftElement>
            <Input
              fontSize="1.125rem"
              mx={8}
              maxW="90%"
              py={2}
              border="none"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
            />
          </InputGroup>
          <Divider mb={6} />
          <Flex flexDir={'column'} alignItems={'flex-start'} ml={5} gap={6}>
            {filteredOrgs.map((org) => {
              return (
                <Button
                  key={org.id}
                  variant="unstyled"
                  onClick={() => {
                    if (pageType === 'projects') {
                      dispatch(setOrgFilterId(org.id))
                      dispatch(setSortType({ ...sortType, project: 'Organization' }))
                    }
                    setSearchTerm('')
                    onClose()
                  }}
                >
                  <Text color="textBlack">{org.name}</Text>
                </Button>
              )
            })}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default OrgsListModal
