import { useState, useEffect } from 'react'
import { Flex, Text, Box, Icon, Grid } from '@chakra-ui/react'
import { icons } from 'theme'
import { Flow, Node } from 'types/graphqlSchema'
import { useNavigate } from 'react-router-dom'
import { useFlowFilters } from 'features/workflow'
import { Loader } from 'components'
import TaskFilterButton from './TaskFilterButton'
import TaskListCard from './TaskListCard'

const filterTypes = {
  all: 'All Tasks',
  urgent: 'Urgent',
  overdue: 'Overdue',
  sent: 'Sent',
}

export type FilterType = keyof typeof filterTypes

interface TaskListProps {
  currentProject?: Node
}

const TaskList = ({ currentProject }: TaskListProps) => {
  const navigate = useNavigate()
  const { todoFlows, todoUrgentFlows, todoOverdueFlows, inProgressFlows } = useFlowFilters()
  const [isLoading, setIsLoading] = useState(true)
  const [activeFilter, setActiveFilter] = useState<FilterType>('all')
  const [tasks, setTasks] = useState<{
    all: Flow[]
    urgent: Flow[]
    overdue: Flow[]
    sent: Flow[]
  }>({
    all: [],
    urgent: [],
    overdue: [],
    sent: [],
  })
  const [filteredTasks, setFilteredTasks] = useState<{
    all: Flow[]
    urgent: Flow[]
    overdue: Flow[]
    sent: Flow[]
  }>({
    all: [],
    urgent: [],
    overdue: [],
    sent: [],
  })

  const toggleFilter = (filterType: FilterType) => {
    setActiveFilter(filterType)
  }

  useEffect(() => {
    setTasks({
      all: [...todoFlows, ...inProgressFlows],
      urgent: todoUrgentFlows ?? [],
      overdue: todoOverdueFlows ?? [],
      sent: inProgressFlows ?? [],
    })
  }, [todoFlows, todoUrgentFlows, todoOverdueFlows, inProgressFlows])

  useEffect(() => {
    const loadFlows = async () => {
      if (!tasks?.all) {
        return
      }
      const projectId = currentProject?.id
      if (!projectId) {
        setFilteredTasks(tasks)
      } else {
        setFilteredTasks({
          all: tasks.all?.filter((flow) => flow.projectId === projectId) ?? [],
          urgent: tasks.urgent?.filter((flow) => flow.projectId === projectId) ?? [],
          overdue: tasks.overdue?.filter((flow) => flow.projectId === projectId) ?? [],
          sent: tasks.sent?.filter((flow) => flow.projectId === projectId) ?? [],
        })
      }
      setIsLoading(false)
    }
    loadFlows()
  }, [tasks, currentProject])

  const selectedTasks = filteredTasks[activeFilter] || []
  const noTasks = !selectedTasks || !selectedTasks.length

  return (
    <Box pos="relative" w="full" h="full">
      {isLoading ? (
        <Loader />
      ) : (
        <Flex direction="column" w="full" h="full" py={4}>
          <Text pl={4} alignSelf="flex-start" fontWeight={600} mb={4} display={{ base: 'none', '2xl': 'block' }}>
            My Tasks
          </Text>
          <Grid
            templateColumns={{
              base: 'repeat(4, 1fr)',
            }}
            w="full"
            gap=".75rem"
            mb={4}
            px={4}
          >
            {Object.keys(filterTypes).map((type) => (
              <TaskFilterButton
                key={type}
                type={type as FilterType}
                activeFilter={activeFilter}
                filterTypes={filterTypes}
                filteredTasks={filteredTasks}
                toggleFilter={toggleFilter}
              />
            ))}
          </Grid>
          {noTasks ? (
            <Flex w="full" h="full" direction="column" align="center" justify="center" p={2} borderRadius="md">
              <Icon as={icons.LuClapperboard} color="gray.500" mb={4} w="1.5rem" h="1.5rem" />
              <Text fontSize="lg" color="gray.500">
                No tasks found.
              </Text>
            </Flex>
          ) : (
            <Flex h="full" w="full" direction="column" gap="1rem" overflowY="auto" pt={2} pl={4}>
              {selectedTasks.map((flow) => (
                <TaskListCard key={flow.id} flow={flow} onClick={() => navigate(`/flows/flow/${flow.id}`)} />
              ))}
            </Flex>
          )}
        </Flex>
      )}
    </Box>
  )
}

export default TaskList
