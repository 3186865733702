import { Flex, Textarea, Text, useBreakpointValue } from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { isFirefox } from 'react-device-detect'

interface MessageFormProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const FlowFormMessage = ({ value, onChange }: MessageFormProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const maxLength = 180
  const [charactersRemaining, setCharactersRemaining] = useState<number>(maxLength)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const minHeight = '60px'

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    setCharactersRemaining(maxLength - newValue.length)
    onChange(e)
  }

  const handleFocus = () => {
    if (textareaRef.current) {
      textareaRef.current.style.transition = 'height 0.6s ease'
      textareaRef.current.style.height = `60px`
    }
  }

  const handleBlur = () => {
    if (value.length > 0 || !textareaRef.current) {
      return
    }
    textareaRef.current.style.transition = 'height 0.6s ease'
    textareaRef.current.style.height = '40px'
  }

  return (
    <Flex
      flexDir="row"
      w="full"
      flex={1}
      alignItems={'center'}
      autoFocus={false}
      pl={isMobile ? 0 : 6}
      gap="0.5rem 1rem" // Smaller vertical gap, larger horizontal gap
    >
      <Textarea
        ref={textareaRef}
        rounded={8}
        border="1px solid var(--chakra-colors-borderLight)"
        color="textBlack"
        fontWeight={400}
        fontSize="14px"
        value={value}
        onChange={handleChange}
        placeholder="Write a message (optional)"
        _placeholder={{ color: 'tipbox.dark.text.charcoalGrey' }}
        resize="none"
        maxLength={maxLength}
        css={{
          '&::-webkit-scrollbar': {
            width: '3px',
          },
          '&::-webkit-scrollbar-track': {
            width: '1px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-textAsh)',
            borderRadius: '6px',
          },
          scrollbarWidth: isFirefox ? 'none' : 'initial',
          overflowY: 'hidden',
          minHeight,
        }}
        _hover={{ border: '1.5px solid var(--chakra-colors-borderLight)' }}
        _focusVisible={{ boxShadow: 'none', outline: 'none' }}
        _selection={{ backgroundColor: 'tipbox.dark.background.charcoal', color: 'tipbox.dark.text.pearl' }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Text width="3ch" textAlign="right" whiteSpace="nowrap" color="charcoalGrey">
        {charactersRemaining}
      </Text>
    </Flex>
  )
}

export default FlowFormMessage
