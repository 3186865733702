import { useState, useEffect, useRef } from 'react'
import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { workflowConstant } from 'helpers/constant'
import { Flow, Node, User, Workflow } from 'types/graphqlSchema'
import ShareTaskForm from './ShareTaskForm'

interface ShareTaskModalProps {
  isReadOnly?: boolean
  isOpen: boolean
  onClose: () => void
  node: Node
  handleNewFlowOnNode?: () => void
  flowsData?: Flow[]
  includeTasks?: boolean
}

const ShareTaskModal = ({
  isOpen,
  onClose,
  node,
  handleNewFlowOnNode,
  flowsData,
  includeTasks = false,
}: ShareTaskModalProps) => {
  const focusRef = useRef<HTMLInputElement | null>(null)

  // Flow Object
  const [flowState, setFlowState] = useState<FlowState>({
    name: node.name ?? undefined,
    workflow: {} as Workflow | undefined,
    participantOptions: [] as User[],
    participants: [] as User[],
    due: null,
    urgent: false,
    uploadNeed: false,
    message: '',
    reminder: {
      selected: false,
      interval: 2,
    },
    keepInLoop: {
      selected: false,
      watchers: [] as User[],
    },
  })

  useEffect(() => {
    if (node) {
      setFlowState((prev) => ({
        ...prev,
        name: node.name,
      }))
    }
  }, [node])

  const handleClose = () => {
    setFlowState({
      name: node.name ?? undefined,
      workflow: {} as Workflow | undefined,
      participantOptions: [] as User[],
      participants: [] as User[],
      due: null,
      urgent: false,
      uploadNeed: false,
      message: '',
      reminder: {
        selected: false,
        interval: 2,
      },
      keepInLoop: {
        selected: false,
        watchers: [] as User[],
      },
    })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        bg="textHighlight"
        color="textBlack"
        fontFamily={'Inter'}
        w={'600px'}
        maxW={'80%'}
        py={4}
      >
        <ModalHeader
          color="inherit"
          py={0}
          display={'flex'}
          alignItems={'baseline'}
          fontSize={18}
          mx={4}
          onClick={() => focusRef.current && focusRef.current.focus()}
        >
          <Text fontSize={'inherit'} variant="tipSaveModalTitle" color="textBlack">
            {node?.name ?? 'Untitled'}
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody display={'flex'} flexDirection={'column'} mx={4} px={0} color="textBlack">
          <ShareTaskForm
            flowType={workflowConstant.approval.name}
            node={node}
            flowState={flowState}
            setFlowState={setFlowState}
            handleNewFlowOnNode={handleNewFlowOnNode}
            flowsData={flowsData}
            includeTasks={includeTasks}
            onClose={handleClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ShareTaskModal
