import { Box, Flex, Text, Avatar, AvatarGroup, Tooltip, useBreakpointValue } from '@chakra-ui/react'
import './AvatarsTooltip.css'
import { getResizedImage } from 'helpers/storage'
import { useState } from 'react'
import { GET_ORG } from 'features/graphql'
import { useQuery } from '@apollo/client'
import { UserOrGuest } from 'features/workflow/components/FlowModal/types/flow-form-types'

interface AvatarsTooltipProps {
  users: UserOrGuest[]
  max?: number
  size?: string
  caption?: string
  clickAction?: () => void
  showUserName?: boolean
  darkBg?: boolean
}

const AvatarsTooltip = ({
  users,
  max = 2,
  size = 'sm',
  caption,
  clickAction,
  showUserName = true,
  darkBg = false,
}: AvatarsTooltipProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })

  const [orgNameFromQuery, setOrgNameFromQuery] = useState<string>('')
  // multi-user avatar group || there is an org name from props || no org id (not a Tipbox user)
  const skipQuery = users?.length !== 1 || !!users?.[0]?.organization?.name || !users?.[0]?.organizationId

  const { data: orgData } = useQuery(GET_ORG, {
    variables: { organizationId: users?.[0]?.organizationId },
    skip: skipQuery,
    onError: (error) => {
      console.error(error)
    },
    onCompleted: () => {
      if (orgData) {
        setOrgNameFromQuery(orgData.organization.name)
      }
    },
  })

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      cursor={clickAction ? 'pointer' : 'default'}
      onClick={clickAction}
    >
      <Tooltip
        hasArrow
        fontSize={'13px'}
        rounded="lg"
        px=".75rem"
        py=".75rem"
        placement="top"
        bg="textBlack"
        label={
          <Flex flexDir={'column'}>
            {users.map((member) => (
              <Text
                key={member.email}
                p={0}
                m={0}
                color={darkBg ? 'textSoft' : 'textHighlight'}
              >{`${member.firstName} ${member.lastName}`}</Text>
            ))}
          </Flex>
        }
      >
        <Box>
          {users.length === 1 && (
            <Flex alignItems={'center'} gap={2}>
              <Avatar
                src={users[0]?.avatarSecureUrl ? getResizedImage(users[0].avatarSecureUrl, 'profile.account') : '#'}
                size={size}
                key={users[0].email}
                name={`${users[0].firstName} ${users[0].lastName}`}
                border="1px solid var(--chakra-colors-textHighlight)"
              />
              <Flex flexDir={'column'}>
                {showUserName && (
                  <Text fontSize={14} fontWeight={400} color={darkBg ? 'textSoft' : 'tipbox.dark.text.charcoalGrey'}>
                    {users[0]?.organization?.name === 'Guest'
                      ? users[0].email
                      : `${users[0].firstName} ${users[0].lastName}`}
                  </Text>
                )}
                {!isMobile && (
                  <Text fontSize={12} color={darkBg ? 'textRegular' : 'tipbox.dark.text.charcoalGrey'}>
                    {users[0]?.organization?.name || orgNameFromQuery || 'Guest'}
                  </Text>
                )}
              </Flex>
            </Flex>
          )}
          {users.length > 1 && (
            <AvatarGroup size={size} max={max} spacing={-1} cursor={'pointer'}>
              {users.map((member) => (
                <Avatar
                  src={member?.avatarSecureUrl ? getResizedImage(member.avatarSecureUrl, 'profile.account') : '#'}
                  key={member.email}
                  size={size}
                  name={`${member.firstName} ${member.lastName}`}
                  border="1px solid var(--chakra-colors-textHighlight)"
                />
              ))}
            </AvatarGroup>
          )}
        </Box>
      </Tooltip>
      <Text color={'cardBackground'}>{caption}</Text>
    </Flex>
  )
}

export default AvatarsTooltip
